'use strict';

var core = require('core/product/tile');

function swatchSwitch(el, data) {
    var product = data && 'product' in data ? data.product : null;
    var $swatch = el;
    var $tileParent = $swatch.parents('.product-tile');
    var $tileImage = $tileParent.find('.product-tile-image');
    var $tileImageHover = $tileParent.find('.product-tile-image-secondary');

    // TCS CUSTOM: Product tile content updates based on swatch selection
    if (product) {
        // Update badges
        if (product.badgesHTML) {
            $tileParent.find('.js-product-customBadge').html(product.badgesHTML.customBadge);
            $tileParent.find('.js-product-pressBadges').html(product.badgesHTML.pressBadges);
        }

        // Promotional message
        var promoMessage = data.product.promotions && data.product.promotions.length ? data.product.promotions[0].calloutMsg : null;
        $tileParent.find('.js-promotion').remove();
        if (promoMessage) {
            $('<div class="promotion js-promotion">' + promoMessage + '</div>').insertAfter($tileParent.find('.price'));
        }

        // Price update
        $tileParent.find('.price').html(product.searchPriceHTML);

        // GTM Data
        var gtmData = JSON.stringify(product.gtmData);
        $tileParent.closest('.product').attr('data-gtmdata', gtmData);

        // Wishlist Data
        var wishlist = require('client_core/wishlist/components/helpers.js');
        var $wishlistIcon = $tileParent.find('.wishlist-toggle-product');
        $wishlistIcon
            .data('wishlistpid', product.id)
            .attr('data-wishlistpid', product.id);
        $wishlistIcon.attr('data-pid', product.id);
        wishlist.updateLinkData($wishlistIcon);
    }

    // Below are AB updates
    $tileParent.find('.swatch.selected').removeClass('selected');
    $swatch.addClass('selected');

    // Replace main image and secondary image
    if ($swatch.data('hover-image') && [null, undefined, '', $tileImage.attr('src')].indexOf($swatch.data('hover-image')) === -1) {
        var swatchImageUrl = $swatch.data('hover-image');
        $tileImage.attr('src', swatchImageUrl);

        if ($tileImageHover.length > 0 && $swatch.data('hover-image-secondary')) {
            var swatchSecondaryImageUrl = $swatch.data('hover-image-secondary');
            $tileImage.data('alt-img-url', swatchSecondaryImageUrl);
            $tileImageHover.attr('src', swatchSecondaryImageUrl);
        }
    }

    // Main Image Link
    var productLinks = $tileParent.find('.product-tile-image-link, .pdp-link .link, .swatch-link-allcolors, .product-brand .brand-link');
    var productUrl = data.urls.product;

    // TCS CUSTOM: Append additional refinements and category id to the product url if needed
    var productNameLink = $tileParent.find('.pdp-link .link');
    var additionalRefs = productNameLink.data('additional-refs');
    var currentCategory = productNameLink.data('current-cat');

    if (currentCategory && data.product.primaryCategory !== currentCategory) {
        productUrl += '&cgid=' + currentCategory;
    }

    if (additionalRefs) {
        productUrl += '&' + $.param(additionalRefs);
    }

    productLinks.attr('href', productUrl);

    // Main Quickview Link
    var mainQuickViewLink = $tileParent.find('a.quickview');
    if (mainQuickViewLink.length) {
        mainQuickViewLink.attr('href', data.urls.quickView);
    }
}

// TCS override AB. TCS Custom: clicking on swatch will retrieve updates
function hijackSwatchClick() {};
function mouseEnter() {};
function resize() {};

// Updates the product tile based on the swatch selected
function updateProductTileSwatchSelection() {
    $('body').on('click', '.product-tile .swatch-link', function() {
        var $swatch = $(this).find('.swatch');
        var url = $(this).attr('data-url-tileswatch'); // if empty, then swatch is unavailable

        if (url) {
            $.ajax({
                url: url,
                method: 'GET',
                success: function (data) {
                    swatchSwitch($swatch, data);
                }
            });
        }
    });
}

core.methods.swatchSwitch = swatchSwitch;
core.hijackSwatchClick = hijackSwatchClick;
core.mouseEnter = mouseEnter;
core.resize = resize;
core.updateProductTileSwatchSelection = updateProductTileSwatchSelection;

module.exports = core;