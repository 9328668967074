var main = require('integrations/main');

main.baseFiles.footer = require('./components/footer');
main.baseFiles.tile = require('./product/tile');
main.baseFiles.base = require('./product/base');
main.baseFiles.quickView = require('./product/quickView');
main.baseFiles.accountHeader = require('./components/accountHeader');
main.baseFiles.pdForm = require('./components/pdForm');
main.baseFiles.tcsVideo = require('./components/tcsVideo');
main.baseFiles.faq = require('./components/faq');
main.baseFiles.menu = require('./components/menu');
main.baseFiles.search = require('./components/search').init;
main.baseFiles.clientSideValidation = require('./components/clientSideValidation');
main.baseFiles.estimatedDelivery = require('./components/estimatedDelivery');
main.baseFiles.shopTheCollection = require('./components/shopTheCollection');
main.baseFiles.reviewsLandingPage = require('./components/reviewsLandingPage');
main.baseFiles.monogram = require('./product/monogram');
main.baseFiles.wishlist = require('./wishlist/wishlist');
main.baseFiles.cart = require('./cart');
main.baseFiles.giftCertificate = require('./giftCertificate/giftCertificate').init;
main.baseFiles.slider = require('./components/slider').init;
main.baseFiles.header = require('./components/header');
main.baseFiles.miniCart = require('./components/miniCart');
main.baseFiles.consentTracking = require('./components/consentTracking');
main.baseFiles.productTile = require('./components/productTile');
main.baseFiles.pageDesigner = require('./pageDesigner');
main.baseFiles.postpageloadevents = require('./components/postpageloadevents');

// Third Party
require('./thirdParty/cloudinary');
main.baseFiles.adobeAnalytics = require('./thirdParty/adobeAnalytics');
main.baseFiles.powerReviewsAnalytics = require('./thirdParty/powerreviews/powerReviewsAnalytics');
main.baseFiles.powerReviewsRendering = require('./thirdParty/powerreviews/powerReviewsRendering');
require('./thirdParty/klaviyo');
require('./thirdParty/dynamicyield');
require('./thirdParty/zinrelo');

module.exports = main;
