'use strict';

var debounce = require('lodash/debounce');
var endpoint = $('.suggestions-wrapper').data('url');
var recentendpoint = $('.suggestions-wrapper').data('recent-url');

var sitePreferencesUtils = require('core/preferences/sitePreferencesUtils');
var preferences = sitePreferencesUtils.getSitePreferences();
var SiteConstants = require('constants/SiteConstants');
var headerUtils = require('core/utilities/headerUtils');
var slider = require('core/components/slider');
var einsteinRecs = require('core/components/einsteinRecs');
var menu = require('core/components/menu');
// Original vars from search.js
var minChars = preferences.headerSearchMinTermLength;
var UP_KEY = SiteConstants.UP_KEY;
var DOWN_KEY = SiteConstants.DOWN_KEY;
var DIRECTION_DOWN = SiteConstants.DIRECTION_DOWN;
var DIRECTION_UP = SiteConstants.DIRECTION_UP;

function showSearchBackground() {
    var $modalBackground = $('.suggestions-modal-background');
    if (!$modalBackground.is(':visible')) {
        var navHeight = ($('.main-menu').length && !window.isMobile()) ? $('.main-menu').outerHeight() : 0;
        var headerNavHeight = headerUtils.getHeaderHeight();
        var headerOnlyHeight = headerNavHeight - navHeight;

        $modalBackground
            .css('top', headerOnlyHeight)
            .fadeIn(SiteConstants.TransitionSpeed);
        $('.header-mobile-topnav-container').addClass('d-none');
    }
}

function hideSearchBackground() {
    var $modalBackground = $('.suggestions-modal-background');
    $modalBackground
        .fadeOut(SiteConstants.TransitionSpeed);
    $('.header-mobile-topnav-container').removeClass('d-none');
}

/**
 * Retrieves Suggestions element relative to scope
 *
 * @param {Object} scope - Search input field DOM element
 * @return {JQuery} - .suggestions-wrapper element
 */
 function getSuggestionsWrapper(scope) {
    return $('.suggestions-wrapper'); // TCS customization to allow repositioning suggestions in DOM
}

/**
 * Determines whether DOM element is inside the .search-mobile class
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 * @return {boolean} - Whether DOM element is inside  div.search-mobile
 */
function isMobileSearch(scope) {
    return !!$(scope).closest('.search-mobile').length;
}

/**
 * Tear down Suggestions panel
 */
function tearDownSuggestions() {
    $('input.search-field').val('').blur();
    $('.search-mobile .suggestions').unbind('scroll');
    $('.suggestions-wrapper').empty();
}

/**
 * Determines whether the "More Content Below" icon should be displayed
 *
 * @param {Object} scope - DOM element, usually the input.search-field element
 */
function handleMoreContentBelowIcon(scope) {
    if (($(scope).scrollTop() + $(scope).innerHeight()) >= $(scope)[0].scrollHeight) {
        $('.more-below').fadeOut();
    } else {
        $('.more-below').fadeIn();
    }
}

/**
 * Observes the suggestion products container to check when einstein products are loaded in the content slot
 * @param {jQuery} $container - The jQuery object representing the suggestion products container.
 */
function observeSuggestionProducts($container) {
    var observer = new MutationObserver((mutationsList, observer) => {
        for (let mutation of mutationsList) {
            if (mutation.type === 'childList') {
                mutation.addedNodes.forEach((node) => {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        if (node.classList.contains('suggestion-products')) {
                            $container.removeClass('no-products d-none');
                            if ($container.find('.suggestion-recent-searches').length === 0) {
                                $container.addClass('no-recent-searches');
                            }
                            observer.disconnect();
                        }
                    }
                });
            }
        }
    });

    observer.observe($container[0], { childList: true, subtree: true });
}

/**
 * Process Ajax response for SearchServices-GetSuggestions
 *
 * @param {Object|string} response - Empty object literal if null response or string with rendered
 *                                   suggestions template contents
 */
function processResponse(response) {
    var $suggestionsWrapper = module.exports.getSuggestionsWrapper(this).empty();
    var headerHeight = window.isMobile()
        ? $('.header-banner').outerHeight() + $('.header-nav').outerHeight()
        : $('.header-banner').outerHeight() + $('.header-nav > .header').outerHeight();
    var maxHeightSubtract = window.isMobile() ? headerHeight + 100 : headerHeight + 16;

    $suggestionsWrapper.find('.suggestions').spinner().stop();

    if (typeof (response) !== 'object') {
        var parsedResponse = $.parseHTML(response);
        if ($(parsedResponse).find('.suggestion-section').length > 0) {
            $suggestionsWrapper.append(response).show();
            var recentSearchPhrases = $(parsedResponse).find('.suggestion-recent-searches').length > 0;
            if (!recentSearchPhrases) {
                $(this).siblings('.reset-button').addClass('d-block');
                $(this).siblings('.search-submit-button').addClass('d-block d-lg-none');
            }
            $suggestionsWrapper.find('.suggestions').css('max-height', 'calc(100vh - ' + maxHeightSubtract + 'px)');

            var $recentOnlySuggestions = $suggestionsWrapper.find('.js-recent-only-suggestions');
            if ($recentOnlySuggestions.length > 0) {
                if ($suggestionsWrapper.find('.suggestion-recent-searches').length > 0) {
                    $recentOnlySuggestions.addClass('no-products');
                    $recentOnlySuggestions.removeClass('d-none');
                }
                if ($suggestionsWrapper.find('[id^="cq_recomm_slot"]').length > 0) {
                    observeSuggestionProducts($recentOnlySuggestions)
                }
            }
            showSearchBackground();

            // Trigger screen reader by setting aria-describedby with the new suggestion message.
            var suggestionsList = $('.suggestions .item');
            if ($(suggestionsList).length) {
                $('input.search-field').attr('aria-describedby', 'search-result-count');
            } else {
                $('input.search-field').removeAttr('aria-describedby');
            }
        } else {
            hideSearchBackground();
        }

    } else {
        $suggestionsWrapper.hide();
        hideSearchBackground();
    }
}

/**
 * Retrieve suggestions
 *
 * @param {Object} scope - Search field DOM element
 */
function getSuggestions(scope) {
    if ($(scope).val().length >= minChars) {
        var $suggestionsWrapper = module.exports.getSuggestionsWrapper(scope);
        $suggestionsWrapper.find('.suggestions').spinner().start();
        $.ajax({
            context: scope,
            url: endpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: processResponse,
            error: function () {
                $suggestionsWrapper.find('.suggestions').spinner().stop();
            }
        });
    } else if ($(scope).val().length === 0) {
        var $suggestionsWrapper = module.exports.getSuggestionsWrapper(scope);
        $suggestionsWrapper.find('.suggestions').spinner().start();
        $.ajax({
            context: scope,
            url: recentendpoint + encodeURIComponent($(scope).val()),
            method: 'GET',
            success: function(data) {
                module.exports.processResponse(data);
                $(scope).siblings('.reset-button').removeClass('d-block');
                $(scope).siblings('.search-submit-button').removeClass('d-block d-lg-none');
            },
            error: function () {
                $suggestionsWrapper.find('.suggestions').spinner().stop();
            }
        });
    } else {
        $(scope).siblings('.reset-button').removeClass('d-block');
        $(scope).siblings('.search-submit-button').removeClass('d-block d-lg-none');
        module.exports.getSuggestionsWrapper(scope).empty();
        hideSearchBackground();
    }
}

/**
 * Handle Search Suggestion Keyboard Arrow Keys
 *
 * @param {Integer} direction takes positive or negative number constant, DIRECTION_UP (-1) or DIRECTION_DOWN (+1)
 */
function handleArrow(direction) {
    // get all li elements in the suggestions list
    var suggestionsList = $('.suggestions .item');
    if (suggestionsList.filter('.selected').length === 0) {
        suggestionsList.first().addClass('selected');
        $('input.search-field').each(function () {
            $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
        });
    } else {
        suggestionsList.each(function (index) {
            var idx = index + direction;
            if ($(this).hasClass('selected')) {
                $(this).removeClass('selected');
                $(this).removeAttr('aria-selected');
                if (suggestionsList.eq(idx).length !== 0) {
                    suggestionsList.eq(idx).addClass('selected');
                    suggestionsList.eq(idx).attr('aria-selected', true);
                    $(this).removeProp('aria-selected');
                    $('input.search-field').each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.eq(idx)[0].id);
                    });
                } else {
                    suggestionsList.first().addClass('selected');
                    suggestionsList.first().attr('aria-selected', true);
                    $('input.search-field').each(function () {
                        $(this).attr('aria-activedescendant', suggestionsList.first()[0].id);
                    });
                }
                return false;
            }
            return true;
        });
    }
}

//Init functions below

function simpleSearch () {
    $('form[name="simpleSearch"]').submit(function (e) {
        var suggestionsList = $('.suggestions .item');
        if (suggestionsList.filter('.selected').length !== 0) {
            e.preventDefault();
            suggestionsList.filter('.selected').find('a')[0].click();
        }
    });
}

function searchKeyListener () {
    $('input.search-field').each(function () {
        /**
         * Use debounce to avoid making an Ajax call on every single key press by waiting a few
         * hundred milliseconds before making the request. Without debounce, the user sees the
         * browser blink with every key press.
         */
        var debounceSuggestions = debounce(module.exports.getSuggestions, 300);
        $(this).on('keyup focus', function (e) {
            // Capture Down/Up Arrow Key Events
            switch (e.which) {
                case DOWN_KEY:
                    module.exports.handleArrow(DIRECTION_DOWN);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                case UP_KEY:
                    module.exports.handleArrow(DIRECTION_UP);
                    e.preventDefault(); // prevent moving the cursor
                    break;
                default:
                    debounceSuggestions(this, e);
            }
        });

        // TCS customization - change placeholder text on focus
        $(this).on('focus', event => {
            var $target = $(event.target);
            if ($target.val() === '' && (window.isMobile() || window.innerWidth > SiteConstants.BreakpointSizes.xl)) {
                var placeholderText = $target.data('alternate-placeholder');
                $target.attr('placeholder', placeholderText).addClass('alternate-placeholder');
            }
            // close main menu if open
            if (window.isMobile() && $('header .main-menu').hasClass('in')) {
                menu.toggleDrawer('close');
            }
        });
        $(this).on('blur', event => {
            var $target = $(event.target);
            if ($target.val() === '' && (window.isMobile() || window.innerWidth > SiteConstants.BreakpointSizes.xl)) {
                var placeholderText = $target.data('placeholder');
                $target.attr('placeholder', placeholderText).removeClass('alternate-placeholder');
            }
        });
    });
}

function hideSuggestions () {
    $('body').on('click', function (e) {
        var $target = $(e.target);
        if ($target.closest('.suggestions').length === 0 && $target.closest('.search-field').length === 0) {
            $('.suggestions').hide();
            hideSearchBackground()
        }
    });
}

function closeOnMobile () {
    $('body').on('click touchend', '.search-mobile .reset-button', function (e) {
        e.preventDefault();
        $('.suggestions').hide();
        hideSearchBackground();
        $(this).removeClass('d-block');
        $(this).siblings('.search-submit-button').removeClass('d-block d-lg-none');
        module.exports.tearDownSuggestions();
    });
}

// close dropdowns if user scrolls outside of search dropdown
function closeOnScroll () {
    $(window).on('scrollUpdate', () => {
        if ($('.suggestions').is(':visible')) {
            $('.suggestions').hide();
            hideSearchBackground()
        }
    });
}

function resetSearch () {
    $('.site-search .reset-button').on('click', function () {
        $(this).removeClass('d-block');
        $(this).siblings('.search-submit-button').removeClass('d-block d-lg-none');
        $(this).prev('.search-field').val('').focus();
    });
}

module.exports = {
    getSuggestionsWrapper: getSuggestionsWrapper,
    isMobileSearch: isMobileSearch,
    tearDownSuggestions: tearDownSuggestions,
    handleMoreContentBelowIcon: handleMoreContentBelowIcon,
    processResponse: processResponse,
    getSuggestions: getSuggestions,
    handleArrow: handleArrow,
    simpleSearch: simpleSearch,
    searchKeyListener: searchKeyListener,
    hideSuggestions: hideSuggestions,
    closeOnMobile: closeOnMobile,
    closeOnScroll: closeOnScroll,
    resetSearch: resetSearch,
    init: function () {
        module.exports.simpleSearch();
        module.exports.searchKeyListener();
        module.exports.hideSuggestions();
        module.exports.closeOnMobile();
        module.exports.closeOnScroll();
        module.exports.resetSearch();
    }
};
