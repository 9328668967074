'use strict'

var recaptcha = require('core/components/recaptcha');
var scrollAnimate = require('core/components/scrollAnimate');
var toastHelpers = require('core/components/toast');

/**
 * Events are divided up by name space so only the
 * events that are needed are initialized.
 */
var events = {
    accountmyinfo: function () {
        $('body').on('submit', '.email-pref-form', function (e) {
            e.preventDefault();
            $.spinner().start();

            var $form = $(this);

            // Add add'l support if other form fields will be added
            var radioLabel = $form.find("input[name='tcs-frequency']").closest('fieldset').find('legend');
            radioLabel.removeClass('is-invalid');
            if (!$form.find("input[name='tcs-frequency']:checked").val()) {
                radioLabel.addClass('is-invalid');
                scrollAnimate(radioLabel);
                
                $.spinner().stop();
                return false;
            }

            recaptcha.check(e, {
                url: $(this).attr('action'),
                method: $(this).attr('method'),
                dataType: 'json',
                data: $(this).serialize(),
                success: response => {
                    var toastType = response && response.success ? 'success' : 'danger';
                    toastHelpers.methods.show(toastType, response.message || '', false);

                    $.spinner().stop();
                },
                error: err => {
                    location.reload();
                    $.spinner().stop();
                }
            });
        });

        $('body').on('click', '.js-email-unsub', function (e) {
            e.preventDefault();
            $.spinner().start();

            recaptcha.check(e, {
                url: $(this).data('url'),
                method: 'POST',
                dataType: 'json',
                success: response => {
                    var toastType = response && response.success ? 'success' : 'danger';
                    toastHelpers.methods.show(toastType, response.message || '', false);

                    if (response.success) {
                        $('.js-email-preferences').remove();
                        $('.js-emailsubvalue').text(response.resources.unsubscribed);
                        $('#add-to-email-list').prop('checked', false);
                    }

                    $.spinner().stop();
                },
                error: err => {
                    location.reload();
                    $.spinner().stop();
                }
            });
        });
    },
    homeshow: function () {},
    productshow: function () {},
    productshowincategory: function () {},
    searchshow: function () {},
    cartshow: function () {},
    checkoutbegin: function () {},
    orderconfirm: function () {},
    all: function () {
        // Add to Cart event for Klaviyo Added to Cart event tracking
        $('body').on('product:KLaddedToCart', function (e, data) {
            // get the Klaviyo company id from cached data, by getting this far into the code it should never be empty
            var kcid;
            if (window && window.CachedData && window.CachedData.klaviyoCID) {
                kcid = window.CachedData.klaviyoCID;
            }

            // get the user's Klaviyo ID from the cookie kla_id
            const getCookie = (name) => {
                return document.cookie.split('; ').reduce((r, v) => {
                    const parts = v.split('=')
                    return parts[0] === name ? decodeURIComponent(parts[1]) : r
                }, '')
                }
            const _kx = JSON.parse(atob(getCookie("__kla_id")))['$exchange_id'];


            // make sure there is a klaviyo user id to associate the event with
            if (_kx !== null) {
                var atcData = data.data;
                var addedIDs = atcData.multiProductsAdded;
                atcData.productAddedToCart = {};

                // remove the multiProductsAdded from the atcData object
                delete atcData.multiProductsAdded;

                // get the event name
                var eventName = atcData.event;

                // loop over the addedIDs and send an event for each item that was added to the cart
                for (var i = 0; i < addedIDs.length; i++) {
                    var addedProduct = addedIDs[i];

                    atcData.productAddedToCart = addedProduct;

                    // create a timestamp with Math.random() appended for each event to pass as the unique_id,
                    // Klaviyo defaults to using the timestamp to the second for this and has a limit of one event/metric per profile per second for each unique_id
                    var uniqueId = Date.now() + Math.random();

                    // create the data object to send as a JSON string
                    var eventData = JSON.stringify({
                        data: {
                            type: 'event',
                            attributes: {
                                properties: atcData,
                                unique_id: uniqueId,
                                metric: {
                                    data: {
                                        type: 'metric',
                                        attributes: {
                                            name: eventName,
                                            service: 'demandware'
                                        }
                                    }
                                },
                                profile: {
                                    data: {
                                        type: 'profile',
                                        attributes: {
                                            _kx: _kx
                                        }
                                    }
                                }
                            }
                        }
                    });

                    // set up the ajax settings
                    var settings = {
                      async: true,
                      crossDomain: true,
                      url: 'https://a.klaviyo.com/client/events/?company_id=' + kcid,
                      method: 'POST',
                      headers: {
                        accept: 'application/json',
                        revision: window.CachedData.klaviyoClientRevision.length > 0 ? window.CachedData.klaviyoClientRevision : '2024-06-15', // Default to 2024-06-15 if site pref is not set
                        'content-type': 'application/json'
                      },
                      processData: false,
                      data: eventData
                    };

                    // send the event to Klaviyo
                    $.ajax(settings).done(function (response) {
                        // this will by empty on success and will only contain generic values if it errors
                        console.log(response);
                    });

                    // reset the productAddedToCart for next loop
                    atcData.productAddedToCart = {};
                }
            }
        });
    }
}

/**
 * @function init
 * @description Initialize the klaviyo functionality if window.klaviyo exists
 * @param {String} nameSpace The current name space
 */
$(document).ready(function () {
    if (window.klaviyo) {
        events.all();
    }

    // cookieOptOut cookie check not required
    events.accountmyinfo();
});
