'use strict';

var core = require('core/wishlist/components/modalShowLists');
var wishlistHelpers = require('client_core/wishlist/components/helpers');
var toastHelpers = require('core/components/toast');
var base = require('core/product/base');

var eventToggleProductOnWishlist = function($listButton) {
    if (!$listButton) return;
    var pid = $listButton.data().wishlistpid;
    var $productContainer = $('.product-detail[data-pid="'+ pid +'"]'); // TCS update - added quotes to prevent issues with slashes in product IDs
    var options = base.methods.getOptions($productContainer);
    var isCart = $('.cart-page').length ? true : false;
    var inWishlist = $listButton.attr('data-inwishlist') && $listButton.attr('data-inwishlist').length && $listButton.attr('data-inwishlist') === 'true' ? true : false;

    options ? $listButton.attr('data-product-options', JSON.stringify(options)) : '';
    $listButton.addClass('loading');

    $.ajax({
        url: $listButton.data().url,
        method: 'POST',
        dataType: 'json',
        data: {
            pid: pid,
            quantity: 1,
            options: options
        },
        success: data => {
            if (!data.error) {
                wishlistHelpers.updateUncachedData({
                    actionType: data.wishlistActionType,
                    pid: pid
                });
                wishlistHelpers.updateLinkData();
                wishlistHelpers.openToast($listButton.data());
                $('body').trigger('wishlist:update', { wishlistUpdateUrl: data.wishlistUpdateUrl });

                // Add product tile to wishlist section
                if (isCart && 'productTileHTML' in data && !inWishlist) {
                    $('body').trigger('wishlist:addProductTileSlider', {
                        productTileHTML: data.productTileHTML,
                        container: $('.wishlist-slider')
                    });
                }

                // Remove from wishlist section
                if (isCart && inWishlist) {
                    wishlistHelpers.removeWishlistTileFromSlider(data.pid);
                }
            } else {
                toastHelpers.methods.show('danger', data.message, true);
            }

            $listButton.removeClass('loading');
        },
        error: err => {
            console.error(err);
            $listButton.removeClass('loading');
        }
    }).then(function (data) {
        // Cart wishlist updates
        if (isCart) {
            // Move product from Cart to Wishlist
            wishlistHelpers.saveForLater(data, $listButton);
        }
    });
};

core.methods.eventToggleProductOnWishlist = eventToggleProductOnWishlist;

module.exports = core;
