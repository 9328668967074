'use strict';

var core = require('core/cart/cart');
var base = require('core/product/base');
var abSlider = require('client_core/components/slider');
var scrollAnimate = require('core/components/scrollAnimate');
var wishlistHelpers = require('client_core/wishlist/components/helpers');

/**
 * Updates the availability of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateAvailability(data, uuid) {
    var lineItem;
    var messages = '';

    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem != null) {
        $('.availability-' + lineItem.UUID).empty();

        if (lineItem.availability) {
            if (lineItem.availability.messages) {
                lineItem.availability.messages.forEach(function (message) {
                    messages += '<span class="line-item-availability-status">' + message;

                    // Dropship or Made to Order Timing message
                    var availabilityTimingMessage = 'dropShipMadetoOrderTiming' in lineItem.availability ? lineItem.availability.dropShipMadetoOrderTiming : '';
                    if (availabilityTimingMessage) {
                        messages += ': <span class="availability-timing">' + availabilityTimingMessage + '</span>'
                    }

                    messages += '</span>';
                });
            }

            if (lineItem.estimatedDelivery && lineItem.estimatedDelivery.cartlabel) {
                messages += '<span> - ' + lineItem.estimatedDelivery.cartlabel + ' ';

                if (lineItem.estimatedDelivery.formattedShipDate) {
                    messages += lineItem.estimatedDelivery.formattedShipDate;
                } else if (lineItem.estimatedDelivery.formattedArrivalDate) {
                    messages += lineItem.estimatedDelivery.formattedArrivalDate;
                }
                messages += '</span>';
            }

            if (lineItem.availability.additionalMessaging) {
                messages += '<div class="line-item-availability-additional-message">' + lineItem.availability.additionalMessaging + '</div>';
            }

            if (lineItem.availability.status) {
                $('.availability-' + lineItem.UUID).attr('data-status', lineItem.availability.status);
            }
        }

        $('.availability-' + lineItem.UUID).html(messages);
    }

    $('.cart-error').empty(); // new message will get appended if still in error state
}

/**
 * re-renders the order totals and the number of items in the cart
 * @param {Object} data - AJAX response from the server
 */
function updateCartTotals(data) {
    var $cart = $('.cart');
    $('.number-of-items').empty().append(data.numItems == 1 ? data.resources.numberOfItem : data.resources.numberOfItems);
    $cart.find('.shipping-cost span').empty().append(data.totals.totalShippingCost);
    $cart.find('.grand-total span, .checkout-continue .checkout-btn-grand-total').empty().append(data.totals.grandTotalLessGiftCertificatePaymentInstrumentsFormatted);
    $cart.find('.checkout-continue .checkout-btn').attr('data-price', data.totals.grandTotalLessGiftCertificatePaymentInstrumentsFormatted);
    $cart.find('.sub-total span').empty().append(data.totals.subTotal);
    $('.minicart-quantity').empty().append(data.numItems);
    $('.minicart-link').attr({
        'aria-label': data.resources.minicartCountOfItems,
        title: data.resources.minicartCountOfItems
    });

    sessionStorage?.setItem?.('cartcount', data.numItems);

    if (data.totals.orderLevelDiscountTotal.value > 0) {
        $cart.find('.order-discount').removeClass('hide-order-discount');
        $cart.find('.order-discount-total').empty()
            .append('- ' + data.totals.orderLevelDiscountTotal.formatted);
    } else {
        $cart.find('.order-discount').addClass('hide-order-discount');
    }

    // TCS CUSTOM: Handle Zinrelo reward display
    if (data.totals.zinreloDiscountTotal.value !== 0) {
        $cart.find('.zinrelo-discount').removeClass('d-none');
        $cart.find('.zinrelo-discount-total').empty()
            .append('- ' + data.totals.zinreloDiscountTotal.formatted);
    } else {
        $cart.find('.zinrelo-discount').addClass('d-none');
    }

    if (data.totals.shippingLevelDiscountTotal.value > 0) {
        $cart.find('.shipping-discount').removeClass('hide-shipping-discount');
        $cart.find('.shipping-discount-total span').empty().append('- ' +
            data.totals.shippingLevelDiscountTotal.formatted);
    } else {
        $cart.find('.shipping-discount').addClass('hide-shipping-discount');
    }

    if (data.payment.giftCertificatePaymentInstruments.length) {
        $cart.find('.giftcertificate-discount').removeClass('d-none');
        $cart.find('.giftcertificate-discount-label').text(data.totals.giftCertificatePaymentInstrumentsLabel);
        $cart.find('.giftcertificate-discount-total span').text('- ' + data.totals.giftCertificatePaymentInstrumentsTotalFormatted);
    } else {
        $cart.find('.giftcertificate-discount').addClass('d-none');
    }

    if (data.totals.grandTotalLessGiftCertificatePaymentInstrumentsValue) {
        $cart.find('.cartAdditionalPaymentButtons').removeClass('d-none');
    } else {
        $cart.find('.cartAdditionalPaymentButtons').addClass('d-none');
    }

    // TCS CUSTOM: Mattress Recycling Fee
    var mattressRecyclingFee = 'mattressRecyclingFee' in data && data.mattressRecyclingFee && 'hasFee' in data.mattressRecyclingFee ? data.mattressRecyclingFee : null;
    if (mattressRecyclingFee && mattressRecyclingFee.hasFee) {
        $cart.find('.js-mattress-recycle-fee').removeClass('d-none');
        $cart.find('.js-mattress-recycle-fee .js-mattress-recycle-fee-price').text(mattressRecyclingFee.originalTotalCost);
        $cart.find('.js-mattress-recycle-fee .js-mattress-recycle-fee-finalprice').text(mattressRecyclingFee.finalCost);
    } else {
        $cart.find('.js-mattress-recycle-fee').addClass('d-none');
        $cart.find('.js-mattress-recycle-fee .js-mattress-recycle-fee-price').text('');
        $cart.find('.js-mattress-recycle-fee .js-mattress-recycle-fee-finalprice').text('');
    }

    // TCS CUSTOM START: Amount Saved
    if (data.totals.amountSaved) {
        $cart.find('.amount-saved-value').empty().append(data.totals.amountSaved);
        $cart.find('.amount-saved').removeClass('d-none');
    } else {
        $cart.find('.amount-saved').addClass('d-none');
        $cart.find('.amount-saved-value').empty();
    }
    // TCS CUSTOM END: Amount Saved

    data.items.forEach(function (item) {
        var itemPrice;
        var itemTotalPrice;

        if (item.productType === 'giftCertificate') {
            itemPrice = item.priceTotal?.price;
            itemTotalPrice = item.priceTotal?.price;
        } else {
            itemPrice = item.renderedPrice;
            itemTotalPrice = item.priceTotal?.renderedPrice;
        }

        if (data.totals.orderLevelDiscountTotal.value > 0) {
            $cart.find('.coupons-and-promos').empty().append(data.totals.discountsHtml);
        }
        if (item.renderedPromotions) {
            $cart.find('.item-' + item.UUID).empty().append(item.renderedPromotions);
        } else {
            $cart.find('.item-' + item.UUID).empty();
        }
        $cart.find('.uuid-' + item.UUID + ' .unit-price').empty().append(itemPrice);
        $cart.find('.line-item-price-' + item.UUID + ' .unit-price').empty().append(itemPrice);
        $cart.find('.item-total-' + item.UUID + ' .js-item-total-price').empty().append(itemTotalPrice);

        // TCS CUSTOM: Add back single item price if qty is >1
        var itemEachContainer = $cart.find('.item-total-' + item.UUID).closest('.line-item-total-price').find('.js-item-each-price');
        if (itemEachContainer) {
            var itemEachPrice = item.itemPrice;
            if (itemEachPrice && 'quantity' in item && item.quantity && item.quantity > 1) {
                itemEachContainer.removeClass('d-none');
                itemEachContainer.find('.js-item-each-price-value').empty().append(itemEachPrice);
            } else {
                itemEachContainer.addClass('d-none');
                itemEachContainer.find('.js-item-each-price-value').empty().append('');
            }
        }

        // TCS CUSTOM: Clearance label
        var clearanceLabel = $cart.find('.item-total-' + item.UUID + '.price .js-label-clearance');
        if (item.isClearance) {
            clearanceLabel.removeClass('d-none');
        } else {
            clearanceLabel.addClass('d-none');
        }

        // TCS CUSTOM: Non-returnable label
        var itemNonreturnableLabel = $cart.find('.availability-' + item.UUID + ' .js-nonreturnable-messaging');
        if (itemNonreturnableLabel && 'itemReturnable' in item) {
            if (!item.itemReturnable) {
                itemNonreturnableLabel.removeClass('d-none');
            } else {
                itemNonreturnableLabel.addClass('d-none');
            }
        }
    });
}

/**
 * re-renders the free shipping meter component
 * @param {Object} basket - the returned basket model
 */
function updateFreeShippingMeter(basket) {
    var $shippingMeter = $('.js-fsmeter');
    var data = basket && basket.freeShippingMeter && basket.freeShippingMeter.meter ? basket.freeShippingMeter : null;

    if ($shippingMeter.length > 0) {
        if (data) {
            var showCheckmark = data.percentComplete >= 100;
            // Adjust value to prevent left/right overlap for empty and full states
            var percentComplete = data.percentComplete < 2 ? 2
                                : data.percentComplete > 98 ? 98
                                : data.percentComplete;

            $shippingMeter.find('.js-fsmeter-title').text(data.title);
            $shippingMeter.find('.js-fsmeter-bar').css('width', percentComplete + '%');
            $shippingMeter.find('.js-fsmeter-description').text(data.description);
            $shippingMeter.removeClass('d-none');
            if (showCheckmark) {
                $shippingMeter.find('.js-fsmeter-bar-checkmark').removeClass('d-none');
            } else {
                $shippingMeter.find('.js-fsmeter-bar-checkmark').addClass('d-none');
            }
        } else {
            $shippingMeter.addClass('d-none');
        }
    }
}

/**
 * Re-renders the cart LTL (Less Than Load) information after ajax calls
 * @param {Object} basket - the returned basket model
 */
function updateLTLCartAjax(basket) {
    var $orderSummaryLTLContainer = $('.js-shipping-ltl-msg');

    if ($orderSummaryLTLContainer.length > 0) {
        var data = basket && basket.lessThanLoad ? basket.lessThanLoad : null;

        if (data && data.hasLTL) {
            // Update Order Summary messaging under estimated delivery
            $orderSummaryLTLContainer.text(data.resources.cart).removeClass('d-none');

            // Update cart product cards
            if (data.isValidShippingMethod) {
                $('.js-product-card-ltl-msg').hide();
            } else {
                $('.js-product-card-ltl-msg').show();
            }
        } else {
            $orderSummaryLTLContainer.empty().addClass('d-none');
            $('.js-product-card-ltl-msg').hide();
        }
    }
}

/**
 * Re-renders the product card's explicit recommendations
 * @param {Object} basket - the returned basket model
 */
function updateExplicitRecommendations(basket) {
    var lineItems = basket && 'items' in basket ? basket.items : null;

    if (lineItems && lineItems.length > 0) {
        for (var i = 0; i < lineItems.length; i++) {
            var item = lineItems[i];
            var $currentPLIRecommendations = $('body .js-cart-explicit-recommendations.uuid-' + item.UUID);

            if ($currentPLIRecommendations.length) {
                $currentPLIRecommendations.remove();
            }

            var $pliContainer = $('.product-info.uuid-' + item.UUID).closest('.card');
            $(item.recommendationsHTML).insertAfter($pliContainer);

            // set up slider for recommendatiosn
            var $newPLIRecommendations = $pliContainer.next('.js-cart-explicit-recommendations');
            if ($newPLIRecommendations.length) {
                abSlider.initializeSliders($newPLIRecommendations);
            }
        }
    }
}

/**
 * Updates the Tiered Promotion content
 * @param {Object} basket - the returned basket model
 */
function updateTieredPromoContent(basket) {
    if ($('.js-tiered-promo').length > 0 && basket) {
        $('.js-tiered-promo-content').empty();

        if ('tieredPromotions' in basket && 'messages' in basket.tieredPromotions && basket.tieredPromotions.messages.length > 0 && basket.tieredPromotions.title) {
            $('.js-tiered-promo-title').text(basket.tieredPromotions.title);

            for (var i = 0; i < basket.tieredPromotions.messages.length; i++) {
                var messageHTML = (i + 1) === basket.tieredPromotions.messages.length ? '<li>' : '<li class="pb-2">';
                messageHTML += basket.tieredPromotions.messages[i];
                messageHTML += '</li>';
                $('.js-tiered-promo-content').append(messageHTML);
            }

            // Promo not applied but added (threshold not met)
            if (!basket.tieredPromotions.applied) {
                $('.js-tiered-promo-notapplied').text(basket.tieredPromotions.resources.notApplied);
                $('.js-tiered-promo').removeClass('alert-success').addClass('alert-danger');
            } else {
                $('.js-tiered-promo-notapplied').text('');
                $('.js-tiered-promo').removeClass('alert-danger').addClass('alert-success');
            }

            $('.js-tiered-promo').removeClass('d-none');
        } else {
            $('.js-tiered-promo').addClass('d-none');
        }
    }

    $('body').trigger('tooltip:init');
}

/**
 * Add Cart Product's explicit recommendation product to cart
 */
function addRecommendationProductToCart() {
    $('body').on('click', '.js-cart-recommendation-add', function () {

        $.spinner().start();

        $.ajax({
            url: $(this).data('url'),
            type: 'POST',
            dataType: 'json',
            data: {
                pid: $(this).data('pid'),
                quantity: 1,
                options: []
            },
            success: function (data) {
                var addedUUID = data.pliUUID && data.pliUUID.length > 0 ? data.pliUUID[0] : null;

                $('.coupons-and-promos').empty().append(data.cart.totals.discountsHtml);
                module.exports.updateCartTotals(data.cart);
                module.exports.updateApproachingDiscounts(data.cart.approachingDiscounts);
                module.exports.updateFreeShippingMeter(data.cart);
                module.exports.updateLTLCartAjax(data.cart);
                module.exports.updateProductDetails(data, addedUUID);
                module.exports.updateTieredPromoContent(data.cart);
                $('body').trigger('setShippingMethodSelection', data.cart);
                module.exports.validateBasket(data.cart);
                $.spinner().stop();

                // Get added UUID's GTM item Data
                var addedItem = data.cart.items.filter(function (item) {
                    return item.UUID === addedUUID;
                });

                if (addedItem && addedItem.length > 0) {
                    $('body').trigger('product:GTMaddToCart', {gtmData: addedItem[0].gtmData});
                }
            },
            error: function (err) {

                module.exports.createErrorNotification(err.responseJSON.errorMessage);
                $.spinner().stop();
            }
        }).then(function (data) {
            updateExplicitRecommendations(data.cart);
        });
    });
}

/**
 * Creates a new availability grouping entry with passed in data
 * @param {string} availabilityGrouping - string of the added/updated item availability grouping
 * @param {Object} data - AJAX response from the server
 */
function createNewAvailabilityGrouping(availabilityGrouping, data) {
    var statusGroup = availabilityGrouping.split('-');
    var tmpl = $('#pli-availability-grouping-template').clone();
    var cart = data && 'cart' in data ? data.cart : (data && 'cartModel' in data ? data.cartModel : null);

    // update tmpl data attributes
    $('.availability-headers', tmpl).attr('data-availability-grouping', availabilityGrouping);
    $('.js-availability-grouping-append', tmpl).attr('data-availability-grouping', availabilityGrouping);

    if (cart) {
        // Update the title
        var statusLabel = cart.sortedAvailabilityItems[statusGroup[0]].statusLabel;
        $('.availability-title', tmpl).text(statusLabel);

        // update the estimated delivery if there is one
        var dateGroup = availabilityGrouping.split(statusGroup[0] + '-');
        var estimatedLabel = cart.sortedAvailabilityItems[statusGroup[0]][dateGroup[1]].cartlabel || null;
        var estimatedDate = cart.sortedAvailabilityItems[statusGroup[0]][dateGroup[1]].checkoutdate || null;
        if (estimatedLabel && estimatedDate) {
            $('.js-estimatedDelivery-container', tmpl).removeClass('d-none');
            $('.js-cart-estimatedDelivery', tmpl).before(estimatedLabel + ' ');
            $('.js-cart-estimatedDelivery', tmpl).text(estimatedDate);
        }
    }

    // add product card
    $(data.renderedTemplate).insertBefore($('.js-availability-grouping-append', tmpl));

    // add new section to cart
    $('.cart .product-cards').append(tmpl.html());
}

/**
 * Updates details of a product line item
 * @param {Object} data - AJAX response from the server
 * @param {string} uuid - The uuid of the product line item to update
 */
function updateProductDetails(data, uuid) {
    var availabilityGrouping = data.addedItemAvailabilityGrouping || null;
    // product card exists already
    if ($('.card .product-info.uuid-' + uuid).length > 0) {
        // check if the card needs to be moved to a different section
        var currentGrouping = $('.card .product-info.uuid-' + uuid).parent().attr('data-availability-grouping');

        if (currentGrouping !== availabilityGrouping) {
            var statusGroup = availabilityGrouping.split('-');

            // remove the current item and add a new one to the bottom of the new grouping
            $('.card .product-info.uuid-' + uuid).parent().remove();
            var $cartAvailabilityAppend = $('.cart .product-cards').find('.js-availability-grouping-append[data-availability-grouping=' + availabilityGrouping + ']');
            if ($cartAvailabilityAppend.length === 1) {
                // grouping already exists
                $(data.renderedTemplate).insertBefore($cartAvailabilityAppend);
            } else if (availabilityGrouping.indexOf('TBD') > -1) {
                // check if TBD and there is a status group already with a date (gift cards count here)
                var $statusExists = $('.cart .product-cards').find('.js-availability-grouping-append[data-availability-grouping^=' + statusGroup[0] + ']');
                if ($statusExists.length > 0) {
                    // dated status grouping already exists
                    $(data.renderedTemplate).insertBefore($statusExists[0]);
                } else {
                    // no grouping at all for the status, create it based on the template
                    createNewAvailabilityGrouping(availabilityGrouping, data);
                }
            } else {
                // no grouping at all for the status, create it based on the template
                createNewAvailabilityGrouping(availabilityGrouping, data);
            }
            // check if we need to remove the old section
            var topGroup =  currentGrouping ? currentGrouping.split('-') : null;
            var groupHasItems = topGroup && data.cartModel.sortedAvailabilityItems[topGroup[0]] && 'hasItems' in data.cartModel.sortedAvailabilityItems[topGroup[0]] ? data.cartModel.sortedAvailabilityItems[topGroup[0]].hasItems : false;
            if (!groupHasItems && topGroup && topGroup.length > 0) {
                // remove all elements for that availability group, no items are in it at all
                $('.cart .product-cards [data-availability-grouping^=' + topGroup[0] + ']').remove();
            }
        } else {
            // replace the product card with the updated one
            $('.card .product-info.uuid-' + uuid).parent().replaceWith(data.renderedTemplate);
        }
    } else {
        // brand new product card (added on the cart page itself)
        // find the correct availability grouping
        var $cartAvailabilityAppend = $('.cart .product-cards').find('.js-availability-grouping-append[data-availability-grouping=' + availabilityGrouping + ']');
        if ($cartAvailabilityAppend.length === 1) {
            // grouping already exists
            $(data.renderedTemplate).insertBefore($cartAvailabilityAppend);
        } else if (availabilityGrouping.indexOf('TBD') > -1) {
            // check if TBD and there is a status group already with a date (gift cards count here)
            var statusGroup = availabilityGrouping.split('-');
            var $statusExists = $('.cart .product-cards').find('.js-availability-grouping-append[data-availability-grouping^=' + statusGroup[0] + ']');
            if ($statusExists.length > 0) {
                // dated status grouping already exists
                $(data.renderedTemplate).insertBefore($statusExists[0]);
            } else {
                // no grouping at all for the status, create it based on the template
                createNewAvailabilityGrouping(availabilityGrouping, data);
            }
        } else {
            // no grouping at all for the status, create it based on the template
            createNewAvailabilityGrouping(availabilityGrouping, data);
        }
        // check if we need to remove the old section
        var topGroup =  currentGrouping ? currentGrouping.split('-') : null;
        var groupHasItems = topGroup && data.cartModel.sortedAvailabilityItems[topGroup[0]] && 'hasItems' in data.cartModel.sortedAvailabilityItems[topGroup[0]] ? data.cartModel.sortedAvailabilityItems[topGroup[0]].hasItems : false;
        if (!groupHasItems && topGroup && topGroup.length > 0) {
            // remove all elements for that availability group, no items are in it at all
            $('.cart .product-cards [data-availability-grouping^=' + topGroup[0] + ']').remove();
        }
    }
    base.enableQuantitySteppers();
}

core.init = function() {
    if ($('.cart-page').length) {
        handlePostCartAddAjax();
    }
    initCartWishlist();
    addRecommendationProductToCart();
    $('body').on('click', '.remove-product', function (e) {
        e.preventDefault();

        var actionUrl = $(this).data('action');
        var productID = $(this).data('pid');
        var productName = $(this).data('name');
        var uuid = $(this).data('uuid');
        module.exports.confirmDelete(actionUrl, productID, productName, uuid);
    });

    $('body').on('afterRemoveFromCart', function (e, data) {
        e.preventDefault();
        module.exports.confirmDelete(data.actionUrl, data.productID, data.productName, data.uuid);
    });

    $('body').on('click', '.cart-delete-confirmation-btn', function (e) {
        e.preventDefault();

        $('.cart-error').empty();

        var productID = $(this).data('pid');
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var isSaveForLater = $(this).attr('data-saveforlater') && $(this).attr('data-saveforlater') === 'true' ? true : false;
        var urlParams = {
            pid: productID,
            uuid: uuid,
            isSaveForLater: isSaveForLater
        };

        url = module.exports.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $('body').trigger('cart:beforeUpdate');

        $.spinner().start();
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data.basket.items.length === 0) {
                    $('.js-cart-explicit-recommendations').remove();
                    $('.cart').empty().append('<div class="cart-empty"><div class="row"> ' +
                        '<div class="col-12 text-center"> ' +
                        '<h1>' + data.basket.resources.emptyCartMsg + '</h1> ' +
                        '</div> ' +
                        '</div></div>'
                    );
                    $('.number-of-items').empty().append(data.basket.resources.numberOfItems);
                    $('.number-of-items-container').removeClass('text-md-right');
                    $('.minicart-quantity').empty().append(data.basket.numItems);
                    $('.minicart-link').attr({
                        'aria-label': data.basket.resources.minicartCountOfItems,
                        title: data.basket.resources.minicartCountOfItems
                    });
                    $('.minicart .popover').empty();
                    $('.minicart .popover').removeClass('show');
                    $('body').removeClass('modal-open');
                    $('html').removeClass('veiled');

                    sessionStorage?.setItem?.('cartcount', data.basket.numItems);
                } else {
                    if (data.toBeDeletedUUIDs && data.toBeDeletedUUIDs.length > 0) {
                        for (var i = 0; i < data.toBeDeletedUUIDs.length; i++) {
                            $('.uuid-' + data.toBeDeletedUUIDs[i]).closest('.card').remove();
                        }
                    }
                    // get the product's availability grouping
                    var availabilityGrouping = $('.uuid-' + uuid).closest('.card').attr('data-availability-grouping');

                    $('.uuid-' + uuid).closest('.card').remove();
                    $('.js-cart-explicit-recommendations.uuid-' +  uuid).remove();
                    if (!data.basket.hasBonusProduct) {
                        $('.bonus-product').remove();
                    }

                    // check if the availability grouping is empty as a whole
                    var topGroup = availabilityGrouping.split('-');
                    var groupHasItems = data.basket.sortedAvailabilityItems[topGroup[0]].hasItems;

                    if (groupHasItems) {
                        // get the second level grouping
                        var formattedDateGroup = availabilityGrouping.split(topGroup[0] + '-');

                        // check if the specific availability date grouping is defined even
                        var dateHasItems = data.basket.sortedAvailabilityItems[topGroup[0]][formattedDateGroup[1]] !== undefined;
                        var TBDHasItems = data.basket.sortedAvailabilityItems[topGroup[0]].TBD.items.length > 0;
                        if (!dateHasItems && !TBDHasItems) {
                            // remove all elements for that availability group based on the date, the date and TBD groups are empty
                            $('.cart .product-cards [data-availability-grouping=' + availabilityGrouping + ']').remove();
                        } else {
                            // group has items, check if the presonalization needs to be hidden (should never have to show personalization information on product deletion)
                            var hasMonogram = false;
                            if (dateHasItems) {
                                hasMonogram = data.basket.sortedAvailabilityItems[topGroup[0]][formattedDateGroup[1]].hasMonogram
                            } else if (TBDHasItems) {
                                hasMonogram = data.basket.sortedAvailabilityItems[topGroup[0]].TBD.hasMonogram
                            }
                            if (!hasMonogram) {
                                // hide the personalization messaging
                                $('.availability-headers[data-availability-grouping=' + availabilityGrouping + '] .js-cart-personalization-msg').addClass('d-none');
                                // if instock group, show the estimated date
                                if (topGroup[0] === 'instock') {
                                    $('.availability-headers[data-availability-grouping=' + availabilityGrouping + '] .js-estimatedDelivery-container').removeClass('d-none');
                                }
                            }
                        }
                    } else {
                        // remove all elements for that availability group, no items are in it at all
                        $('.cart .product-cards [data-availability-grouping^=' + topGroup[0] + ']').remove();
                    }

                    // NOTE: there's an open issue for problems with removing products with bonus items: https://github.com/SalesforceCommerceCloud/storefront-reference-architecture/issues/748
                    $('.coupons-and-promos').empty().append(data.basket.totals.discountsHtml);
                    module.exports.updateCartTotals(data.basket);
                    module.exports.updateApproachingDiscounts(data.basket.approachingDiscounts);
                    module.exports.updateFreeShippingMeter(data.basket);
                    module.exports.updateLTLCartAjax(data.basket);
                    module.exports.updateTieredPromoContent(data.basket);
                    updateExplicitRecommendations(data.basket);
                    $('body').trigger('setShippingMethodSelection', data.basket);
                    module.exports.validateBasket(data.basket);
                }

                if (data.giftwrap && data.giftwrap.cartError) {
                    module.exports.createErrorNotification(data.giftwrap.cartError);
                    scrollAnimate($('.cart-error'));
                }

                $('body').trigger('cart:update', [data, uuid]);

                $.spinner().stop();
            },
            error: function (err) {
                if ('responseJSON' in err && err.responseJSON) {
                    if ('redirectUrl' in err.responseJSON && err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    } else {
                        module.exports.createErrorNotification(err.responseJSON.errorMessage);
                        $.spinner().stop();
                    }
                } else {
                    location.reload();
                }
            }
        });
    });

    $('body').on('quantityStepper:change', (event, stepper) => {
        var $stepper = $(stepper);
        var isMiniCart = $stepper.closest('.minicart').length > 0;
        var selectId = $stepper.closest('.quantity-form').find('select').attr('id');
        var $select = $('.cart-page select#' + selectId);
        var value = parseInt($stepper.find('input').val());

        // if the qty change was triggered from the minicart, manually update cart qty input values
        if (isMiniCart) {
            var $cartStepper = $select.next('.quantity-stepper');
            $cartStepper.find('input').prop('value', value).prop('data-qty', value);
        }
    });

    $('body').on('change', '.quantity-form > .quantity', function() {
        var url = $(this).data('action');
        if (!url) {
            return;
        }

        var preSelectQty = $(this).data('pre-select-qty');
        var quantity = $(this).val();
        var productID = $(this).data('pid');
        var uuid = $(this).data('uuid');
        var urlParams = {
            pid: productID,
            quantity: quantity,
            uuid: uuid
        };
        url = module.exports.appendToUrl(url, urlParams);

        $(this).parents('.card').spinner().start();

        $('body').trigger('cart:beforeUpdate');

        $.ajax({
            url: url,
            type: 'get',
            context: this,
            dataType: 'json',
            success: function (data) {
                $('.quantity[data-uuid="' + uuid + '"]').val(quantity);
                $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                module.exports.updateCartTotals(data);
                module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                module.exports.updateFreeShippingMeter(data);
                module.exports.updateLTLCartAjax(data);
                module.exports.updateTieredPromoContent(data);
                module.exports.updateAvailability(data, uuid);
                module.exports.validateBasket(data);
                updateExplicitRecommendations(data);
                $(this).data('pre-select-qty', quantity);

                // TCS CUSTOM: Error message for product quota checks
                if (data.resources && data.resources.quantityQuotaErrorMessage) {
                    module.exports.createErrorNotification(data.resources.quantityQuotaErrorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                }

                $('body').trigger('cart:update', [data, uuid]);

                $.spinner().stop();
                if ($(this).parents('.product-info').hasClass('bonus-product-line-item') && $('.cart-page').length) {
                    location.reload();
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $(this).val(parseInt(preSelectQty, 10));
                    $.spinner().stop();
                }
            }
        });
    });

    $('.shippingMethods').change(function () {
        var url = $(this).attr('data-actionUrl');
        var urlParams = {
            methodID: $(this).find(':selected').attr('data-shipping-id')
        };
        // url = module.exports.appendToUrl(url, urlParams);

        $('.totals').spinner().start();
        $('body').trigger('cart:beforeShippingMethodSelected');
        $.ajax({
            url: url,
            type: 'post',
            dataType: 'json',
            data: urlParams,
            success: function (data) {
                if (data.error) {
                    window.location.href = data.redirectUrl;
                } else {
                    $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                    module.exports.updateCartTotals(data);
                    module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                    module.exports.updateFreeShippingMeter(data);
                    module.exports.updateLTLCartAjax(data);
                    module.exports.updateTieredPromoContent(data);
                    updateExplicitRecommendations(data);
                    module.exports.validateBasket(data);
                }
                $('body').trigger('cart:shippingMethodSelected', data);
                $.spinner().stop();
            },
            error: function (err) {
                if (err.redirectUrl) {
                    window.location.href = err.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });

    $('.promo-code-form').submit(function (e) {
        e.preventDefault();

        var currentItemCount = parseInt($('.minicart-quantity').text(), 10) || 0;
        var isMobileCheckout = $('#checkout-main').length && $(window).width() < 768 ? true : false;

        $.spinner().start();
        $('.coupon-missing-error').hide();
        $('.coupon-error-message').empty();
        if (!$('.coupon-code-field').val()) {
            $('.promo-code-form .form-control').addClass('is-invalid');
            $('.promo-code-form .form-control').attr('aria-describedby', 'missingCouponCode');
            $('.coupon-missing-error').show();
            $.spinner().stop();
            return false;
        }
        var $form = $('.promo-code-form');
        $('.promo-code-form .form-control').removeClass('is-invalid');
        $('.coupon-error-message').empty();
        $('body').trigger('promotion:beforeUpdate');

        var url = $form.attr('action');

        // TCS CUSTOM - pass checkout stage into the coupon add/remove for checkout
        var checkoutStages = [
            'customer',
            'shipping',
            'payment',
            'placeOrder',
            'submitted'
        ];
        if (checkoutStages.includes(window.history.state)) {
            var urlParams = {
                stage: window.history.state
            };
            url = module.exports.appendToUrl(url, urlParams);
        }

        $.ajax({
            url: url,
            type: 'GET',
            dataType: 'json',
            data: $form.serialize(),
            success: function (data) {
                if (data.error) {
                    $('.promo-code-form .form-control').addClass('is-invalid');
                    $('.promo-code-form .form-control').attr('aria-describedby', 'invalidCouponCode');
                    $('.coupon-error-message').empty().append(data.errorMessage);
                    $('body').trigger('promotion:error', data);
                    $('.coupon-code-field').trigger('focus');
                    $.spinner().stop();
                } else {
                    // If cart item was added/removed via promo code submit
                    if ((['', null, undefined].indexOf(data.numItems) === -1 && currentItemCount !== data.numItems) || data.action.indexOf('Checkout') > -1) {

                        // Clean Url Structure of any erroneous parameters
                        if (window.history && ['', null, undefined].indexOf(data.actionUrls.showUrl) === -1) {
                            history.replaceState({}, null, data.actionUrls.showUrl);
                        }

                        // Used for returning the user to the promo location after page refreshes
                        if (isMobileCheckout) {
                            window.sessionStorage.setItem('checkout-promo-added', '1');
                        }

                        // Force uncached reload
                        window.location.reload(true);
                    } else {
                        $('.coupons-and-promos').empty().append(data.totals.discountsHtml);
                        module.exports.updateCartTotals(data);
                        module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                        module.exports.updateFreeShippingMeter(data);
                        module.exports.updateLTLCartAjax(data);
                        module.exports.updateTieredPromoContent(data);
                        updateExplicitRecommendations(data);
                        module.exports.validateBasket(data);
                        $('body').trigger('promotion:success', data);
                        $('.coupon-code-field').val('');
                        $('body').trigger('tooltip:init');
                        $.spinner().stop();
                    }

                    // TCS CUSTOM: hide promo form if coupon was applied and the actual promo total > 0
                    if (data.totals.hasCouponApplied && data.totals.orderLevelDiscountTotal.value > 0) {
                        $('.js-coupon-form-container').addClass('d-none');
                    }
                }
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.errorMessage);
                    $.spinner().stop();
                }
            }
        });
        return false;
    });

    $('body').on('click', '.remove-coupon', function (e) {
        e.preventDefault();

        var couponCode = $(this).data('code');
        var uuid = $(this).data('uuid');
        var $deleteConfirmBtn = $('.delete-coupon-confirmation-btn');
        var $productToRemoveSpan = $('.coupon-to-remove');

        $deleteConfirmBtn.data('uuid', uuid);
        $deleteConfirmBtn.data('code', couponCode);

        $productToRemoveSpan.empty().append(couponCode);
    });

    $('body').on('click', '.delete-coupon-confirmation-btn', function (e) {
        e.preventDefault();

        var currentItemCount = parseInt($('.minicart-quantity').text(), 10) || 0;
        var url = $(this).data('action');
        var uuid = $(this).data('uuid');
        var couponCode = $(this).data('code');

        // TCS CUSTOM - pass checkout stage into the coupon add/remove for checkout
        var currentStage = 'customer';
        var checkoutStages = [
            'customer',
            'shipping',
            'payment',
            'placeOrder',
            'submitted'
        ];
        if (checkoutStages.includes(window.history.state)) {
            currentStage = window.history.state;
        }

        var urlParams = {
            code: couponCode,
            uuid: uuid,
            stage: currentStage
        };

        url = module.exports.appendToUrl(url, urlParams);

        $('body > .modal-backdrop').remove();

        $.spinner().start();
        $('body').trigger('promotion:beforeUpdate');
        $.ajax({
            url: url,
            type: 'get',
            dataType: 'json',
            success: function (data) {
                $('.coupon-uuid-' + uuid).remove();

                // If cart item was added/removed via promo code removal
                if ((['', null, undefined].indexOf(data.numItems) === -1 && currentItemCount !== data.numItems) || data.action.indexOf('Checkout') > -1) {
                    // Clean Url Structure of any erroneous parameters
                    if (window.history && ['', null, undefined].indexOf(data.actionUrls.showUrl) === -1) {
                        history.replaceState({}, null, data.actionUrls.showUrl);
                    }
                    // Force uncached reload
                    window.location.reload(true);
                } else {
                    module.exports.updateCartTotals(data);
                    module.exports.updateApproachingDiscounts(data.approachingDiscounts);
                    module.exports.updateFreeShippingMeter(data);
                    module.exports.updateLTLCartAjax(data);
                    module.exports.updateTieredPromoContent(data);
                    module.exports.validateBasket(data);
                    updateExplicitRecommendations(data);
                    $.spinner().stop();
                    $('body').trigger('promotion:success', data);
                    $("[data-toggle=popover]").popover(); // TCS CUSTOM: reinit promo popovers
                }

                // TCS CUSTOM: show promo form if applied order level discount value is not greater than 0
                if (!(data.totals.orderLevelDiscountTotal.value > 0)) {
                    $('.js-coupon-form-container').removeClass('d-none');
                }
            },
            error: function (err) {
                $('body').trigger('promotion:error', err);
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                } else {
                    module.exports.createErrorNotification(err.responseJSON.errorMessage);
                    $.spinner().stop();
                }
            }
        });
    });
    $('body').on('click', '.cart-page .bonus-product-button', function () {
        $.spinner().start();
        $(this).addClass('launched-modal');
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                $('body').trigger('bonusproduct:edit', data);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    });

    $('body').on('hidden.bs.modal', '#chooseBonusProductModal', function () {
        $('#chooseBonusProductModal').remove();
        $('.modal-backdrop').remove();
        $('body').removeClass('modal-open');

        if ($('.cart-page').length) {
            $('.launched-modal .btn-outline-primary').trigger('focus');
            $('.launched-modal').removeClass('launched-modal');
        } else {
            $('.product-detail .add-to-cart').focus();
        }
    });

    $('body').on('change', '.quantity-select', function () {
        var selectedQuantity = $(this).val();
        $('.modal.show .update-cart-url').data('quantity', selectedQuantity);
    });

    $('body').on('click', '.update-cart-product-global', function (e) {
        e.preventDefault();
        var updateProductUrl = $(this).closest('.cart-and-ipay').find('.update-cart-url').val();
        var form = $(this).closest('.cart-and-ipay').find('.update-cart-url').data();

        if (form) {
            form.selectedOptionValueIds = base.methods.getOptions($('#quickViewModal'));
            form.pid = module.exports.getPidValue($(this))
            $(this).parents('.card').spinner().start();
            $('body').trigger('cart:beforeUpdate');

            if (updateProductUrl) {
                $.ajax({
                    url: updateProductUrl,
                    type: 'post',
                    context: this,
                    data: form,
                    dataType: 'json',
                    success: function (data) {
                        $('#quickViewModal').modal('hide');

                        $('.coupons-and-promos').empty().append(data.cartModel.totals.discountsHtml);
                        module.exports.updateCartTotals(data.cartModel);
                        module.exports.updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                        module.exports.updateFreeShippingMeter(data.cartModel);
                        module.exports.updateAvailability(data.cartModel, form.uuid);
                        module.exports.updateLTLCartAjax(data.cartModel);
                        module.exports.updateTieredPromoContent(data.cartModel);
                        module.exports.updateProductDetails(data, form.uuid);

                        wishlistHelpers.updateLinkData(); // TCS update: fixes AB bug

                        if (data.uuidToBeDeleted) {
                            $('.uuid-' + data.uuidToBeDeleted).parent().remove();
                        }

                        if (data.giftwrap && data.giftwrap.giftwrapProductRemoved) {
                            if (data.giftwrap.UUID) {
                                $('.uuid-' + data.giftwrap.UUID).parent().remove();
                            }
                            if (data.giftwrap.giftwrapErrorMsg && data.giftwrap.giftwrapErrorMsg.length > 0) {
                                module.exports.createErrorNotification(data.giftwrap.giftwrapErrorMsg);
                            }
                        }

                        // TCS CUSTOM: Error message for product quota checks
                        if (data.quantityQuotaErrorMessage) {
                            module.exports.createErrorNotification(data.quantityQuotaErrorMessage);
                        }

                        module.exports.validateBasket(data.cartModel);

                        $('body').trigger('cart:update', [data, form.uuid]);

                        $.spinner().stop();
                    },
                    error: function (err) {
                        if (err.responseJSON.redirectUrl) {
                            window.location.href = err.responseJSON.redirectUrl;
                        } else {
                            module.exports.createErrorNotification(err.responseJSON.errorMessage);
                            $.spinner().stop();
                        }
                    }
                }).then(function (data) {
                    updateExplicitRecommendations(data.cartModel); // Do after to avoid timing issue with updateProductDetails()
                });
            }
        }
    });

    $('body').on('product:afterAddToCartQuickview', () => {
        $('#quickViewModal').modal('hide');
        var verifyCartPage = $('body').find('.page').data('action');
        if (verifyCartPage == 'Cart-Show') {
            location.reload();
        }
    });

    //responsible for filling edit gift cert modal with information from line item on cart page
    $('body').on('click', 'a.edit.editGiftCertificateLineItem', function (e) {
        e.preventDefault();

        var anchor = $(this);
        var uuid = anchor.data('uuid');
        var urlParams = {
            giftUUID: uuid
        };

        var $modal = $('#editGiftCertificateLineItemModal');
        $modal.find('.modal-body').spinner().start();
        // TCS CUSTOM: Get the gift card field values
        var giftCardUrl = anchor.data('gift-url');
        $.ajax({
            url: giftCardUrl,
            data: urlParams,
            method: 'GET',
            dataType: 'json',
            success: function (data) {
                var $form = $modal.find('.modal-body form');
                // replace the amount select
                var $amountSelect = $form.find('#amount');
                $amountSelect.find('option').not(':first').remove();
                var amounts = data.AmountOptions;
                for (var i = 0; i < amounts.length; i++) {
                    var content='<option id="' + amounts[i].Id + '" value="' + parseInt(amounts[i].Value).toFixed(1) + '">' + amounts[i].Label + '</option>';
                    $amountSelect.append(content);
                }

                var giftCardData = data.giftCardData[0];
                // select correct amount
                $form.find('#amount option[value="' + parseInt(giftCardData.AMOUNT).toFixed(1) + '"]').prop('selected', true);
                // from
                $form.find('#from').val(giftCardData.FROM);
                // to
                $form.find('#recipient').val(giftCardData.NAME);
                // recipients email
                $form.find('#recipientEmail').val(giftCardData.EMAIL);
                // recipients emai confirmation
                $form.find('#confirmRecipientEmail').val(giftCardData.EMAIL);
                // message
                if ('MESSAGE' in giftCardData && giftCardData.MESSAGE.length > 0) {
                    $form.find('textarea#egift-message').val(giftCardData.MESSAGE).trigger("input");
                }

                $modal.find('.btn-update-giftcertificate-incart').attr('data-uuid', uuid);
                $modal.find('.btn-update-giftcertificate-incart').data('uuid', uuid);
            },
            error: function (err) {
                console.error('There was an issue retrieving this asset: ', err);
            }
        }).always(function() {
            $modal.find('.modal-body').spinner().stop();
        });
    });

    // TCS Custom - reinit promo popovers
    $('body').on('cart:update', () => {
        if ($('[data-toggle="popover"]').length) {
            $('[data-toggle="popover"]').popover();
        }
    });

    // TCS CUSTOM - Trigger for use in 3rd party scripts to trigger custom cart updates
    $('body').on('cart:thirdPartyUpdate', function(e, data) {
        // update the cart with data from the model
        module.exports.updateCartTotals(data);
        module.exports.updateApproachingDiscounts(data.approachingDiscounts);
        module.exports.updateFreeShippingMeter(data);
        module.exports.updateLTLCartAjax(data);
        module.exports.updateTieredPromoContent(data);
    });

    // TCS Custom - on Checkout, scroll to promo code after applying and page refresh
    if (window.sessionStorage.getItem('checkout-promo-added') && window.sessionStorage.getItem('checkout-promo-added') === '1') {
        window.sessionStorage.removeItem('checkout-promo-added');
        if ($('.checkout-promo-code-section').length) {
            scrollAnimate($('.checkout-promo-code-section'));
        }
    }
}

/**
 * Retrieves the wishlist to display on the Cart page
 */
function initCartWishlist() {
    if ($('.js-cart-wishlist').length) {
        try {
            $.ajax({
                url: $('.js-cart-wishlist').data('url'),
                method: 'GET',
                success: response => {
                    if (response && response.html) {
                        $('.js-cart-wishlist').html(response.html);
                    }
                },
                error: err => {}
            }).done(function () {
                if ($('.wishlist-slider').length) {
                    abSlider.initializeSliders($('.wishlist-slider'));
                }
            });
        } catch (e) {}
    }
}

// For handling ATC on wishlist items on the Cart page
function handlePostCartAddAjax() {
    $('body').on('addCartAjaxUpdate', function (event, data) {
        $('.coupons-and-promos').empty().append(data.cart.totals.discountsHtml);
        module.exports.updateCartTotals(data.cart);
        module.exports.updateApproachingDiscounts(data.cart.approachingDiscounts);
        module.exports.updateFreeShippingMeter(data.cart);
        module.exports.updateLTLCartAjax(data.cart);
        module.exports.updateTieredPromoContent(data.cart);
        updateExplicitRecommendations(data.cart);
        $('body').trigger('setShippingMethodSelection', data.cart);
        module.exports.validateBasket(data.cart);

        // trigger cart:update so additional 3rd party scripts dependent on this run
        $('body').trigger('cart:update', data.cart);

        var addedUUID = data.pliUUID && data.pliUUID.length > 0 ? data.pliUUID[0] : null;
        if (addedUUID) {
            module.exports.updateProductDetails(data, addedUUID);
        }
    });
}

core.updateCartTotals = updateCartTotals;
core.updateAvailability = updateAvailability;
core.updateFreeShippingMeter = updateFreeShippingMeter;
core.updateLTLCartAjax = updateLTLCartAjax;
core.updateProductDetails = updateProductDetails;
core.updateTieredPromoContent = updateTieredPromoContent;
core.handlePostCartAddAjax = handlePostCartAddAjax;

module.exports = core;
