'use strict'

/**
 * This function grabs the option values in mobile to redirect the user to the correct page.
 */
function accountHeaderMobile() {
    $('body').on('change', '.js-account-header-nav', function () {
        window.location.href = $(this).val();
    });
}

module.exports = {
    accountHeaderMobile: accountHeaderMobile
};
