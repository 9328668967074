'use strict';

// TCS file override to remove all minicart functionality aside from count update

module.exports = function () {
    $('.minicart').on('count:update', (event, count) => {
        if (count && $.isNumeric(count.quantityTotal)) {
            $('.minicart .minicart-quantity').text(count.quantityTotal);
            $('.minicart .minicart-link').attr({
                'aria-label': count.minicartCountOfItems,
                title: count.minicartCountOfItems
            });

            sessionStorage?.setItem?.('cartcount', count.quantityTotal);
        }
    });
};
