'use strict';

var core = require('core/wishlist/wishlist');

core.baseFiles.wishlistShowLists = require('./components/modalShowLists');
core.baseFiles.toast = require('./components/toast');
core.baseFiles.wishlistToggleProduct = require('./components/toggleProduct');
core.baseFiles.wishlistDetail = require('./detail');

module.exports = core;
