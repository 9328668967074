'use strict';

function loadVideoOnResize() {
    var delay = (function(){
        var timer = 0;
        return function(callback, ms){
            clearTimeout (timer);
            timer = setTimeout(callback, ms);
        };
    })();

    $(window).on('resize', function() {
        // check that the video element exists
        if ($('.tcs-video').length) {
            var $video = $('.tcs-video video');
            $video.each(function() {
                // pause video while resizing/swapping it out to help avoid flickering
                if (!(this.paused)) {
                    this.pause();
                }
            });

            delay(function() {
                // start playing after a 250ms pause to help avoid flickering
                $video.each(function() {
                    var videoSrc;
                    if (window.innerWidth > 768) {
                        videoSrc = $(this).find('.desktopVideoSrc');
                    } else {
                        videoSrc = $(this).find('.mobileVideoSrc') || $(this).find('.desktopVideoSrc');
                    }
                    // only change if the source is different
                    if (this.currentSrc !== videoSrc.attr('src')) {
                        this.load();
                        this.addEventListener('loadedmetadata', function() {
                            this.play();
                        });
                    } else if (this.paused) {
                        this.play();
                    }

                });
            }, 250);
        }
    });
}

module.exports = {
    loadVideoOnResize: loadVideoOnResize
};
