'use strict';
if (window && !window.$) { // this check is for SG, in SG window.$ is already exist and throw error on reinitialize
    window.jQuery = window.$ = require('jquery');
}

var $prCategorySnippets = $('.pr-category-snippet');
var $prWarForm = $('.pr-war-form');
var $prPdpComponents = $('.pr-pdp-components');
var headerUtils = require('core/utilities/headerUtils');

/* eslint-disable no-undef */

/**
 * @function prLoadWarForm
 */
function prLoadWarForm() {
    if (window.POWERREVIEW) {
        window.pwr = window.pwr || function () {
            (pwr.q = pwr.q || []).push(arguments);
        };
        if ($prWarForm.length > 0 && $prWarForm.data('pr-war-form')) {
            var prWarFormData = $prWarForm.data('pr-war-form');
            // add on_submit callback method
            prWarFormData.on_submit = function () { // eslint-disable-line
                window.scrollTo(0, 0);
            };
            pwr('render', prWarFormData);
        }
    }
}

/**
 * @function prLoadIRLGalleries
 * Loads UGC Galleries
 */
function prLoadIRLGalleries() {
    try {
        if (window.POWERREVIEW) {
            var galleries = $('.js-pr-irlsnippet');
        
            for (var i = 0; i < galleries.length; i++) {
                var gallery = $(galleries[i]);

                if (gallery.attr('data-pr-mounted') !== 'undefined' && gallery.attr('data-pr-mounted') !== true) {
                    window.pwr = window.pwr || function () {
                        (pwr.q = pwr.q || []).push(arguments);
                    };
                    pwr("render", {
                        api_key: window.POWERREVIEW.api_key,
                        locale: window.POWERREVIEW.locale,
                        merchant_id: window.POWERREVIEW.merchant_id,
                        CD4_IRL_IMAGE_GALLERY_STYLE: gallery.attr('data-custom-styling'),
                        components: {
                            IRLSnippet: gallery.attr('id'),
                        }
                    });
                }
            }
        }
    } catch (e) {
        console.log('prLoadIRLGalleries():' + e.toString());
    }
}

/**
 * @function prLoadPdpComponents
 * @param {Object} powerReviewsData --powerReviewsData from product variant
 */
function prLoadPdpComponents(powerReviewsData) {
    if (window.POWERREVIEW) {
        window.pwr = window.pwr || function () {
            (pwr.q = pwr.q || []).push(arguments);
        };
        if ($prPdpComponents.length > 0 || powerReviewsData) {
            var prPdpData = powerReviewsData || $prPdpComponents.data('pr-pdp-components');

            // TCS Update - adds callback and clones mobile reviews snippet to desktop view
            prPdpData.on_render = (config, _data) => {
                if (config.component && config.component === 'ReviewSnippet') {
                    var reviewSnippets = $('[id="pr-reviewsnippet"]');
                    if (reviewSnippets.length === 2) {
                        var $mobileSnippet = $(reviewSnippets[0]);
                        var $desktopSnippet = $(reviewSnippets[1]);
                        var mobileReviewContent = $mobileSnippet.html();
                        $desktopSnippet.html(mobileReviewContent);
                        $desktopSnippet.find('.p-w-r').show();
                        // override PR event with better scroll positioning
                        $('.pr-snippet-stars-reco-stars').on('click', event => {
                            event.preventDefault();
                            var $reviewSection = $('.pr-pdp-review-display');
                            if ($reviewSection.length > 0) {
                                var reviewSectionOffsetTop = $('.pr-pdp-review-display').offset().top;
                                var reviewSectionMarginTop = $('.pr-pdp-review-display').css('margin-top').split('px')[0] || 0;
                                var headerHeight = headerUtils.getHeaderHeightNavOnly();
                                var position = reviewSectionOffsetTop - reviewSectionMarginTop - headerHeight;
                                setTimeout(() => { // using setTimeout to override default PR scroll positioning
                                    $('html, body').animate({
                                        scrollTop: position
                                    }, 500);
                                });
                            }
                        });
                    }
                }
            }

            pwr('render', prPdpData);
        }
    }
}

$(document).ready(function () {
    $(document).ajaxComplete(function (event, xhr, settings) {
        if ($('#product-content').length > 0) { // this is for SG PDP variant change
            $prPdpComponents = $('.pr-pdp-components');
            prLoadPdpComponents();
        }
        if (settings.url.includes('Cart-GetProduct') || settings.url.includes('Product-ShowQuickView') || settings.url.includes('Wishlist-GetProduct')) {
            var prData = $('#quickViewModal').find('#pr-reviewsnippet').data('quickview-pr');
            prLoadPdpComponents(prData);
        }
    });
    if ($prWarForm.length > 0) { // only fire if user is on write review page
        prLoadWarForm();
    }
    if ($prPdpComponents.length > 0) { // only fires if user is on PDP page.
        prLoadPdpComponents();
    }

    // UGC/"IRL" Galleries
    if ($('.js-pr-irlsnippet').length) {
        prLoadIRLGalleries();
    }
    $('body').on('prGallery:init', prLoadIRLGalleries);
});