'use strict';

var einsteinUtils = require('./einsteinUtils');
var productTile = require('./productTile');
var abSlider = require('./slider');

module.exports = {};

/**
 * Gets all placeholder elements, which hold einstein recommendations queries the details from the
 * einstein engine and feeds them back to the dom element
 * @param {Object} e jquery event
 * @returns {Object} jquery targets
 */
module.exports.loadRecommendations = function ($context) {
    var $recommendationTargets = $context.hasClass('.einstein-data') ? $context : $context.find('.einstein-data');
    $recommendationTargets = $recommendationTargets.filter((i, rec) => {
        return !$(rec).hasClass('einstein-loading') && $(rec).find('.product[data-pid="placeholder"]').length > 0;
    });

    return $recommendationTargets.addClass('einstein-loading').each(function () {
        var $recTrigger = $(this);
        var data = $recTrigger.data() || {};
        var asyncUrl = data.url;

        // TCS CUSTOM START
        var fullEinsteinUtils = einsteinUtils.getEinsteinUtils();
        if (!fullEinsteinUtils.getRecs) {
            // einstein (cquotient) disabled or blocked or is being loaded later and not on start
            // hide the associated slider title if there is one and run the rest of the logic for safety
            var $recTitle = $recTrigger.closest('.slider-container').parent().find('.slider-title');
            if ($recTitle.length > 0) {
                // Only hide the title if not in PD edit mode
                if ($recTitle.parent().attr('data-sfcc-pd-component-info') === undefined) {
                    $recTitle.addClass('d-none');
                }
            }
        }
        // TCS CUSTOM END

        einsteinUtils.getRecommendations(data, function (einsteinResponse) {
            var response = einsteinUtils.interpretResponse(data, einsteinResponse);

            // TCS CUSTOM START
            // no recommendations in the response, hide the associated slider title if there is one and run the rest of the logic for safety
            if (response.length === 0) {
                var $recTitle = $recTrigger.closest('.slider-container').parent().find('.slider-title');
                if ($recTitle.length > 0) {
                    // Only hide the title if not in PD edit mode
                    if ($recTitle.parent().attr('data-sfcc-pd-component-info') === undefined) {
                        $recTitle.addClass('d-none');
                    }
                }
            } else if (response.length > 0) {
                // double check for a title and show it in case Einstein (cquotient) was disabled/blocked/or loaded later on
                // earlier and is now enabled and has a response length for this slider
                var $recTitle = $recTrigger.closest('.slider-container').parent().find('.slider-title');
                if ($recTitle.length > 0) {
                    // safety code to show the title since there is a repsonse from Einstein for this slider
                    $recTitle.removeClass('d-none');
                }
            }
            // TCS CUSTOM END

            // Add replace content URL
            $.each(response, function (i, rec) {
                if (!rec || !rec.id) return null;

                var pidURL = asyncUrl;
                pidURL += (pidURL.indexOf('?') === -1 ? '?' : '&') + 'pid=' + rec.id;
                $recTrigger.find('.product[data-pid="placeholder"]:not([data-replace-content])')
                    .first()
                    .attr('data-replace-content', pidURL); //Must be attr to allow productTile.js async selector rule to catch recommendation
            });

            // Remove empty slides
            $recTrigger.removeClass('einstein-loading').find('.slide:has(.product[data-pid="placeholder"]:not([data-replace-content]))').remove();

            // Prepare Slider Container
            var $sliderContainer = $recTrigger.closest('.slider-container').removeClass('delay-init');

            productTile.init($recTrigger.find('.product'), function (status) {
                // Re Initialize sliders to account for TileInclude Errors
                var tns = status.errors > 0 ? $sliderContainer.data('tns') : null;

                if (tns) {
                    tns.destroy();
                    tns.rebuild();
                }
            });

            // Initialize sliders
            abSlider.initializeSliders($sliderContainer.parent());
        });
    });
}

module.exports.init = function (context) {
    var $context = context && context instanceof jQuery ? context : $(document);
    module.exports.loadRecommendations($context);
};
