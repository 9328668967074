'use strict';

var productBase = require('./../product/base');
var core = require('core/wishlist/detail');
var toastHelpers = require('core/components/toast');
var wishlistHelpers = require('client_core/wishlist/components/helpers');
var abSlider = require('client_core/components/slider');

var cardProductEventClickAddCart = function(e) {
    e.preventDefault();
    $.spinner().start();
    var $this = $(this);
    var $cardProduct = $this.parents('.wishlist-card-product');
    var addToCartUrl = $this.data().url;
    var pid = $cardProduct.data('pid');
    var quantity = $cardProduct.find('.wishlist-card-product-quantity').val();
    var options = JSON.stringify($this.data().options);
    var isCart = $('.cart-page').length ? true : false;
    var cartPageEmpty = $('.cart-empty').length ? true : false;

    var formData = {
        pid,
        quantity,
        options,
        isWishlist: isCart
    }

    if (!isCart) {
        productBase.methods.launchAddToBagModal(formData); // TCS custom
    }

    $.ajax({
        url: addToCartUrl,
        method: 'POST',
        data: formData,
        dataType: 'json',
        success: function (data) {
            $('body').trigger('product:afterAddToCart', data);
            productBase.methods.miniCartReportingUrl(data.reportingURL, data.error);

            var gtmData = JSON.parse($cardProduct.attr('data-gtmdata'));
            var qty = $cardProduct.find('.wishlist-card-product-quantity').val();
            $('body').trigger('product:GTMaddToCart', {
                gtmData: gtmData,
                qty: qty,
                data: data
            });

            if (isCart) {
                wishlistHelpers.updateUncachedData({
                    actionType: 'remove',
                    pid: pid
                });
                wishlistHelpers.updateLinkData(false, data);

                // Wishlist tile add to cart on Cart page
                if (cartPageEmpty) {
                    window.location.reload();
                } else {
                    $('.minicart').trigger('count:update', data);
                    $('body').trigger('addCartAjaxUpdate', data);
                    if (data.message) {
                        toastHelpers.methods.show('success', data.message, true);
                    }
                    // remove added product from wishlist
                    if ('wishlistProductRemoved' in data && data.wishlistProductRemoved) {
                        wishlistHelpers.removeWishlistTileFromSlider(pid);
                    }

                    $.spinner().stop();
                }
            } else {
                productBase.methods.handlePostCartAdd(data);
                $.spinner().stop();
            }
        },
        error: function () {
            $.spinner().stop();
        }
    });
};

var eventOverlayClickAddToCart = function(e) {
    e.preventDefault();
    var selectedListAndProducts = module.exports.methods.overlayGetSelectedListAndProducts();
    var addToCartUrl = $(this).data().url;
    if (selectedListAndProducts.pidsObj.length) {
        $.spinner().start();
        selectedListAndProducts.pidsObj = JSON.stringify(selectedListAndProducts.pidsObj);

        var formData = {
            pidsObj: selectedListAndProducts.pidsObj
        }
        productBase.methods.launchAddToBagModal(formData); // TCS custom

        $.ajax({
            url: addToCartUrl,
            method: 'POST',
            data: formData,
            success: function (data) {
                productBase.methods.handlePostCartAdd(data);
                $('body').trigger('product:afterAddToCart', data);
                module.exports.methods.overlayDeselectAll();
                productBase.methods.miniCartReportingUrl(data.reportingURL, data.error);
                $('body').trigger('product:GTMaddToCart', {
                    multipleProducts: true,
                    gtmData: addToCartWishlistProductsGTMEvent(data),
                    data: data
                });

                // Klaviyo Added to Cart events for multiple products added at once
                if ('KLMultiPids' in data) {
                    $('body').trigger('product:KLaddedToCart', {
                        data: data.KLMultiPids
                    });
                }

                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
};

function addToCartWishlistProductsGTMEvent(data){
    var result = [];
    var addedPLIUUIDs = data.pliUUID;

    for (var i = 0; i < addedPLIUUIDs.length; i++) {
        var pliUUID = addedPLIUUIDs[i];
        var addedPLI = data.cart.items.filter(function (item) {
            return item.UUID === pliUUID;
        });

        if (addedPLI && addedPLI.length > 0) {
            var wishlistCard = $('.wishlist-card-product[data-pid="' + addedPLI[0].id + '"]');
            result.push({
                'gtmData': addedPLI[0].gtmData,
                'qty': wishlistCard.find('.wishlist-card-product-quantity').val()
            });
        }

    }

    return result;
}

var eventClickAddToCart = function (e) {
    e.preventDefault();
    var $this = $(this);
    var $products = $this.parents('.wishlist-details').find('.wishlist-card-product:not(.unavailable):not(.product-type-master)');
    var addToCartUrl = $this.data().url;
    var pidsObj = [];

    $products.each(function () {
        var $product = $(this);
        if ($product.hasClass('product-type-set')) {
            var toastMessage = $product.data('message');
            toastHelpers.methods.show('danger', toastMessage, true);
        } else {
            pidsObj.push({
                pid: $product.data().pid,
                qty: $product.find('.wishlist-card-product-quantity').val(),
                options: $product.find('.wishlist-card-product-add').attr('data-options')
            });
        }
    });

    if (pidsObj.length) {
        $.spinner().start();

        var formData = {
            pidsObj: JSON.stringify(pidsObj)
        }

        productBase.methods.launchAddToBagModal(formData); // TCS custom

        $.ajax({
            url: addToCartUrl,
            method: 'POST',
            data: formData,
            success: function (data) {
                productBase.methods.handlePostCartAdd(data);
                $('body').trigger('product:afterAddToCart', data);
                $('body').trigger('product:GTMaddToCart', {
                    multipleProducts: true,
                    gtmData: addToCartWishlistProductsGTMEvent(data),
                    data: data
                });

                // Klaviyo Added to Cart events for multiple products added at once
                if ('KLMultiPids' in data) {
                    $('body').trigger('product:KLaddedToCart', {
                        data: data.KLMultiPids
                    });
                }

                productBase.methods.miniCartReportingUrl(data.reportingURL, data.error);
                $.spinner().stop();
            },
            error: function () {
                $.spinner().stop();
            }
        });
    }
};

/**
 * Quickview Modal
 */
var quickviewModalEventClickUpdate = function(e) {
    var wishlistHelpers = require('client_core/wishlist/components/helpers');
    e.preventDefault(e);

    var $this = $(this);
    var $modal = $('#quickViewModal');
    var detailPageSize = wishlistHelpers.getUrlParam('sz') ? Number(wishlistHelpers.getUrlParam('sz')) : null;
    var isCart = $('.cart-page').length ? true : false;
    var form = {
        pid: $this.data().pid,
        pidUpdated: $this.data().pidUpdated,
        uuid: $this.data().uuid,
        listId: isCart ? null : $('.wishlist-details').data().listId,
        selectedOptionValueIds: productBase.methods.getOptions($modal),
        detailPageSize: detailPageSize,
        isCart: isCart
    };

    $modal.find('.modal-content').spinner().start();
    $.ajax({
        url: $this.data().url,
        type: 'POST',
        data: form,
        dataType: 'json',
        success: function (data) {
            var $productsHtml = $(data.renderedTemplate);

            // Update content on page
            if (!data.error && $productsHtml && $productsHtml.length) {
                if (isCart && form.pidUpdated) {
                    // TCS CUSTOM
                    $('.wishlist-slider .slider').trigger('slider:destroy');
                    var originalProductTile = $('.wishlist-card-product[data-pid="'+ form.pid + '"]').length ? $('.wishlist-card-product[data-pid="'+ form.pid + '"]').parent() : null;
                    if (originalProductTile && originalProductTile.length > 0) {
                        for (var i = 0; i < originalProductTile.length; i++) {
                            $(originalProductTile[i]).remove();
                        }
                        $('.wishlist-slider .slider').prepend('<div data-position="0" class="slide">' + $productsHtml[0].outerHTML + '</div>');
                        abSlider.initializeSliders($('.wishlist-slider'));
                        if (data.message) {
                            toastHelpers.methods.show('success', data.message, true);
                        }
                    }
                } else {
                    $('.wishlist-details').trigger('updateProducts', $productsHtml);
                }

                if (form.pidUpdated) {
                    wishlistHelpers.updateUncachedData({
                        actionType: 'change',
                        pid: form.pid,
                        pidUpdated: form.pidUpdated
                    });
                }

                $this.attr('data-gtmdata', JSON.stringify(data.gtmData));
            } else {
                toastHelpers.methods.show('danger', data.message, true);
                $modal.find('.modal-content').spinner().stop();
            }
            $modal.modal('hide');
        },
        error: function () {
            $modal.modal('hide');
            $modal.find('.modal-content').spinner().stop();
        }
    });
};

var eventUpdateProducts = function (e, $products) {
    $.spinner().start();
    core.methods.unsetSelectedProductsState();
    $('.wishlist-details')
        .find('.wishlist-details-products')
        .replaceWith($products);
    productBase.enableQuantitySteppers();
    $.spinner().stop();

    setTimeout(() => {
        wishlistHelpers.updateLinkData(); // TCS Customization: update recommended slider hearts when list changes
    });
};

var toggleWishlistNav = function($updatedProducts) {
    var $wishlistDetailsNav = $('.wishlist-details-nav');
    var updatedProductsCount = $updatedProducts.find('.wishlist-card-product').length;
    if (updatedProductsCount > 0) {
        $wishlistDetailsNav.removeClass('d-none');
    } else {
        $wishlistDetailsNav.addClass('d-none');
    }
}

// update wishlist when items are added/removed via product tiles
var handleWishlistUpdates = function() {
    $('body').on('wishlist:update', (_event, data) => {
        if ($('.wishlist-details').length) {
            var url = data.wishlistUpdateUrl;
            var $wishlistContainer = $('.wishlist-details-products');
            $.spinner().start();

            $.ajax({
                url: url,
                method: 'GET',
                success: response => {
                    var $updatedProducts = $(response);
                    $wishlistContainer.replaceWith($updatedProducts);
                    toggleWishlistNav($updatedProducts);
                    $.spinner().stop();
                },
                error: err => {
                    console.error('Error updating wishlist', err);
                    $.spinner().stop();
                }
            });
        }
    });
}

/**
 * Remove single item from wishlist
 */
var deleteProductModalEventShow = function(e) {
    $(this)
        .attr({
            'data-list-id': e.relatedTarget.data().listId,
            'data-list-item-id': e.relatedTarget.data().listItemId,
            'data-product-id': e.relatedTarget.data().productId // TCS update to add product ID
        })
        .data({
            listId: e.relatedTarget.data().listId,
            listItemId: e.relatedTarget.data().listItemId,
            productId: e.relatedTarget.data().productId
        });
};

var deleteProductModalEventClickDelete = function(e) {
    e.preventDefault();
    var $modal = $('#wishlistModalDeleteProduct');
    var detailPageSize = wishlistHelpers.getUrlParam('sz') ? Number(wishlistHelpers.getUrlParam('sz')) : null;
    $modal.spinner().start();
    $.ajax({
        url: $modal.data().url,
        method: 'POST',
        dataType: 'json',
        data: {
            listId: $modal.data().listId,
            listItemId: $modal.data().listItemId,
            detailPageSize: detailPageSize,
            returnTemplate: true
        },
        success: function(data) {
            var $productsHtml = $(data.renderedTemplate);
            if (!data.error && $productsHtml.length) {
                $('.wishlist-details').trigger('updateProducts', $productsHtml);
                toggleWishlistNav($productsHtml);

                // TCS customization: update uncached data so product tiles can update
                wishlistHelpers.updateUncachedData({
                    actionType: 'remove',
                    pid: $modal.data().productId
                });
            } else {
                toastHelpers.methods.show('danger', data.message, true);
                $.spinner().stop();
            }
            $modal.modal('hide');
        },
        error: function() {
            $modal.modal('hide');
            $.spinner().stop();
        }
    });
}

/**
 * Remove multiple items from wishlist
 */
var eventOverlayClickRemove = function(e) {
    e.preventDefault();
    var selectedListAndProducts = module.exports.methods.overlayGetSelectedListAndProducts();
    $('#wishlistModalDeleteProducts')
        .attr({
            'data-list-id': selectedListAndProducts.listId,
            'data-list-item-ids': selectedListAndProducts.listItemIds,
            'data-product-ids': selectedListAndProducts.pids // TCS update to add product IDs
        })
        .data({
            'listId': selectedListAndProducts.listId,
            'listItemIds': selectedListAndProducts.listItemIds,
            'productIds': selectedListAndProducts.pids
        })
        .modal('show');
};

var deleteProductsModalEventClickDelete = function(e) {
    e.preventDefault();
    var $modal = $('#wishlistModalDeleteProducts');
    var detailPageSize = wishlistHelpers.getUrlParam('sz') ? Number(wishlistHelpers.getUrlParam('sz')) : null;
    $modal.spinner().start();
    $.ajax({
        url: $modal.data().url,
        method: 'POST',
        dataType: 'json',
        data: {
            listId: $modal.data().listId,
            listItemIds: JSON.stringify($modal.data().listItemIds),
            detailPageSize: detailPageSize
        },
        success: function(data) {
            var $productsHtml = $(data.renderedTemplate);
            if (!data.error && $productsHtml.length) {
                $('.wishlist-details').trigger('updateProducts', $productsHtml);
                $('.wishlist-overlay-detail-selected').trigger('close');
                toggleWishlistNav($productsHtml);

                // TCS customization: update uncached data so product tiles can update
                $.each($modal.data().productIds, (_i, productId) => {
                    wishlistHelpers.updateUncachedData({
                        actionType: 'remove',
                        pid: productId
                    });
                });
            } else {
                toastHelpers.methods.show('danger', data.message, true);
                $.spinner().stop();
            }
            $modal.modal('hide');
        },
        error: function() {
            $modal.modal('hide');
            $.spinner().stop();
        }
    });
}

var cardProductEventClickRemove = function(e) {
    e.preventDefault();

    if ($('#wishlistModalDeleteProduct').length) {
        $('#wishlistModalDeleteProduct')
        .modal('show', $(this));
    } else {
        // TCS CUSTOM: Remove wishlist detail product tile - used on Cart page
        var url = $(this).attr('data-url');
        var removedPid = $(this).attr('data-product-id');

        if (url) {
            $.spinner().start();
            $.ajax({
                url: url,
                method: 'POST',
                dataType: 'json',
                data: {
                    listId: $(this).attr('data-list-id'),
                    listItemId: $(this).attr('data-list-item-id'),
                    returnTemplate: false
                },
                success: function(data) {
                    if (!data.error) {
                        wishlistHelpers.removeWishlistTileFromSlider(removedPid);
                        if (data.message) {
                            toastHelpers.methods.show('success', data.message, true);
                        }

                        wishlistHelpers.updateUncachedData({
                            actionType: 'remove',
                            pid: removedPid
                        });
                        wishlistHelpers.updateLinkData(false, data);
                        $.spinner().stop();
                    } else {
                        toastHelpers.methods.show('danger', data.message, true);
                        $.spinner().stop();
                    }
                },
                error: function() {
                    $.spinner().stop();
                }
            });
        }
    }
};

core.methods.cardProductEventClickAddCart = cardProductEventClickAddCart;
core.methods.eventOverlayClickAddToCart = eventOverlayClickAddToCart;
core.methods.eventClickAddToCart = eventClickAddToCart;
core.methods.quickviewModalEventClickUpdate = quickviewModalEventClickUpdate;
core.methods.eventUpdateProducts = eventUpdateProducts;
core.methods.deleteProductModalEventShow = deleteProductModalEventShow;
core.methods.deleteProductModalEventClickDelete = deleteProductModalEventClickDelete;
core.methods.eventOverlayClickRemove = eventOverlayClickRemove;
core.methods.deleteProductsModalEventClickDelete = deleteProductsModalEventClickDelete;
core.methods.cardProductEventClickRemove = cardProductEventClickRemove;
core.handleWishlistUpdates = handleWishlistUpdates;

module.exports = core;
