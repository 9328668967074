'use strict';

var core = require('core/components/menu');

var headerUtils = require('core/utilities/headerUtils');
var SiteConstants = require('constants/SiteConstants');

function toggleDrawer(status) {
    var $header = $('header');
    var $headerNav = $header.find('.header-nav');
    var headerNavHeight = headerUtils.getHeaderHeight();
    var $mainMenu = $header.find('.main-menu');
    var $navbar = $mainMenu.find('.navbar-nav');
    var $modalBackground = $('.modal-background');
    var tcsMobileNavHeaderHeight = $('.header-mobile-topnav-container').length && !$('.header-mobile-topnav-container').is(":hidden") ? $('.header-mobile-topnav-container').outerHeight() : 0;
    headerNavHeight = headerNavHeight - tcsMobileNavHeaderHeight;

    if (status === 'open') {
        $('html, body').scrollTop(-1); // negative value needed for iOS to scroll all the way to the top
        $('body').addClass('mobile-menu-in');
        $mainMenu
            .addClass('in')
            .attr('aria-hidden', 'false')
            .css('height', 'calc(100% - ' + headerNavHeight + 'px)') // TCS update to fix AB issue
            .css('top', headerNavHeight)
            .siblings().attr('aria-hidden', 'true');
        $modalBackground
            .fadeIn(SiteConstants.TransitionSpeed)
            .css('top', headerNavHeight);
        $header
            .siblings().attr('aria-hidden', 'true');
        $navbar
            .find('.nav-link').first().focus();
        // added setTimeout to allow iOS time to finish scrolling before locking
        setTimeout(() => {
            $('html').addClass('lock-scroll');
        });
    } else {
        $('body').removeClass('mobile-menu-in');
        $navbar
            .find('.nav-menu, .top-category').detach();
        $navbar
            .find('.thirdCatOpen').removeClass('thirdCatOpen');
        $navbar
            .find('.show').removeClass('show');
        $mainMenu
            .removeClass('in')
            .removeClass('overflow-hidden')
            .attr('aria-hidden', 'true')
            .siblings().attr('aria-hidden', 'false');
        $header
            .siblings().attr('aria-hidden', 'false');
        $modalBackground
            .fadeOut(SiteConstants.TransitionSpeed);

        setTimeout(() => {
            $modalBackground.css('top', '');
            $('html').removeClass('lock-scroll');
            $headerNav.addClass('transparent-nav');
        }, SiteConstants.TransitionSpeed);
    }
};

function showMegamenuBackground($element) {
    var $modalBackground = $('.modal-background');
    var navHeight = ($('.main-menu').length && !window.isMobile()) ? $('.main-menu').outerHeight() : 0;
    var searchBarHeight = ($('header .search-mobile').length && window.isMobile()) ? $('header .search-mobile').outerHeight() : 0;
    var headerNavHeight = headerUtils.getHeaderHeight();
    var headerOnlyHeight = headerNavHeight - navHeight - searchBarHeight;

    $modalBackground
        .css('top', headerOnlyHeight)
        .fadeIn(SiteConstants.TransitionSpeed);

    if ($element.closest('.header').length > 0) {
        $modalBackground.css('z-index', $('.header-nav').css('z-index'));
    }
}

function hideMegamenuBackground() {
    var $modalBackground = $('.modal-background');

    if ($('body').hasClass('mobile-menu-in')) {
        $modalBackground.css('z-index', '');
    } else {
        $modalBackground.css('z-index', '');
        $modalBackground.fadeOut(SiteConstants.TransitionSpeed);
    }
}

function closeMegamenu($dropdown, hideBackground) {
    // Close current dropdown
    $dropdown.removeClass('show');
    if ($dropdown.children('.megamenu-container').length > 0) {
        $dropdown.children('.megamenu-container').children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
    } else {
        $dropdown.children('.dropdown-menu').removeClass('show').attr('aria-hidden', 'true');
    }

    $dropdown.children('.nav-link').attr('aria-expanded', 'false');
    if (hideBackground) {
        hideMegamenuBackground();
    }
}

function updateDropdownPosition($menuItem) {
    var $dropdown = $menuItem.next('.megamenu-container');
    $dropdown.removeClass('left-align-menu right-align-menu').css({ 'left' : '', 'right' : '', 'max-height' : ''});
    $dropdown.closest('.megamenu').removeClass('center-menu');

    // set max height on dropdown
    var viewportHeight = $(window).height();
    var headerNavHeight = headerUtils.getHeaderHeight();
    var maxDropdownHeight = viewportHeight - (headerNavHeight + SiteConstants.Spacer) + 'px';
    $dropdown.css('max-height', maxDropdownHeight);

    // get position of menu item
    var viewportWidth = $('.navbar-nav').innerWidth();
    var menuItemWidth = $menuItem.outerWidth();
    var navbarLeftOffset = $('.navbar').offset().left;
    var menuItemLeftOffset = $menuItem.offset().left - navbarLeftOffset;
    var menuItemRightOffset = viewportWidth - (menuItemLeftOffset + menuItemWidth);

    // get position of dropdown
    $dropdown.addClass('d-block');
    var dropdownWidth = $dropdown.outerWidth();
    var dropdownLeftOffset = $dropdown.offset() ? $dropdown.offset().left : 0;
    var dropdownRightOffset = viewportWidth - (dropdownLeftOffset + dropdownWidth);
    $dropdown.removeClass('d-block');

    // if 5 column menu center in viewport, otherwise align as needed for hoverability
    if ($dropdown.find('.menu-subcategories').children('.dropdown-item').length > 4) {
        $dropdown.closest('.megamenu').addClass('center-menu');
    } else {
        // left-align if the menu has a negative left offset
        if (dropdownLeftOffset < 100) {
            $dropdown.addClass('left-align-menu').css('left', -menuItemLeftOffset);
        }
        // right-align if the menu has a negative right offset
        if (dropdownRightOffset < 0) {
            $dropdown.addClass('right-align-menu').css('right', -menuItemRightOffset);
        }
    }
}

function dropdownMenu() {
    // Custom dropdown behaviors for top menu
    var $dropdownMenu = $('.custom-dropdown:not(.disabled) [data-toggle="dropdown"]');
    $dropdownMenu.on('click', event => {
        var isAccountSelector = $(event.target).closest(".account-contact-dropdown-target").length > 0;
        var isLevelThreeOrHigher = $(event.target).closest('.menu-level-2').length > 0;

        if (!isAccountSelector && !isLevelThreeOrHigher) {
            event.stopPropagation(); // Prevent Bootstrap dropdown click events
            if (window.isMobile()) {
                // Drawer behavior on mobile
                event.preventDefault();
                var $dropdownLink = $(event.target);
                if ($dropdownLink.hasClass('js-menu-nav-element')) {
                    $dropdownLink = $dropdownLink.closest('.nav-link');
                }
                var categoryId = $dropdownLink.attr('id');
                var $dropdown = $dropdownLink.closest('.custom-dropdown');
                var $li = $('<li class="dropdown-item top-category" role="button"></li>');
                var $closeMenu = $('<li class="nav-menu"></li>');
                var backButtonHtml = $dropdownLink.find('.js-menu-category-item').prop('outerHTML');
                $closeMenu.append($('.close-menu').first().clone());
                $closeMenu.find('.close-menu button').append(backButtonHtml);

                // TCS update - reference dropdowns by categoryId, since multiple can be in same li
                var $dropdownMenu;
                if (categoryId) {
                    $dropdownMenu = $dropdown.find('[aria-label="' + categoryId + '"]');
                } else {
                    $dropdownMenu = $dropdown.children('.dropdown-menu');
                }

                if ($dropdown.children('.megamenu-container').length > 0) {
                    $dropdown.children('.megamenu-container').children('.dropdown-menu')
                        .prepend($li)
                        .prepend($closeMenu)
                        .attr('aria-hidden', 'false')
                        .find('a').removeAttr('tabindex', '-1');
                } else {
                    $dropdown.children('.dropdown-menu')
                        .prepend($li)
                        .prepend($closeMenu)
                        .attr('aria-hidden', 'false')
                        .find('a').removeAttr('tabindex', '-1');
                }
                $dropdownMenu.nextAll('.dropdown-menu').hide(); // TCS update - hide the other dropdown menus
                $dropdown.addClass('show');
                $dropdown.parents('.dropdown-menu, .menu-toggleable-left').addClass('overflow-hidden');
                $dropdownLink.attr('aria-expanded', 'true');
                $dropdown.closest('.menu-group').find('.nav.navbar-nav > li.nav-item > a').attr('aria-hidden', 'true');
                $dropdownLink.closest('.main-menu').scrollTop(0);
                $dropdownLink.closest('.dropdown-menu').scrollTop(0);
            }
        }
    });

    // top-level header category menu items
    var $desktopDropdownMenu = $('.menu-level-1 > .custom-dropdown:not(.disabled) > [data-toggle="dropdown"]');

    // Desktop - open menu using hoverIntent to prevent unintentional opening
    $desktopDropdownMenu.hoverIntent({
        over: event => {
            if (!window.isMobile()) {
                var $eventElement = $(event.target);

                // Get parent category element
                if ($eventElement.hasClass('js-menu-nav-element')) {
                    $eventElement = $eventElement.closest('.nav-link');
                }

                // Close all dropdowns
                var $activeDropdowns = $('.megamenu.show');
                $activeDropdowns.each((_index, element) => {
                    closeMegamenu($(element), false);
                });

                // Open current dropdown
                var $megamenuContainer = $eventElement.siblings('.megamenu-container');
                var $dropdown = $megamenuContainer.length > 0 ? $megamenuContainer.children('.dropdown-menu') : $eventElement.siblings('.dropdown-menu');
                $eventElement.parent().addClass('show');
                $dropdown.addClass('show').attr('aria-hidden', 'false');
                $eventElement.attr('aria-expanded', 'true');

                // add css variable to reference in css for preventing horizontal scroll
                $('html').attr('style', '--scrollbar-width:' + (window.innerWidth - document.documentElement.clientWidth) + 'px');

                // set max height on dropdown
                var viewportHeight = $(window).height();
                var headerNavHeight = headerUtils.getHeaderHeight();
                var maxDropdownHeight = viewportHeight - (headerNavHeight + SiteConstants.Spacer) + 'px';
                $megamenuContainer.css('max-height', maxDropdownHeight);
                updateDropdownPosition($eventElement);
                showMegamenuBackground($eventElement);
            }
        },
        out: () => {
            // this 'out' handler is left empty, since it's triggered from parent, handled in mouseleave event below
        },
        interval: 50
    });

     // Desktop - close menu on mouseleave
     $desktopDropdownMenu.parent().on('mouseleave', event => {
        if (!window.isMobile()) {
            var $dropdown = $(event.currentTarget);
            var navbarHasFocus = $(event.relatedTarget).is('.menu-level-1');
            if (!navbarHasFocus && $(event.relatedTarget).closest('.megamenu').length === 0) {
                closeMegamenu($dropdown, true);
            }
        }
    });

    // Desktop - trigger close of megamenu when hovering from navbar spaces to anything but active megamenus
    $('.menu-level-1').on('mouseleave', event => {
        if (!window.isMobile()) {
            var $relatedTarget = $(event.relatedTarget);
            if ($relatedTarget.closest('.megamenu.show').length === 0) {
                // Close all other dropdowns
                var $activeDropdowns = $('.megamenu.show');
                $activeDropdowns.each((_index, element) => {
                    closeMegamenu($(element), true);
                });
            }
        }
    });

    // Desktop - trigger close of any open megamenus when hovering a non megamenu nav item
    $('.menu-level-1 > .nav-item:not(.megamenu)').on('mouseenter', () => {
        if (!window.isMobile()) {
            var $activeDropdowns = $('.megamenu.show');
            $activeDropdowns.each((_index, element) => {
                closeMegamenu($(element), true);
            });
        }
    });

    // Mobile - move account dropdown out of header to avoid z-index issues with sticky page elements
    $('.header-account-container .account-contact-dropdown-target').on('show.bs.dropdown', event => {
        if (window.isMobile()) {
            var $dropdownMenu = $(event.target).find('.dropdown-menu');
            $('body > .page').append($dropdownMenu).addClass('header-account-container');
        }
    });

    // custom header dropdowns that aren't in the main category nav menu
    var $headerNonNavDropdowns = $('.account-contact-dropdown-target .nav-link');
    $headerNonNavDropdowns.each((_index, element) => {
        var $headerNonNavDropdown = $(element);
        var customClass = $headerNonNavDropdown.closest('.dropdown').parent().attr('class');

        $headerNonNavDropdown.popover({
            content: function () {
                return $(this).siblings('.dropdown-menu').html();
            },
            trigger: 'manual',
            animation: false,
            placement: 'bottom',
            html: true,
            sanitize: false,
            template: `<div class="popover mt-0 ${customClass}" role="tooltip"><div class="popover-body dropdown-menu show"></div></div>`
        }).on('mouseenter', event => {
            if (!window.isMobile()) {
                var $target = $(event.target);
                $target.popover('show');
                $target.closest('.dropdown').addClass('show');
                showMegamenuBackground($target);
            }
        }).on('mouseleave', event => {
            if (!window.isMobile()) {
                var $target = $(event.target);
                var $relatedTarget = $(event.relatedTarget);

                // don't close if mouse moves into popover
                if ($relatedTarget.closest('.popover').length === 0) {
                    $target.popover('hide');
                    $target.closest('.dropdown').removeClass('show');
                    if ($relatedTarget.closest('.account-contact-dropdown-target').length === 0) {
                        hideMegamenuBackground();
                    }
                } else {
                    // bind event for closing popover on mouseleave
                    $relatedTarget.closest('.popover').on('mouseleave', event => {
                        // don't close if mouse moves back into popover link
                        if (!$(event.relatedTarget).is($target)) {
                            $target.popover('hide');
                            $target.closest('.dropdown').removeClass('show');
                            if ($(event.relatedTarget).closest('.account-contact-dropdown-target').length === 0) {
                                hideMegamenuBackground();
                            }
                        }
                    });
                }
            }
        }).on('click', event => {
            if (window.isMobile()) {
                var $target = $(event.target);
                var $navLink = $target.closest('.nav-link');

                if (!$navLink.closest('.dropdown').hasClass('show')) {
                    $navLink.popover('show');
                    $navLink.closest('.dropdown').addClass('show');
                    showMegamenuBackground($navLink);
                } else {
                    $navLink.popover('hide');
                    $navLink.closest('.dropdown').removeClass('show')
                    hideMegamenuBackground();
                }
            }
        });

        // close dropdowns if user scrolls to avoid wonky background overlay positioning
        $(window).on('scrollUpdate', event => {
            if ($headerNonNavDropdown.closest('.dropdown').hasClass('show')) {
                $headerNonNavDropdown.popover('hide');
                $headerNonNavDropdown.closest('.dropdown').removeClass('show')
                hideMegamenuBackground();
            }
        });

        // close mobile dropdowns if user clicks outside
        $(document).on('click', event => {
            if (window.isMobile()
                && $headerNonNavDropdown.closest('.dropdown').hasClass('show')
                && $(event.target).closest('.popover').length === 0
                && !$(event.target).closest('.nav-link').is($headerNonNavDropdown)) {
                    $headerNonNavDropdown.popover('hide');
                    $headerNonNavDropdown.closest('.dropdown').removeClass('show');
                    hideMegamenuBackground();
            }
        });
    });
};

function navbarBackBtn() {
    $('.navbar-nav').on('click', '.back', event => {
        event.preventDefault();
        core.clearSelection(event.target);
        $(event.target).parents('.dropdown-menu, .menu-toggleable-left').removeClass('overflow-hidden');
        $(event.target).parents('.dropdown-menu').nextAll('.dropdown-menu').show(); // TCS update to re-show sibling dropdowns
    });
};

function navbarToggler() {
    $('.navbar-toggler').click(event => {
        event.preventDefault();
        if ($('body').hasClass('mobile-menu-in')) {
            toggleDrawer('close');
            $('.header-nav').addClass('transparent-nav');
        } else {
            toggleDrawer('open');
            $('.header-nav').removeClass('transparent-nav');
        }
    });

    $('html').on('click', '.mobile-menu-in .modal-background', () => toggleDrawer('close'));
};

core.toggleDrawer = toggleDrawer;
core.dropdownMenu = dropdownMenu;
core.navbarToggler = navbarToggler;
core.mobileSearchFix = null;
core.navbarBackBtn = navbarBackBtn;

module.exports = core;
