'use strict'

$(document).ready(function () {
    if ($('#faqs').length) {
        var hash = window.location.hash;
        var SiteConstants = require('constants/SiteConstants');
        var headerHeight = $('.header-nav').outerHeight();
        var mobileNavTop = $("#mobile-faq-nav").closest("div").offset().top;
        var $mobileNav = $("#mobile-faq-nav").closest("div");
        var mobileNavHeight = $mobileNav.outerHeight();

        $('.sticky-left-column > .container > .row > div:first-child').css('top', headerHeight);

        // Handles links to anchor inside a dropdown when coming from another page
        if (hash.length) {
            var $element = $(".card-body").find(hash);
            if ($element) {
                if ($element.length > 0) {
                    navToHashInDropdown(hash);
                } else if ($element.length == 0) {
                    navToHashOnPage(hash);
                };
            };
        };

        // Click event to handle links that point to anchors on the page when found within faq page content
        $('.faq-content a, .footer-container a').click(function(event) {
            var navLinkHash = event.target.hash;
            var $element = $(".card-body").find(navLinkHash);
            if (navLinkHash.startsWith("#") && $element.length > 0) {
                event.preventDefault();
                navToHashInDropdown(navLinkHash);
            } else if (navLinkHash.startsWith("#") && $element.length == 0) {
                event.preventDefault();
                navToHashOnPage(navLinkHash);
            };
        });

        // New function to handle anchors in dropdowns upon page load and on click when on the page
        function navToHashInDropdown(hash) {
            var $toggle = $(hash);
            var $cardTitle = $toggle.closest('.card').find('.card-title');
            var accordionExpanded = $cardTitle.attr('aria-expanded');
            if (accordionExpanded === "false") {
                $cardTitle.trigger('click');
            }
            setTimeout(() => {
                var cardTitleHeight = $cardTitle.outerHeight();
                var hashOffset = $(hash).offset().top;
                var offset = hashOffset - headerHeight - mobileNavHeight - cardTitleHeight;
                if (cardTitleHeight && offset) {
                    window.scrollTo(0, offset);
                };
            }, SiteConstants.TransitionSpeed);
        };

        // Function to handle scrolling when anchors are found outside dropdown
        function navToHashOnPage(desktopNavHash) {
                var desktopNavHashOffset = $(desktopNavHash).offset().top;
                var desktopNavOffset = desktopNavHashOffset - headerHeight - mobileNavHeight;
                window.scrollTo(0, desktopNavOffset);
        }

        // Set mobile nav dropdown to fixed position when it hits header height scroll point
        $(window).scroll(function (event) {
            if ($(window).scrollTop() >= (mobileNavTop - headerHeight)) {
                $("#mobile-faq-nav").closest('div').addClass('mobile-sticky-panel');
                $('.mobile-sticky-panel').css('top', headerHeight);
                $("#faqs").find(".faq-secondary-nav").css("padding-top", mobileNavHeight + "px");
            } else {
                $("#mobile-faq-nav").closest('div').removeClass('mobile-sticky-panel');
                $("#faqs").find(".faq-secondary-nav").css("padding-top", "0");
            };
        });

        // Handle scroll when selecting links in the mobile dropdown nav to take into account the header height
        $('body').on('change', '#mobile-faq-nav', function () {
            var navHash = $(this).val();
            var navHashOffset = $(navHash).offset().top;
            var mobileNavOffset = navHashOffset - headerHeight - mobileNavHeight;
            window.scrollTo(0, mobileNavOffset);
        });

        // Handle scroll when selecting links in the desktop nav to take into account the header height
        $('.js-desktop-faq-nav a').click(function(event) {
            var $target = $(event.target).closest('a');
            var desktopNavHash = $target.attr('href');
            if (desktopNavHash.startsWith("#")) {
                event.preventDefault();
                navToHashOnPage(desktopNavHash);
            };
        });
    };
});