'use strict';

var core = require('core/wishlist/components/toast');
var wishlistHelpers = require('client_core/wishlist/components/helpers');

var dismiss = function($toast) {
    $toast
        .removeClass('show')
        .attr('tabindex', '-1');
    if ($toast.data().productId) {
        $('[data-wishlistpid="' + $toast.data().productId + '"]') // TCS update: added quotes to handle product IDs with slashes
            .first()
            .trigger('focus');
    }
    module.exports.methods.removeEventListenersWindow();
};

var eventClickUndo = function(e) {
    e.preventDefault();
    var $this = $(this);
    var $toast = $this.parents('.wishlist-toast');
    var url;
    var wishlistId;
    var wishlistQuantities;
    var productOptions;

    if (typeof $toast.data().wishlistId === 'string') {
        url = $this.data().urlList;
        wishlistId = $toast.data().wishlistId;
        wishlistQuantities = $toast.data().wishlistQuantities;
        productOptions = $toast.data().productOptions;
    } else {
        url = $this.data().urlLists;
        wishlistId = $toast.data().wishlistId.join('|');
        wishlistQuantities = $toast.data().wishlistQuantities.join('|');
        productOptions = $toast.data().productOptions;
    }

    $.ajax({
        url: url,
        method: 'POST',
        dataType: 'json',
        data: {
            pid: $toast.data().productId,
            wishlistId: wishlistId,
            wishlistQuantities: wishlistQuantities,
            productOptions: productOptions
        },
        success: function(data) {
            if (!data.error) {
                wishlistHelpers.updateUncachedData({
                    actionType: data.wishlistActionType,
                    pid: data.pid
                });
                wishlistHelpers.updateLinkData($('[data-wishlistpid="' + data.pid + '"]'), {'wishlistQuantities': wishlistQuantities});
                wishlistHelpers.openToast(data);

                // TCS Update: trigger event so wishlist items update when on wishlist page
                $('body').trigger('wishlist:update', { wishlistUpdateUrl: data.wishlistUpdateUrl });
            } else {
                toastHelpers.methods.show('danger', data.message, true);
            }
        }
    });
};

var addEventListenersToast = function() {
    $('.wishlist-toast')
        .off('click') // TCS update to prevent double events
        .on('click', module.exports.methods.eventStopPropagation)
        .on('click', '.undo', module.exports.methods.eventClickUndo)
        .on('click', '[data-toast-dismiss]', module.exports.methods.eventDismissButton)
        .on('show', module.exports.methods.eventShow)
        .on('dismiss', module.exports.methods.eventDismiss);
};

core.methods.dismiss = dismiss;
core.methods.eventClickUndo = eventClickUndo;
core.addEventListenersToast = addEventListenersToast;

module.exports = core;
