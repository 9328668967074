'use strict'

/**
 * Get cookie value by cookie name from browser
 * @param {string} cookieName - name of the cookie
 * @returns {string} cookie value of the found cookie name
 */
function getCookie(cookieName) {
    var name = cookieName + '=';
    var decodedCookie = decodeURIComponent(document.cookie);
    var cookieArray = decodedCookie.split(';');
    for (var i = 0; i < cookieArray.length; i++) {
        var cookieItem = cookieArray[i];
        while (cookieItem.charAt(0) === ' ') {
            cookieItem = cookieItem.substring(1);
        }
        if (cookieItem.indexOf(name) === 0) {
            return cookieItem.substring(name.length, cookieItem.length);
        }
    }
    return '';
}

function getCart(cartItems) {
    var result = [];

    for (var i = 0; i < cartItems.length ; i++) {
        var item = cartItems[i];
        result.push({
            productId: item.id,
            quantity: item.quantity,
            itemPrice: item.price.sales.value // per quantity price
        });
    }

    return result;
}

function getATCData(pliUUID, items) {
    var result = {};

    var addedPLI = items.filter(function (item) {
        return item.UUID === pliUUID;
    });

    if (addedPLI && addedPLI.length > 0) {
        var pli = addedPLI[0];
        result.productId = pli.id;
        result.value = pli.priceTotal.value; // Total value including all quantities
        result.currency = pli.price.sales.currency;
        result.quantity = pli.quantity;
    }

    return result;
}

/**
 * Events are divided up by name space so only the
 * events that are needed are initialized.
 */
var events = {
    all: function () {
        // Add to Cart event
        $('body').on('product:afterAddToCart', function (e, data) {
            var pliUUIDs = data.pliUUID;

            if (pliUUIDs && pliUUIDs.length > 0) {
                var cartPayload = getCart(data.cart.items);
                
                // Fire ATC event for each product added
                for (var i = 0; i < pliUUIDs.length; i++) {
                    var properties = getATCData(pliUUIDs[i], data.cart.items);
                    properties.dyType = "add-to-cart-v1"; // do not change
                    properties.cart = cartPayload;

                    DY.API("event", {
                        name: "Add to Cart",
                        properties: properties
                    });
                }
            }
        });

        // DY server-side ID Cookie
        const today = new Date();
        const oneYearDate = new Date(today);
        oneYearDate.setFullYear(today.getFullYear() + 1);
        const dyid_server = getCookie('_dyid_server');
        if (dyid_server && dyid_server !== "" && dyid_server !== "null" && dyid_server !== null) {
            document.cookie = `_dyid_server=${dyid_server};expires=${oneYearDate};path=/;Secure`;
        } else {
            const callback = (mutationsList) => {
                for (let mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        const cookieValue = getCookie('_dyid');
                        if (cookieValue) {
                            document.cookie = `_dyid_server=${cookieValue};expires=${oneYearDate};path=/;Secure`;
                            observer.disconnect();
                            break;
                        }
                    }
                }
            };

            const observer = new MutationObserver(callback);
            observer.observe(document.body, { childList: true, subtree: true });
        }

        // Consent tracking
        $('.js-ccpa-modal-confirm').on('click', function () {
            DYO.ActiveConsent.updateConsentAcceptedStatus(false); // false = output
        });
    }
}

/**
 * @function init
 * @description Initialize the DynamicYield functionality if window.DY exists
 * @param {String} nameSpace The current name space
 */
$(document).ready(function () {
    if (window.DY) {
        events.all();
    }
});
