'use strict';

var base = require('pagedesigner/pageDesigner');

var scrollAnimate = require('core/components/scrollAnimate');

/**
 * TCS CUSTOM: Runs the code that is found in the rvw_pagedesigner_core initBelowFoldRegion
 * function with some addtional custom TCS functionality for PowerReviews and PD hotspots
 * for tooltip:init. Also used for the TCS custom Chromeless below fold region
 */
function reinitRegionEvents($html) {
    var reinitFiles = require('core/utilities/ajaxHelpers');
    var processInclude = require('base/util');

    // Init pageLoad
    reinitFiles.methods.animateIfVisible($html.find('[data-animation]'), 'initialPageLoad');

    // init product tiles
    if ($html.find('.product-tile').length > 0) {
        processInclude(reinitFiles['productTile']);
    }

    // init sliders
    if ($html.find('.slider-container').length > 0) {
        var $sliders = $html.find('.slider-container');
        $sliders.each((_index, slider) => {
            var $slider = $(slider);
            if ($slider.closest('.experience-commerce_layouts-sliderEinstein').length > 0) {
                reinitFiles['einstein'].init($slider.parent());
            } else {
                reinitFiles['slider'].initializeSliders($slider.parent());
            }
        });
    }

    // init videos
    if ($html.find('.video-container').length > 0) {
        processInclude(reinitFiles['video']);
    }

    // PowerReviews UGC/"IRL" Galleries
    $('body').trigger('prGallery:init');

    // For PD hotspot component
    $('body').trigger('tooltip:init');
}

/**
 * TCS CUSTOM: Updated to use the above reinitRegionEvents function to add functionality for
 * Power Reviews and PD Hotspot tooltip:init
 * Should be kept updated the same as the AB Core function minus this change
 */
function initBelowFoldRegion() {
    // Render any components in the Below Fold region via ajax after page load
    const $belowFoldRegion = $('.pd-async-region');

    // Render any components in the Below Fold region via ajax after page load
    $belowFoldRegion.each(function (i, el) {
        const $el = $(el);
        const belowFoldObserver = new IntersectionObserver((entries, _observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    belowFoldObserver.unobserve(entry.target);

                    const url = $el.data('url');
                    const populateRegion = new Promise(resolve => {
                        var $xhr = $el.data('$xhr');
                        $xhr && $xhr.abort && $xhr.abort();

                        // Send to Page-Show, return HTML for ajaxRegion template
                        $el.data('$xhr', $.ajax({
                            url: url,
                            method: 'GET',
                            beforeSend: () => {
                                $el.addClass('loading');
                                $el.spinner().start();
                            },
                            success: response => {
                                var $html = $(response);
                                $el.replaceWith($html);
                                resolve($html);
                            },
                            error: err => {
                                console.error('Unable to render Below Fold region ', err);
                            },
                            complete: () => {
                                $el.removeClass('loading');
                                $el.spinner().stop();
                            }
                        }));
                    });

                    populateRegion.then(($html) => {
                        reinitRegionEvents($html);
                    });
                }
            });
        }, {});

        belowFoldObserver.observe(el);
    });
}

/**
 * TCS CUSTOM: Custom init for Below Fold region on Chromeless components
 * Should match the same functionality in the initBelowFoldRegion function minus the spinner() functionality
 */
function initBelowFoldRegionChromeless() {
    // Render any chromeless components in the Below Fold region via ajax after page load
    const $belowFoldRegionChromeless = $('.pd-async-region-chromeless');

    $belowFoldRegionChromeless.each(function (i, el) {
        const $el = $(el);
        const belowFoldChromelessObserver = new IntersectionObserver((entries, _observer) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    belowFoldChromelessObserver.unobserve(entry.target);

                    const url = $el.data('url');
                    const populateRegion = new Promise(resolve => {
                        var $xhr = $el.data('$xhr');
                        $xhr && $xhr.abort && $xhr.abort();

                        // Send to Page-Show, return HTML for ajaxRegion template
                        $el.data('$xhr', $.ajax({
                            url: url,
                            method: 'GET',
                            beforeSend: () => {
                                $el.addClass('loading');
                            },
                            success: response => {
                                var $html = $(response);
                                $el.replaceWith($html);
                                resolve($html);
                            },
                            error: err => {
                                console.error('Unable to render Below Fold region ', err);
                            },
                            complete: () => {
                                $el.removeClass('loading');
                            }
                        }));
                    });

                    populateRegion.then(($html) => {
                        reinitRegionEvents($html);
                    });
                }
            });
        }, {});

        belowFoldChromelessObserver.observe(el);
    });
}

function triggerBelowFoldAnchor(anchorID) {
    if (anchorID) {
        var $belowFoldRegion = $('#belowFoldRegion');

        if ($belowFoldRegion && $belowFoldRegion.attr('data-url') && $belowFoldRegion.children().length === 0) {
            $.ajax({
                url: $belowFoldRegion.attr('data-url'),
                method: 'GET',
                success: function (response) {
                    $belowFoldRegion.html(response);
                },
                error: function () {
                }
            }).then(function () {
                reinitRegionEvents($belowFoldRegion);
            }).then(function () {
                if ($(anchorID)) {
                    scrollAnimate($(anchorID));
                }
            });
        } else {
            // On pageload w/ hash in url
            if ($(anchorID)) {
                scrollAnimate($(anchorID));
            }
        }
    }
}

function initBelowFoldAnchorTrigger() {
    $('body').on('click', '.js-link-belowfold', function (e) {
        e.preventDefault();
        triggerBelowFoldAnchor($(this).attr('href'));
    });
    if ($(location).attr('hash')) {
        triggerBelowFoldAnchor($(location).attr('hash'));
    }
}

function updateCollapsibleButtonText() {
    $('body').on('click', '.js-pd-collapsible', function () {
        if ($(this).attr('aria-expanded') && $(this).attr('aria-expanded') === 'true') {
            // collapse
            $(this).find('.js-text-expand').addClass('d-none');
            $(this).find('.js-text-collapse').removeClass('d-none');
        } else {
            // expand
            $(this).find('.js-text-expand').removeClass('d-none');
            $(this).find('.js-text-collapse').addClass('d-none');
        }
    });
}

base.initBelowFoldRegion = initBelowFoldRegion;
base.initBelowFoldRegionChromeless = initBelowFoldRegionChromeless;
base.initBelowFoldAnchorTrigger = initBelowFoldAnchorTrigger;
base.updateCollapsibleButtonText = updateCollapsibleButtonText

module.exports = base;
