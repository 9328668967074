'use strict';
var core = require('core/components/clientSideValidation');

// enable character count functionality for any field that contains a 'js-character-count' element
core.characterCount = function() {
    var updateStatus = function($element, num, max) {
        var status = num + '/' + max;
        $element.text(status);

        if (num > max) {
            $element.parent().addClass('text--danger');
        } else {
            if ($element.parent().hasClass('text--danger')) {
                $element.parent().removeClass('text--danger');
            }
        }
    }

    $('.js-character-count').each((_index, element) => {
        var $element = $(element);
        var $input = $element.parent().siblings('input');
        if ($input.length) {
            var maxLength = parseInt($input.attr('maxlength'));
            if (maxLength) {
                // set initial value
                updateStatus($element, $input.val().length, maxLength);

                // bind event for updating on input change
                $input.on('blur input change', event => {
                    if (event.type === 'blur') {
                        // setTimeout to give googleAutoComplete time to finish populating
                        setTimeout(() => {
                            updateStatus($element, $input.val().length, maxLength);
                        }, 500);
                    } else {
                        updateStatus($element, $input.val().length, maxLength);
                    }

                });
            }
        }
    });
}

core.invalid = function () {
    $('form input, form textarea, form select').on('invalid', function (e) {
        e.preventDefault();
        this.setCustomValidity('');

        if (!this?.validity?.valid) {
            var validationMessage = this.validationMessage;
            var $this = $(this);

            $this.addClass('is-invalid');
            if (this.validity.patternMismatch && ($this.data('pattern-mismatch') || $this.data('range-error'))) {
                // TCS CUSTOM - Add in check for length mismatch on regex validation for Braintree form validation
                var inputValue = this.value;
                // default to 25 for max length if one is not set as it is the most common max length for TCS
                // just about all fields have the max length attribute though for address forms
                var maxLength = this.maxLength ? this.maxLength : 25;
                var minLength = this.minLength ? this.minLength : 0;
                if (inputValue.length > maxLength || minLength > inputValue.length) {
                    validationMessage = $this.data('range-error');
                } else {
                    validationMessage = $this.data('pattern-mismatch');
                }
            }
            if ((this.validity.rangeOverflow || this.validity.rangeUnderflow) &&
                $this.data('range-error')) {
                validationMessage = $this.data('range-error');
            }
            if ((this.validity.tooLong || this.validity.tooShort) &&
                $this.data('range-error')) {
                validationMessage = $this.data('range-error');
            }
            if (this.validity.valueMissing && $this.data('missing-error')) {
                validationMessage = $this.data('missing-error');
            }
            $this.parents('.form-group').find('.invalid-feedback')
                .text(validationMessage);
        }
    });
}

core.showPassword = function () {
    $('body').on('click', '.js-show-password', function(e) {
        var passwordInput = $(this).closest('.form-group').find('.js-password-input');
        if (passwordInput) {
            var $checkbox = $(this);
            passwordInput.each((index, element) => {
                $(element).attr('type', $checkbox.prop('checked') ? 'text' : 'password');
            });
        }
    });
}

module.exports = core;
