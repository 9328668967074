'use strict';

function getCookieValue(a) {
    var b = document.cookie.match('(^|;)\\s*' + a + '\\s*=\\s*([^;]+)');
    return b ? b.pop() : '';
}

function getExperienceType() {
    var deviceType = 'desktop';

    try {
        var httpUserAgent =  window.navigator.userAgent.toLowerCase();

        if (httpUserAgent.indexOf('iphone') > -1) {
            // iphone
            deviceType = 'mobile';
        } else if (httpUserAgent.indexOf('android') > -1) {
            // android
            if (httpUserAgent.indexOf('mobile') > -1) {
                deviceType = 'mobile';
            } else {
                deviceType = 'tablet';
            }
        } else if (httpUserAgent.indexOf('ipad') > -1 || (window.navigator.maxTouchPoints && window.navigator.maxTouchPoints > 0)) {
            // ipad
            deviceType = 'tablet';
        }
    } catch (e) {};

    return deviceType;
}

function fireAAEvent() {
    if (window.digitalData) {
        window._T.sendPageDataToAnalytics(window.digitalData);
    }
}

function initWindowDigitalData(adobeAnalytics) {
    window.digitalData = (window.digitalData ? window.digitalData : { event: [] });
    digitalData.site = JSON.parse(adobeAnalytics.impression);
    digitalData.site.experienceType = getExperienceType();
    digitalData.user = adobeAnalytics.user;

    if (document.cookie.indexOf('searchTermCookie') !== -1)
    {
        var searchTermEvent = {"category":{"primaryCategory":Resources.SEARCH_TERMS_PRIMARY_CATEGORY},"eventInfo":{"eventName":Resources.SEARCH_TERMS_EVENT_NAME, "eventMode":"stash"},"stash":{"searchMedium":Resources.SEARCH_TERMS_EVENT_NAME, "searchTerm":getCookieValue("searchTermCookie")},"searchTerm" : getCookieValue("searchTermCookie")};

        if (typeof window._T === 'object' && window._T !== null && typeof window._T.sendEventDataToAnalytics === 'function') {
            window._T.sendEventDataToAnalytics(searchTermEvent);
            //clear cookie
            document.cookie = 'searchTermCookie=; path=/; max-age=0';
        }
    }

    if (adobeAnalytics) {
        if ('page' in adobeAnalytics && adobeAnalytics.page) {
            digitalData.page = adobeAnalytics.page;
        }
        if ('product' in adobeAnalytics && adobeAnalytics.product) {
            digitalData.product = adobeAnalytics.product;
        }
        if ('cart' in adobeAnalytics && adobeAnalytics.cart) {
            digitalData.cart = adobeAnalytics.cart;
        }
        if ('event' in adobeAnalytics && adobeAnalytics.cart) {
            digitalData.event = adobeAnalytics.event;
        }
        if ('postOrder' in adobeAnalytics && adobeAnalytics.postOrder) {
            digitalData.postOrder = adobeAnalytics.postOrder;
        }
        if ('transaction' in adobeAnalytics && adobeAnalytics.transaction) {
            digitalData.transaction =  adobeAnalytics.transaction;
            digitalData.event = [];
        }
        if ('user' in adobeAnalytics && adobeAnalytics.user) {
            digitalData.user =  adobeAnalytics.user;
        }
    }

    window.digitalData = digitalData;
}

function initAdobeAnalytics() {
    if ($('#js-aadata').length) {
        try {
            var adobeAnalytics = JSON.parse($('#js-aadata').text());
            if (adobeAnalytics) {
                initWindowDigitalData(adobeAnalytics);

                if (adobeAnalytics.config) {
                    var aaScript = adobeAnalytics.config;
                    var script = document.createElement('script');
                    script.type = 'text/javascript';
                    script.setAttribute('src', aaScript);
                    script.setAttribute('async', '');
                    document.getElementsByTagName('head')[0].appendChild(script);

                    script.onload = () => {
                        aaloadFooterWhenReady(1);

                        // Delay for adobe analytics and visitor API scripts to finish initialization before attempting to use.
                        function aaloadFooterWhenReady(delayAttempts) {
                            return new Promise(oResolve => {
                                if (typeof window._T === 'object' && window._T !== null && typeof window._T.sendPageDataToAnalytics === 'function' && typeof window._T.sendEventDataToAnalytics === 'function') {
                                    fireAAEvent();
                                    oResolve('resolved');
                                } else if (delayAttempts > 0 && delayAttempts < 50) {
                                    oResolve(new Promise(iResolve => {
                                        setTimeout(function() {
                                            iResolve(aaloadFooterWhenReady(1 + delayAttempts));
                                        }, 500);
                                    }));
                                } else {
                                    console.warn('loadAdobeAnalyticsFooter Failed. Functions, sendPageDataToAnalytics or sendEventDataToAnalytics, are unavailable. Core Adobe Analytics script is taking too long to complete initialization after 50 polling attempts.');
                                    oResolve('failed');
                                }
                            });
                        }
                    }
                }
            }
        } catch (e) {}
    }
}

module.exports = {
    initAdobeAnalytics: initAdobeAnalytics,
    postPageErrorTracking: function () {
        $('body').on('product:afterAddToCart promotion:error checkout:updateCheckoutViewError', function(e, data) {
            try {
                if (data && 'error' in data && data.error) {
                    if ('adobeAnalytics' in data && data.adobeAnalytics) {
                        window.digitalData = (window.digitalData ? digitalData : { event: [] });
    
                        if ('page' in window.digitalData) {
                            window.digitalData.page.error = data.adobeAnalytics;
                        } else {
                            window.digitalData.page = {
                                error: data.adobeAnalytics
                            }
                        }
                        if (typeof window._T === 'object' && window._T !== null && typeof window._T.sendEventDataToAnalytics === 'function') {
                            window._T.sendPageDataToAnalytics(window.digitalData);
                            console.log('Adobe success: postPageErrorTracking');
                        }
                    }
                }
            } catch (e) {
                console.log(e);
            }
        });
    },
    postPageCustomEventTracking: function () {
        try {
            $('body').on('login:success product:afterAddToCart checkout:afterPlaceOrder', function(e, data) {
                if (data && 'thdAnalyticsEvent' in data && data.thdAnalyticsEvent) {
                    if (typeof window._T === 'object' && window._T !== null && typeof window._T.sendEventDataToAnalytics === 'function') {
                        console.log('Adobe success: postPageCustomEventTracking');
                        var thdAnalyticsEvent = data.thdAnalyticsEvent;
                        if (typeof thdAnalyticsEvent === 'string') {
                            thdAnalyticsEvent = JSON.parse(thdAnalyticsEvent);
                        }
                        window._T.sendEventDataToAnalytics(thdAnalyticsEvent);
                    }
                }
    
                // Place Order
                if (data && e.type === 'checkout:afterPlaceOrder' && 'adobeAnalyticsSuccessOrder' in data && data.adobeAnalyticsSuccessOrder) {
                    if (typeof window._T === 'object' && window._T !== null && typeof window._T.sendEventDataToAnalytics === 'function') {
                        console.log('Adobe success: postPageCustomEventTracking');
                        window._T.sendEventDataToAnalytics(JSON.parse(data.adobeAnalyticsSuccessOrder.orderSubmissionEventImpression));
                    }
    
                    if (typeof window._T === 'object' && window._T !== null && typeof window._T.sendPageDataToAnalytics === 'function') {
                        window.digitalData = (window.digitalData ? digitalData : { event: [] });
                        digitalData.cart.price.paymentMethod =  data.adobeAnalyticsSuccessOrder.orderSubmissionImpression;
                        window._T.sendPageDataToAnalytics(digitalData);
                    }
                }
    
                // Add product Success
                if (data && e.type === 'product:afterAddToCart' && 'adobeAnalytics' in data && data.adobeAnalytics) {
                    window.digitalData = (window.digitalData ? digitalData : { event: [] });
                    digitalData.cart = JSON.parse(data.adobeAnalytics);
                    if (typeof window._T === 'object' && window._T !== null && typeof window._T.sendPageDataToAnalytics === 'function') {
                        window._T.sendPageDataToAnalytics(digitalData);
                    }
                }
            });
        } catch (e) {
            console.log(e);
        }
    },
    postPageCustomEventsTracking: function () {
        $('body').on('login:register:success', function(e, data) {
            if (data && 'thdAnalyticsEvents' in data && data.thdAnalyticsEvents && data.thdAnalyticsEvents.length > 0) {
                if (typeof window._T === 'object' && window._T !== null && typeof window._T.sendEventDataToAnalytics === 'function') {
                    for (var i =0; i < data.thdAnalyticsEvents.length; i++) {
                        console.log('Adobe success: postPageCustomEventTracking');
                        window._T.sendEventDataToAnalytics(data.thdAnalyticsEvents[i]);
                    }
                }
            }
        });
    },
    cartClickCheckoutButton: function () {
        if ($('.checkout-btn').length > 0) {
            $('body').on('click', '.checkout-btn', function() {
                if ($(this).is(":not(:disabled)")) {
                    var adobeAnalytics = $(this).data('adobe');

                    if (adobeAnalytics) {
                        if (typeof window._T === 'object' && window._T !== null && typeof window._T.sendEventDataToAnalytics === 'function') {
                            console.log('Adobe success: cartClickCheckoutButton');
                            window._T.sendEventDataToAnalytics(adobeAnalytics);
                        }
                    }
                }
            });
        }
    }
};