'use strict';

var core = require('core/wishlist/components/helpers');
var abSlider = require('core/components/slider');

/**
 * update clicked links attributes and text
 * @param {Object} specificLinks - jQuery object representing selection of product list experience links
 */
var updateLinkData = function(specificLinks, data) {
    if (!window || !window.UncachedData || !window.UncachedData.wishlistPIDs) {
        return;
    }
    var wishlistLinks = specificLinks || $('[data-wishlistpid]');
    if (!wishlistLinks || !wishlistLinks.length) {
        return;
    }
    var wishlistPIDs = new Set(window.UncachedData.wishlistPIDs);
    wishlistLinks.each((_i, wishlistLink) => {
        var $wishlistLink = $(wishlistLink);
        var wishlistpid = $wishlistLink.attr('data-wishlistpid');
        if (wishlistpid) {
            var isInWishlist = (wishlistPIDs.has(wishlistpid));
            $wishlistLink.attr('data-inwishlist', isInWishlist);

            if (isInWishlist) {
                $wishlistLink.attr('title', $wishlistLink.attr('data-removemessage'));
                $wishlistLink
                    .attr('data-wishlist-action-type', 'add')
                    .data('wishlist-action-type', 'add');
                if ($wishlistLink.data('includetext') === true) {
                    $wishlistLink.find('span').html($wishlistLink.attr('data-removemessage'));
                }
            } else {
                $wishlistLink.attr('title', $wishlistLink.attr('data-addmessage'));
                $wishlistLink
                    .attr('data-wishlist-action-type', 'remove')
                    .data('wishlist-action-type', 'remove');
                if ($wishlistLink.data('includetext') === true) { // AB bugfix, changed 'includeicon' to 'includetext'
                    $wishlistLink.find('span').html($wishlistLink.attr('data-addmessage'));
                }
            }
            var icon = $wishlistLink.find('.wishlist-icon');
            if (icon && icon.length) {
                $(icon).toggleClass('selected', isInWishlist);
            }
            if (data && data.wishlistQuantities) {
                $wishlistLink
                    .attr('data-wishlistquantity', data.wishlistQuantities)
                    .data('wishlistquantity', data.wishlistQuantities);
            } else {
                $wishlistLink
                    .attr('data-wishlistquantity', 1)
                    .data('wishlistquantity', 1);
            }
        }
    });
};

/**
 * Remove a product from the cart and move it to the Wishlist
 * @param {Object} data - Response data
 * @param {html} $button - Wishlist "save for later" button
 */
function saveForLater(data, $button) {
    if (data && data.wishlistActionType === 'add') {
        $button.closest('.product-info')
            .find('.cart-delete-confirmation-btn')
            .attr('data-saveforlater', true)
            .click();
    }
}

/**
 * Remove detailed wishlist tile from a slider
 * @param {Object} data - Response data
 * @param {html} $button - Wishlist "save for later" button
 */
function removeWishlistTileFromSlider(removedPid) {
    if (removedPid) {
        if ($('.wishlist-slider .slider').length) {
            $('.wishlist-slider .slider').trigger('slider:destroy');
        }

        var originalProductTile = $('.wishlist-card-product[data-pid="'+ removedPid + '"]').length ? $('.wishlist-card-product[data-pid="'+ removedPid + '"]').parent() : null;
        if (originalProductTile && originalProductTile.length > 0) {
            for (var i = 0; i < originalProductTile.length; i++) {
                $(originalProductTile[i]).remove();
            }
            var hasRemainingWishlistItems = $('.wishlist-slider .slider-container .slider').children().length ? true : false;
    
            if (!hasRemainingWishlistItems) {
                $('.wishlist-slider').addClass('d-none');
            }
        }
        abSlider.initializeSliders($('.wishlist-slider'));
    }
}

core.updateLinkData = updateLinkData;
core.saveForLater = saveForLater;
core.removeWishlistTileFromSlider = removeWishlistTileFromSlider;

module.exports = core;
