'use strict'

/**
 * Init popover dropdowns
*/
function initPopoverDropdowns() {
    $('body').on('click', '.js-swatch-dropdown-button, .js-quantity-dropdown-button', event => {
        event.preventDefault();
    });
    
    $('.js-shopcollection-container [data-toggle="popover"]').popover(); // promos
    $(".js-swatch-dropdown-button").popover({
        content: getSwatchDropdownContent,
        sanitize: false,
        trigger: 'focus'
    });

    $(".js-quantity-dropdown-button").popover({
        content: getQuantityDropdownContent,
        sanitize: false,
        trigger: 'focus'
    });
}

function getQuantityDropdownContent() {
    return $(this).next('.quantity-dropdown-content-container').html();
}

function getSwatchDropdownContent() {
    return $(this).next('.swatch-dropdown-content-container').html();
}

/**
 * Init shop the collection content
*/
$(document).ready(function () {
    if ($('.js-shopcollection-container').length) {
        try {
            $.ajax({
                url: $('.js-shopcollection-container').data('url'),
                method: 'GET',
                dataType: 'json',
                success: function (data) {
                    if (data && 'renderedHTML' in data && data.renderedHTML) {
                        $('.js-shopcollection-container').html(data.renderedHTML);
                    }
                },
                error: function (err) {}
            }).then(function () {
                initPopoverDropdowns();
            });
        } catch (e) {};
    }
});

/**
 * Updates Shop the Collection component when a product is selected/unselected
*/
function updateShopCollection() {
    $('body').on('change', '.js-shop-collection-checkbox', function(e) {
        e.preventDefault();

        var productTile = $(this).closest('.shop-collection-tile');
        $('.js-shop-collection-no-items-errormsg, .js-shop-collection-attrs-errormsg').hide();

        // Show/hide tile footer content
        var productTileFooter = productTile.find('.shop-collection-tile-footer');
        if ($(this).is(':checked')) {
            var isAllAttrSelected = checkAllAttributesSelected(productTile);

            if (isAllAttrSelected) {
                productTileFooter.find('.js-product-total-price-container').removeClass('d-none');
                productTileFooter.find('.quantity').removeClass('d-none');
                productTileFooter.closest('.shop-collection-tile').addClass('selected');
                productTileFooter.closest('.shop-collection-products').addClass('has-selected');
            } else {
                // Not all attributes have been selected yet. Display error msg.
                this.checked = false;
                productTileFooter.closest('.shop-collection-tile').removeClass('selected');
                if (!$('.shop-collection-tile.selected').length) {
                    productTileFooter.closest('.shop-collection-products').removeClass('has-selected');
                }
                productTile.find('.js-shop-collection-attrs-errormsg').show();
                return;
            }
        } else {
            productTileFooter.find('.js-product-total-price-container').addClass('d-none');
            productTileFooter.find('.quantity').addClass('d-none');
            productTileFooter.closest('.shop-collection-tile').removeClass('selected');
            if (!$('.shop-collection-tile.selected').length) {
                productTileFooter.closest('.shop-collection-products').removeClass('has-selected');
            }
        }

        updateAddToBagFooter();
    });
}

/**
 * Checks if all product attributes have been selected for a product
*/
function checkAllAttributesSelected(productTile) {
    var attributes = productTile.find('.attribute');
    var isValid = true;

    for (var i = 0; i < attributes.length; i++) {
        if ($(attributes[i]).find('.selectpicker option:selected').text().indexOf('Select') > -1) {
            isValid = false;
            break;
        }
    }

    return isValid;
}

/**
 * Updates Shop the Collection footer area (add to bag button text/total cost)
*/
function updateAddToBagFooter() {
    if ($('.shop-collection-tile').length) {
        var selectedProducts = $('.shop-collection-tile .js-shop-collection-checkbox:checked');

        // Get totals
        var totalItemCount = 0;
        var totalPrice = 0;
        if (selectedProducts.length) {
            for (var i = 0; i < selectedProducts.length; i++) {
                var productTile = $(selectedProducts[i]).closest('.shop-collection-tile');
                var itemTotalPrice = productTile.find('.js-product-total-price').attr('data-pricevalue');
                totalItemCount += parseFloat(productTile.find('.quantity-select').val());

                if (itemTotalPrice) {
                    totalPrice += parseFloat(itemTotalPrice);
                }
            }
        }

        if (totalPrice) {
            totalPrice = '$' + totalPrice.toFixed(2); // TCS only supports US
        }

        // Update Add to Bag Button
        var addToCartButton = $('.js-shop-collection-add-to-cart');
        var buttonText = addToCartButton.attr('data-text-bag');
        if (totalItemCount) {
            if (totalItemCount === 1) {
                buttonText = addToCartButton.attr('data-text-item-bag').replace('[X]', totalItemCount);
            } else if (totalItemCount > 1) {
                buttonText = addToCartButton.attr('data-text-items-bag').replace('[X]', totalItemCount);
            }

            if (totalPrice) {
                buttonText += ' - ' + totalPrice;
            }
        }
        addToCartButton.text(buttonText);

        // Update grand total
        if (totalPrice && totalItemCount) {
            $('.js-shop-collection-totals-container').removeClass('d-none');
            $('.js-shop-collection-totals').text(totalPrice);
        } else {
            $('.js-shop-collection-totals').text('');
            $('.js-shop-collection-totals-container').addClass('d-none');
        }
    }
}

/**
 * Unchecks Shop the Collection selections
*/
function resetShopTheCollection() {
    var selectedProducts = $('.shop-collection-tile .js-shop-collection-checkbox:checked');

    if (selectedProducts.length) {
        for (var i = 0; i < selectedProducts.length; i++) {
            $(selectedProducts[i]).click();
        }
    }
}

module.exports = {
    methods: {
        updateAddToBagFooter: updateAddToBagFooter,
        resetShopTheCollection: resetShopTheCollection
    },
    updateShopCollection: updateShopCollection
};
