'use strict';

var toastHelpers = require('core/components/toast');
var scrollAnimate = require('core/components/scrollAnimate');
var recaptcha = require('core/components/recaptcha');

/**
 * Submits the Page Designer form
 */
 function submitPageDesignerForm() {
    $('body').on('submit', 'form.js-pd-form', function (e) {
        e.preventDefault();
        $.spinner().start();

        var $form = $(this);

        // check if recaptcha is enabled
        if (typeof grecaptcha !== 'undefined' && grecaptcha) {
            recaptcha.check(e, {
                url: $form.attr('action'),
                method: 'POST',
                data: $form.serialize(),
                success: function (data) {
                    if (!data.success) {
                        toastHelpers.methods.show('danger', data.errorMessage, true);
                    } else {
                        $form.remove();
                        $('.js-pd-form-confirmation').removeClass('d-none');
                        scrollAnimate($('.js-pd-form-confirmation'));
                    }
                    $.spinner().stop();
                },
                error: function (err) {
                    $.spinner().stop();
                    if (err.responseJSON && err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }
                }
            });
        }
        else {
            $.ajax({
                method: 'POST',
                url: $form.attr('action'),
                data: $form.serialize(),
                success: function (data) {
                    if (!data.success) {
                        toastHelpers.methods.show('danger', data.errorMessage, true);
                    } else {
                        $form.remove();
                        $('.js-pd-form-confirmation').removeClass('d-none');
                        scrollAnimate($('.js-pd-form-confirmation'));
                    }
                    $.spinner().stop();
                },
                error: function (err) {
                    $.spinner().stop();
                    if (err.responseJSON && err.responseJSON.redirectUrl) {
                        window.location.href = err.responseJSON.redirectUrl;
                    }
                }
            });
        }
    });
}

/**
 * Exclude emoji
 */
function excludeEmoji() {
    $(".js-pd-form input").on('input', function () {
        //Regex to remove emoji's from text area
        var stripEmojis = $(this).val().replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
            '').replace(/\s+/g, ' ').replace(/[\u200B-\u200D\uFEFF\uFE0F]/g, '');

        //Reset the text area with emoji-less string
        $(this).val(stripEmojis);
    });
};

module.exports = {
    submitPageDesignerForm: submitPageDesignerForm,
    excludeEmoji: excludeEmoji
};