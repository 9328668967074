'use strict';

var scrollAnimate = require('core/components/scrollAnimate');

function learnMoreModal() {
    $('body').on('click', '.js-activity-learn-more', function (e) {
        e.preventDefault();

        var $modal = $('#rewardActivityModal');
        var activityTitle = $(this).closest('.js-reward-activity-tile').find('.js-activity-title').text();
        $.spinner().start();
        $.ajax({
            url: $(this).data('url'),
            method: 'GET',
            dataType: 'json',
            success: function(data) {
                if ('htmlContent' in data && data.htmlContent) {
                    $modal.modal('show');
                    $modal.find('.modal-title').empty().text(activityTitle);
                    $modal.find('.modal-body').empty().html(data.htmlContent);
                }

                $.spinner().stop();
            },
            error: function() {
                $.spinner().stop();
            }
        });
    });
}

/**
 * Attempts to get the potential points for the cart on load once the third party zrl_mi object is
 * loaded and initialization is completed successfully
 */
function getZinreloPoints() {
    if (typeof zrl_mi !== 'undefined' && zrl_mi.init_success !== 'undefined' && zrl_mi.init_success) {
        if ($('#zrl-cart-product-data').length > 0 && $('.zrl-cart-points').length > 0) {
            zrl_mi.get_potential_points_success_handler = function() {
                $('.zrl-cart-points').text(potential_points);
            }
            var zrlData = $('#zrl-cart-product-data').val();
            zrl_mi.get_potential_points(JSON.parse(zrlData));
        }
    } else {
        setTimeout( function () {
            getZinreloPoints();
        }, 100);
    }
}

/**
 * Updates the product array after the cart has been updated in some way with the new data
 * and updates the potential reward points based on the zrl_mi.get_potential_points call
 */
function updateZinreloProductData(basketItems) {
    try {
        var zinreloProductData = [];
        for (var i = 0; i < basketItems.length; i++) {
            var item = basketItems[i];
            var productEntry = {
                "product_id": item.id,
                "price": item.proratedPrice,
                "quantity": item.quantity,
                "category": item.category || ''
            };
            zinreloProductData.push(productEntry);
        }
        var zrlProductDataValue = $('#zrl-cart-product-data').length && $('#zrl-cart-product-data').val() ? $('#zrl-cart-product-data').val() : null;
        if (zrlProductDataValue) {
            var zrlData = JSON.parse(zrlProductDataValue);
            if (JSON.stringify(zrlData) !== JSON.stringify(zinreloProductData)) {
                zrl_mi.get_potential_points(zinreloProductData);
                $('#zrl-cart-product-data').val(JSON.stringify(zinreloProductData));
            }
        }
    } catch (e) {
        console.log(e);
    }
}

/**
 * Events are divided up by name space so only the
 * events that are needed are initialized.
 */
var events = {
    cartshow: function () {
        // try to get the potential points for the page
        getZinreloPoints()

        // update the zinrelo potential points on cart:update or cart:thirdPartyUpdate or promotion:success being triggered
        $('body').on('cart:update cart:thirdPartyUpdate promotion:success', function (e, data, uuid) {
            var basketItems = typeof data.basket !== 'undefined' ? data.basket.items :
                typeof data.cartModel !== 'undefined' ? data.cartModel.items : data.items;
            if (basketItems && basketItems.length > 0) {
                updateZinreloProductData(basketItems);
            }
        });

        // base popover settings for the zinrelo rewards drop down
        var popOverSettings = {
            placement: 'bottom',
            container: '.zrl-rewards-container',
            html: true,
            sanitize: false,
            template: '<div class="popover zrl-popover-dropdown"><div class="popover-body"></div></div>',
            content: function() {
                return $('.zrl-dropdown-content-container').html();
            },
            trigger: 'click'
        };
        $('body').on('zrlInCartRedemptionRendered', function() {
            $('.js-zrl-dropdown-button').popover(popOverSettings);
        });

        $('.inCartRedemptionContainer').on('shown.bs.popover', '.js-zrl-dropdown-button', function() {
            $('body').addClass('zrl-rewards-open');
        });
        $('.inCartRedemptionContainer').on('hidden.bs.popover', '.js-zrl-dropdown-button', function(e) {
            $('body').removeClass('zrl-rewards-open');
        });

        $('html').on('click', '.zrl-rewards-open', function() {
            $('.js-zrl-dropdown-button').popover('hide');
        });

        // clicking the zinrelo reward button within the popover, updates text and select value
        $('.inCartRedemptionContainer').on('click', '.zrl-reward-button', function (e) {
            e.stopPropagation();
            $('.js-zrl-dropdown-button').text($(this).find('.zrl-reward-name').text());
            $('.zinreloRewardsDropdown').val($(this).data('zrl-reward-id')).change();
            $('.js-zrl-dropdown-button').popover('hide');
        });
    },
    rewardsdashboard: function () {
        learnMoreModal();

        // View Benefits
        $('body').on('click', '.status-benefits', function () {
            scrollAnimate($('#benefits'));
        });

        // highlight table column
        try {
            if ($('.js-rewardtable').length && $('.js-reward-user-tier').length) {
                var currentTier = $('.js-reward-user-tier').text();
                var tableHeadColumns = $('.js-rewardtable-headrow th');
                var columnIndex;

                // Get column index
                if (tableHeadColumns && tableHeadColumns.length > 0) {
                    for (var i = 0; i < tableHeadColumns.length; i++) {
                        var heading = $(tableHeadColumns[i]).find('h5');
                        if (heading && (heading.text() === currentTier)) {
                            columnIndex = i;
                            $(tableHeadColumns[i]).addClass('reward-highlight');
                            break;
                        }
                    }
                }

                // Highlight other cells in column
                if (columnIndex) {
                    var rows = $('.js-rewardtable tbody tr');
                    for (var i = 1; i < rows.length; i++) {
                        var row = $(rows[i]);
                        var columnCountIndex = columnIndex + 1;
                        var cell = row.find('td:nth-child('+ columnCountIndex + ')');
                        if (cell) {
                            cell.addClass('reward-highlight');
                            if (rows.length === (i+1)) {
                                cell.addClass('reward-highlight-bottom');
                            }
                        }
                    }
                }
            }
        } catch (e) {
            console.log(e.toString());
        }
    },
    rewardslanding: function () {
        learnMoreModal();
    },
    all: function () {
        // account header
        if ($('.js-header-rewards').length > 0) {
            var observer = new MutationObserver((mutationsList, observer) => {
                $('.js-header-rewards').removeClass('d-none');
                observer.disconnect();
            });

            observer.observe($('.js-header-rewards')[0], {
                childList: true,
                subtree: true
             });
        }
    }
}

/**
 * @function init
 */
$(document).ready(function () {
    events.all();

    if (pageAction && events[pageAction]) {
        events[pageAction]();
    }
});
