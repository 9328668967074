
'use strict';

var formErrors = require('core/checkout/formErrors');
var formValidation = require('base/components/formValidation');
var cart = require('core/cart/cart');
var productBase = require('./../product/base');
var recaptcha = require('core/components/recaptcha');

/**
 * shows giftcert toast message
 * @param {string} data - data returned from the server's ajax call
 */
function displayMessage(data, button) {
    $.spinner().stop();

    $('.minicart').trigger('count:update', data);
    var status = data.success ? 'alert-success' : 'alert-danger';

    if ($('.add-giftcertificate-messages').length === 0) {
        $('body').append('<div class="add-giftcertificate-messages toast-messages"></div>');
    }

    $('.add-giftcertificate-messages').append('<div class="alert text-center add-giftcertificate-alert text-center ' + status + '">' + (data.msg || 'There was an error') + '</div>');

    setTimeout(function () {
        $('.add-giftcertificate-messages').remove();
        if (button) {
            button.removeAttr('disabled');
        }
    }, 5000);
}

function giftCertificateFormSubmission() {
    $('body').on('click', 'form.GiftCertificateForm .btn-add-giftcertificate-tocart, form.GiftCertificateForm .btn-update-giftcertificate-incart', function (e) {
        e.preventDefault();

        $('#giftcertificate-message').empty().hide();

        var form = $(this).closest('form');
        var isCheckBalanceForm = form.hasClass('js-gc-balance-form');
        var isEdit = false;
        var button = form.find('.btn-add-giftcertificate-tocart');
        if (!button || !button.length) {
            button = form.find('.btn-update-giftcertificate-incart');
            isEdit = true;
        } else {
            $('body').trigger('product:beforeAddToCart', this);
        }
        $(form).spinner().start();

        var url = form.attr('action');
        var queryString = form.serialize() + '&format=ajax';
        if (isEdit) {
            queryString += '&uuid=' + button.data('uuid');
            var requestUuid = button.data('uuid');
        }
        button.attr('disabled', true);

        recaptcha.check(e, {
            url: url,
            method: 'POST',
            dataType: 'json',
            data: queryString,
            success: function (data) {
                if (data.success) {
                    if (!isCheckBalanceForm) {
                        if (isEdit) {
                            $('#editGiftCertificateLineItemModal').modal('hide');
                            cart.updateCartTotals(data.cartModel);
                            cart.updateApproachingDiscounts(data.cartModel.approachingDiscounts);
                            cart.updateProductDetails(data, requestUuid);
                            cart.updateAvailability(data.cartModel, data.newProductUuid);

                            if (data.uuidToBeDeleted) {
                                $('.uuid-' + data.uuidToBeDeleted).remove();
                            }
                            cart.validateBasket(data.cartModel);

                            $('body').trigger('cart:update', [data, data.newProductUuid]);
                        } else {
                            // Add new Gift Card product to cart
                            var addToBagData = {
                                pid: null,
                                pidsObj: null,
                                childProducts: null,
                                quantity: 1
                            }
                            productBase.methods.launchAddToBagModal(addToBagData); // TCS custom. Don't add before ajax call otherwise user won't see GC form errors
                            productBase.methods.handlePostCartAdd(data);
                            $('body').trigger('product:GTMaddToCart', {
                                'gtmData': data.gtmData
                            });
                            $('body').trigger('product:afterAddToCart', data);
                        }
                        form.trigger('reset');
                    }
                    formErrors.clearPreviousErrors(form);
                } else {
                    if (data.redirectUrl) {
                        window.location.href = data.redirectUrl;
                    } else {
                        formValidation(form, data);
                    }
                }

                $.spinner().stop();
                displayCheckBalanceResult(data, button);
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                displayMessage(err, button);
            }
        });
    })
};

function displayCheckBalanceResult(data, button) {
    button.removeAttr('disabled');

    if (data && 'success' in data && 'balance' in data) {
        var alertClass = data.success ? 'success' : 'danger';
        $('#giftcertificate-message').removeClass('alert-danger alert-success');
        $('#giftcertificate-message').text(data.balance);
        $('#giftcertificate-message').addClass('alert-' + alertClass);
        $('#giftcertificate-message').show();
    }
}

function updateGiftCertificateLineItem(data, uuid) {
    var lineItem;
    for (var i = 0; i < data.items.length; i++) {
        if (data.items[i].UUID === uuid) {
            lineItem = data.items[i];
            break;
        }
    }

    if (lineItem) {
        $('.dwfrm_giftCertificate_purchase_from-' + uuid + ' span').html(lineItem.senderName);
        $('.dwfrm_giftCertificate_purchase_from-' + uuid).data('value', lineItem.senderName);
        $('.dwfrm_giftCertificate_purchase_recipient-' + uuid + ' span').html(lineItem.recipientName);
        $('.dwfrm_giftCertificate_purchase_recipient-' + uuid).data('value', lineItem.recipientName);
        $('.dwfrm_giftCertificate_purchase_recipientEmail-' + uuid + ' span').html(lineItem.recipientEmail);
        $('.dwfrm_giftCertificate_purchase_recipientEmail-' + uuid).data('value', lineItem.recipientEmail);
        $('.dwfrm_giftCertificate_purchase_message-' + uuid + ' span').html(lineItem.message || '');
        $('.dwfrm_giftCertificate_purchase_message-' + uuid).attr('title', lineItem.message || '');
        $('.pricing.item-total-' + uuid).html(lineItem.priceTotal.price);
        $('.pricing.item-total-' + uuid).data('value', lineItem.priceTotal.priceValue + '');
    }
}

function checkBalanceFormSubmission() {
    $('body').on('submit', 'form.check-balance', function (e) {
        e.preventDefault();

        var form = $(this);
        form.spinner().start();

        var button = form.find('.js-checkbalancebutton');
        var url = form.attr('action');
        var queryString = form.serialize() + '&format=ajax';
        button.attr('disabled', true);

        //because the error is not an html5 error typically (balance is 0 or does not exist)
        //we must clear the previous error + message manually
        formErrors.clearPreviousErrors(form);
        recaptcha.check(e, {
            url: url,
            method: 'POST',
            dataType: 'json',
            data: queryString,
            success: function (data) {
                if (data.success) {
                    form.find('.balancemsg').html(data.balance);
                } else {
                    form.find('.balancemsg').html('');
                    formValidation(form, data);
                    if (data.msg) {
                        displayMessage(data, button);
                    }
                }

                $.spinner().stop();
                button.removeAttr('disabled');
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                displayMessage(err, button);
            }
        });
    });
}

/**
 * TCS CUSTOM: Updated to work with the TCS SVS integration
 */
function applyGiftCertificateToBasket () {
    $('body').on('click', 'form.GiftCertificateForm .js-applybalancebutton', function (e) {
        e.preventDefault();

        $('#giftcertificate-message').empty().hide();

        var button = $(this);
        button.attr('disabled', true);
        var container = button.parents('.giftcertificate-information');
        var checkoutForm = button.parents('form');

        checkoutForm.spinner().start();

        var url = button.data('action');

        //because the error is not an html5 error typically (balance is 0 or does not exist)
        //we must clear the previous error + message manually
        formErrors.clearPreviousErrors(checkoutForm);
        recaptcha.check(e, {
            url: url,
            method: 'POST',
            data: checkoutForm.serialize() + '&format=ajax',
            success: function (data) {
                if (data.success) {
                    displayMessage(data);
                    $('.js-giftcertificatepaymentinstruments').html(data.template);

                    // hide payment methods not CREDIT_CARD
                    $('.payment-options .nav-item').not('[data-method-id="CREDIT_CARD"], [data-method-id="GIFT_CARD"]').addClass('disabled');
                    $('.giftcard-paywith-message').removeClass('d-none');
                    $('.giftcard-paywith-fullbalance').removeClass('d-none');

                    $('body').trigger('checkout:updateCheckoutView', {order: data.order, customer: data.customer});
                    $('body').trigger('checkout:updateCheckoutViewPaymentInformation', { order: data.order });
                    checkoutForm.trigger('reset');
                    $('#collapsibleGiftCard').collapse('hide');
                } else {
                    if (data.redirectUrl) {
                        window.location.href = data.redirectUrl;
                    } else {
                        formValidation(checkoutForm, data);
                        if (data.msg) {
                            displayMessage(data, button);
                        }
                    }
                }

                $.spinner().stop();
                if (!data.success || (data.success && data.order.totals.grandTotalLessGiftCertificatePaymentInstrumentsValue > 0)) {
                    button.removeAttr('disabled');
                }
            },
            error: function (err) {
                if (err.responseJSON.redirectUrl) {
                    window.location.href = err.responseJSON.redirectUrl;
                }
                displayMessage(err, button);
            }
        });

    })
}

function removeGiftCertificatePaymentInstrument() {
    $('body').on('click', '.js-remove-giftcertificate-pi', function (e) {
        e.preventDefault();

        var button = $(this);
        button.attr('disabled', true);

        var container = button.parents('.giftcertificate-information');
        container.spinner().start();

        var url = button.data('action');
        var giftCertificateCode = button.data('giftcertificatecode');
        $.ajax({
            url: url,
            method: 'POST',
            dataType: 'json',
            data: '&giftCertificateCode=' + giftCertificateCode,
            success: function (data) {
                if (data.success) {
                    $('.js-giftcertificatepaymentinstruments').html(data.template);

                    $('body').trigger('checkout:updateCheckoutView', {order: data.order, customer: data.customer});
                    $('body').trigger('checkout:updateCheckoutViewPaymentInformation', { order: data.order });

                    if (data.order.totals.grandTotalLessGiftCertificatePaymentInstrumentsValue > 0) {
                        $('form.GiftCertificateForm .js-applybalancebutton').removeAttr('disabled');
                    }

                    if (!data.order.billing.payment.giftCertificatePaymentInstruments.length) {
                        // show all payment methods if there are no more gift cards
                        $('.payment-options .nav-item').not('[data-method-id="CREDIT_CARD"], [data-method-id="GIFT_CARD"]').removeClass('disabled');
                        // hide the pay with messaging
                        $('.giftcard-paywith-message').addClass('d-none');
                        $('.giftcard-paywith-fullbalance').addClass('d-none');
                    }
                }

                $.spinner().stop();
                displayMessage(data);
            },
            error: function (err) {
                displayMessage(err, button);
            }
        });
    });
}

// Check's if recipient email and confirm recipient email match
function confirmGiftEmail() {
    $('#confirmRecipientEmail').blur(function () {
        var recipientEmail = $('#recipientEmail').val();
        var confirmRecipientEmail = $(this).val();
        var emailMismatchErrorText = $('.js-email-match-text').text();
        var errorContainer = $(this).closest('.form-group').find('.invalid-feedback');

        if (recipientEmail !== confirmRecipientEmail) {
            errorContainer.text(emailMismatchErrorText).show();
            $(this).addClass('is-invalid');
        } else {
            errorContainer.text('').hide();
            $(this).removeClass('is-invalid');
        }
    });
}

// Counts down remaining characters available for use in the form. Character count doesn't include spaces, and doesn't allow the use of emoji's
function giftCharacterCounter() {
    $("#egift-count").text("250");
    $("#egift-message").on('input', function () {
        var giftMessageString = $(this).val();
        //Regex to remove emoji's from text area
        var stripEmojis = giftMessageString.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
            '').replace(/\s+/g, ' ').replace(/[\u200B-\u200D\uFEFF\uFE0F]/g, '');

        //Reset the text area with emoji-less string
        $(this).val(stripEmojis);

        //Handle character count & truncation
        var stringWithoutSpaces = ($(this).val()).replaceAll(" ", "");
        var countWithoutSpaces = (250 - stringWithoutSpaces.length);
        $("#egift-count").text(countWithoutSpaces < 0 ? '0' : countWithoutSpaces);
        if (countWithoutSpaces < 0) {
            var spaceCount = stripEmojis.split(" ").length;
            $(this).val(($(this).val()).substr(0, (250 + (spaceCount - 1))));
        }
    });
};

module.exports = {
    displayMessage: displayMessage,
    giftCertificateFormSubmission: giftCertificateFormSubmission,
    updateGiftCertificateLineItem: updateGiftCertificateLineItem,
    checkBalanceFormSubmission: checkBalanceFormSubmission,
    applyGiftCertificateToBasket: applyGiftCertificateToBasket,
    removeGiftCertificatePaymentInstrument: removeGiftCertificatePaymentInstrument,
    giftCharacterCounter: giftCharacterCounter,
    confirmGiftEmail: confirmGiftEmail,
    init: function () {
        giftCertificateFormSubmission();
        checkBalanceFormSubmission();
        applyGiftCertificateToBasket();
        removeGiftCertificatePaymentInstrument();
        giftCharacterCounter();
        confirmGiftEmail();
    }
};
