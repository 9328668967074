'use strict';

var productBase = require('core/product/base');
var SiteConstants = require('constants/SiteConstants');
var debounce = require('lodash/debounce');

/**
 * Init selections to default if no selection already made on modal show
 */
function setDefaultValues() {
    var monogram = $('.monogram-experience');
    var fontList = $(monogram).find('[data-font-list]');
    var styleList = $(monogram).find('[data-mono-styles]');
    var inputText = $(monogram).find('.monogram-text');
    var colorList = $(monogram).find('[data-mono-color-list]');


    // make sure all fonts are selected and showing the right name
    fontList.each(function() {
        var fontItem = $(this).find('.monogram-font-item');
        var isFontSelected = false;
        // make sure something is selected (always should be something)
        fontItem.each(function() {
            if($(this).hasClass('monogram-option-selected')) {
                isFontSelected = true
                // make sure that the font name is showing in the label
                var fontName = $(this).attr('data-monogram-fontname');
                $(this).closest('.monogram-fonts').find('.monogram-font-selected').text(fontName);
            }
        });
        // nothing selected so click on first font item to select that and trigger all the updates
        if(fontItem !== null && !isFontSelected) {
            $(fontItem[0]).find('button').click();
        }
    });

    // make sure all styles are selected and showing the right name
    styleList.each(function() {
        var styleItem = $(this).find('.monogram-style-item');
        var isStyleSelected = false;
        // make sure something is selected (always should be something)
        styleItem.each(function() {
            if($(this).hasClass('monogram-option-selected')) {
                isStyleSelected = true
                // make sure that the style name is showing in the label
                var styleName = $(this).attr('data-style');
                $(this).closest('.monogram-style').find('.monogram-style-selected').text(styleName);
            }
        });
        // nothing selected so click on first style item to select that and trigger all the updates
        if(styleItem !== null && !isStyleSelected) {
            $(styleItem[0]).find('button').click();
        }
    });

    // make sure all colors are selected and showing the right name
    colorList.each(function() {
        var colorItem = $(this).find('.monogram-color-item');
        var isColorSelected = false;
        // make sure something is selected (always should be something)
        colorItem.each(function() {
            if($(this).hasClass('monogram-option-selected')) {
                isColorSelected = true
                // make sure that the color name is showing in the label
                var colorName = $(this).find('span').attr('data-colorname');
                $(this).closest('.monogram-colors').find('.monogram-color-selected').text(colorName);
            }
        });
        // nothing selected so click on first color item to select that and trigger all the updates
        if(colorItem !== null && !isColorSelected) {
            $(colorItem[0]).find('button').click();
        }
    });

    // text updates for safety
    inputText.each(function(){
        var input = $(this).find('input');
        if ($(input).hasClass('is-invalid')) {
            // reset an invalid text input on modal show
            $(input).val('ABC');
            $(input).removeClass('is-invalid');
            $(this).find('.invalid-feedback').removeClass('d-block').text();
        }
        // set placeholder text if the input is empty
        if ($(input).val().length === 0) {
            $(input).val('ABC');
        }
    });
}

/**
 * Set the monogram options to be identical for each product
 */
function monogramSameAs() {
    $('body').on('change', '#monogram-same-as', function(e) {
        var checkbox = $('input[type="checkbox"]#monogram-same-as');
        // check for opposite as this fires before the prop updates
        if($(checkbox).is(':checked')) {
            // hide the monogram sections except for first
            $('.monogram-section-single').slice(1).children().not(":first-child").not('.monogram-section-count').addClass('d-none');
            // unhide the same as messaging on the sections except for first
            $('.monogram-section-single .monogram-section-count .monogram-same-as').slice(1).removeClass('d-none');

            // update the pulse image to be the first one
            getMonogramPulseImages();

            // update the monogram confirmation to be clones of the first one
            var monogram = $('.monogram-experience');
            updateMonogramItemDisplay(monogram);
        } else {
            // unhide the monogram sections
            $('.monogram-section-single').children().not(":first-child").removeClass('d-none');

            // hide the same as messaging on the sections
            $('.monogram-section-single .monogram-section-count .monogram-same-as').addClass('d-none');
        }
    })
}

/**
 * Open the Monogramming modal
 */
function showMonogramModal() {
    $('body').on('click', '.monogram-checkbox', function(e) {
        e.preventDefault();

        // get product quantity
        var $productContainer = productBase.methods.getProductContainer($(this));
        var qtyValue = $productContainer.find('.quantity-select').val();
        var monogramSectionCount = $('.monogram-experience').find('.monogram-section-single').length;
        if (parseInt(qtyValue) > 1 || (parseInt(qtyValue) !== monogramSectionCount)) {

            if (monogramSectionCount !== parseInt(qtyValue) && parseInt(qtyValue) > monogramSectionCount) {
                // add additional monogram sections to the modal
                var qtyDif = qtyValue - monogramSectionCount;
                cloneMonogramSection(qtyDif)
            }
            if (monogramSectionCount > parseInt(qtyValue)) {
                // remove any past the qtyvalue amount of monogram sections
                $('.monogram-section-single:gt(' + (parseInt(qtyValue) - 1) + ')').remove();

                updatePersonalizationCounts();
            }
        }
        // set the default options up
        setDefaultValues();

        // check if the sections are supposed to be the same
        if ($('input[type="checkbox"]#monogram-same-as').is(':checked')) {
            // make sure the first monogram section is visible
            $('.monogram-section-single:first').children().removeClass('d-none');
            // hide the monogram sections except for first
            $('.monogram-section-single').slice(1).children().not(":first-child").not('.monogram-section-count').addClass('d-none');
            // unhide the same as messaging on the sections except for first
            $('.monogram-section-single .monogram-section-count .monogram-same-as').slice(1).removeClass('d-none');
        }

        // reset the slider to the first slide
        var $slider = $('.monogram-experience').find('.slider-container');
        var tns = $slider.data('tns');
        if (tns) {
            tns.goTo(0);
        }

        // open modal
        $('#monogramModal').modal('show');
    });
}

function monogramModalLoaded() {
    $('body').on('modal:loaded', function(event, modal) {
        var $modal = $(modal);
        // only update if the modal is for the monogram
        if ($modal.hasClass('monogram-experience')) {
            updateMonogramImages();
            getMonogramPulseImages();
        }
    });
}

/**
 * Get allowed styles based on the font
 */
function getAllowedFontStyles(font) {
    var allowedStyles = [];
    // get the allowed styles from hidden inputs in the font
    var fontInputs = $(font).find('input');

    $(fontInputs).each(function() {
        if ($(this).data('monogram-availability')) {
            allowedStyles.push($(this).data('monogram-type'));
        }
     });

    return allowedStyles;
}

/**
 * Update all available options after updating font/style
 */
function updateAvailableOptions(selectedOption) {
    var selectedOption = $(selectedOption);

    // monogram section to update
    var monogramSection = $(selectedOption).closest('.monogram-section-single');

    // get the style section
    var selectedStyle = $(monogramSection).find('[data-mono-styles] .monogram-option-selected').data('style');

    var allowedStyles = getAllowedFontStyles(selectedOption);

    // on currently unallowed style
    if ($.inArray(selectedStyle, allowedStyles) === -1) {
        // reset the style to Initials (style update will handle text field)
        $(monogramSection).find('.monogram-style-item[data-style="initials"] button').click();

        // disable the unallowed style
        $(monogramSection).find('.monogram-style-item[data-style="' + selectedStyle + '"] button').addClass('disabled');
    } else if (allowedStyles.length === 1) {
        // only one allowed style, so disable the other (currently only 2 styles so this is safe to do)
        $(monogramSection).find('.monogram-style-item[data-style!="' + selectedStyle + '"] button').addClass('disabled');
    } else {
        // on an allowed style but more than one style is allowed
        // allow any disabled buttons (currently only 2 styles so this is safe to do)
        $(monogramSection).find('.monogram-style-item button.disabled').removeClass('disabled');
    }
}

/**
 * Font button clicks
 */
function monogramFontUpdate() {
    $('body').on('click', '.monogram-font-item button', function(event) {
        event.preventDefault();
        var selectedFont = event.currentTarget.closest('.monogram-font-item');
        var fontList = selectedFont.closest('[data-font-list]');

        // update the selected font styling
        $(fontList).find('.monogram-option-selected').removeClass('monogram-option-selected');
        $(selectedFont).addClass('monogram-option-selected');

        // update the font display text
        $(fontList).parent().find('.monogram-font-selected').text($(selectedFont).data('monogram-fontname'));

        // update the available options
        updateAvailableOptions(selectedFont);

        // update the text input data
        var monogram = selectedFont.closest('.monogram-section-single');
        monogramTextUpdate(monogram);

        // get updated pulse image
        var monogramSection = event.currentTarget.closest('.monogram-section-single');
        getMonogramPulseImages(monogramSection);
    });
}

/**
 * monogram style button clicks
 */
function monogramStyleUpdate() {
    $('body').on('click', '.monogram-style-item button', function(event) {
        event.preventDefault();

        var selectedStyle = event.currentTarget.closest('.monogram-style-item');
        var styleList = selectedStyle.closest('[data-mono-styles]');

        // update the selected style
        $(styleList).find('.monogram-option-selected').removeClass('monogram-option-selected');
        $(selectedStyle).addClass('monogram-option-selected');

        // update the selected style text
        $(styleList).parent().find('.monogram-style-selected').text($(selectedStyle).data('style'));

        // update the text input data
        var monogram = selectedStyle.closest('.monogram-section-single');
        monogramTextUpdate(monogram);

        // get updated pulse image
        var monogramSection = event.currentTarget.closest('.monogram-section-single');
        getMonogramPulseImages(monogramSection);
    });
}

/**
 * Update the color swatches on click
 * Color does not affect the font/style, only the images
 */
function monogramColorUpdate() {
    $('body').on('click', '.monogram-color-item button', function (event) {
        event.preventDefault();

        var selectedColor = event.currentTarget.closest('.monogram-color-item');
        var colorList = selectedColor.closest('[data-mono-color-list]');

        // update the selected style
        $(colorList).find('.monogram-option-selected').removeClass('monogram-option-selected');
        $(selectedColor).addClass('monogram-option-selected');

        // update the color display name
        var colorName = $(selectedColor).find('span').attr('data-colorname');
        $(selectedColor).closest('.monogram-colors').find('.monogram-color-selected').text(colorName);

        // get updated pulse image
        var monogramSection = event.currentTarget.closest('.monogram-section-single');
        getMonogramPulseImages(monogramSection);
    });
}

/**
 * Update the text input field after changing font/style
 */
function monogramTextUpdate(monogram) {
    // get the selected font for the monogram section
    var selectedFont = $(monogram).find('[data-font-list] .monogram-option-selected');

    // get the selected style
    var selectedStyle = $(monogram).find('[data-mono-styles] .monogram-option-selected').attr('data-style');

    // get the hidden input for that style
    var fontInfo = $(selectedFont).find('input[data-monogram-type="' + selectedStyle + '"]');

    // get the new input attributes from the font
    var inputMin = $(fontInfo).attr('data-monogram-min');
    var inputMax = $(fontInfo).attr('data-monogram-max');
    var inputRegex = $(fontInfo).attr('data-monogram-regex');
    var transform = $(fontInfo).attr('data-monogram-texttransform');
    var sequence = $(fontInfo).attr('data-monogram-displayseq');

    // update the label text length monogram-text-length
    $(monogram).find('.monogram-text-selected .monogram-text-length').text(inputMin + '-' + inputMax);

    // update the error message if visible
    var errorContainer = $(monogram).find('.monogram-text .invalid-feedback.d-block');
    if (errorContainer.length > 0) {
        var errorMessage = $(monogram).find('.monogram-text-selected').text();
        $(monogram).find('.invalid-feedback').text(errorMessage).addClass('d-block');
    }

    // update the text field attributes
    var textInput = $(monogram).find('input[name="monogram-text"]');
    textInput.attr('data-mon-char-min', inputMin).data('mon-char-min', inputMin);
    textInput.attr('data-mon-char-max', inputMax).data('mon-char-max', inputMax);
    textInput.attr('data-mon-font-regex', inputRegex).data('mon-font-regex', inputRegex);
    textInput.attr('data-mon-font-case', transform).data('mon-font-case', transform);
    textInput.attr('data-mon-seq', sequence).data('mon-seq', sequence);

    // check input for errors
    validateTextInput(textInput);
}

/**
 * Update text field on input updates
 */
function monogramTextInput () {
    var monoDelay = $('.monogram-experience').attr('data-monogram-delay');
    var getImages = debounce(getMonogramPulseImages, monoDelay);
    $('input[name="monogram-text"]').on("input", function(event) {
        // validate as user types
        var textInput = event.currentTarget;
        validateTextInput(textInput);

        // get updated pulse image with delay on debounce
        var monogramSection = event.currentTarget.closest('.monogram-section-single');
        getImages(monogramSection, event);
    });
}

/**
 * validates the text input against the checks
 */
function validateTextInput(textInput) {
    var errors = false;

    // check length
    var inputMin = $(textInput).attr('data-mon-char-min');
    var inputMax = $(textInput).attr('data-mon-char-max');

    if ($(textInput).val().length < inputMin || $(textInput).val().length > inputMax) {
        errors = true;
    }

    // check regex
    var reg = new RegExp($(textInput).attr('data-mon-font-regex'));
    if (!reg.test($(textInput).val())) {
        errors = true;
    }

    var monogram = $(textInput).closest('.monogram-experience');
    var textArea = $(textInput).closest('.monogram-text');
    // enable/disable apply cta based on errors
    if (errors) {
        // check if already disabled
        if (!$(textInput).hasClass('is-invalid')) {
            // disable apply cta
            $(monogram).find('.monogram-cart-add').addClass('disabled');

            // show error message
            var errorMessage = $(textArea).find('.monogram-text-selected').text();
            $(textArea).find('.invalid-feedback').text(errorMessage).addClass('d-block');
            $(textInput).addClass('is-invalid');
        }
    } else {
        // check if already disabled
        if ($(textInput).hasClass('is-invalid')) {
            // enable apply cta
            $(monogram).find('.monogram-cart-add').removeClass('disabled');

            // hide/clear error message
            $(textArea).find('.invalid-feedback').text('').removeClass('d-block');
            $(textInput).removeClass('is-invalid');
        }
    }
}

/**
 * Monogram Modal Apply button click
 */
function monogramApply() {
    $('body').on('click', '.monogram-cart-add', function(event) {
        event.preventDefault();
        var element = event.target;
        var monogram = element.closest('.monogram-experience');

        // update the monogram item display
        updateMonogramItemDisplay(monogram);

        // add the check mark to personalize and trigger the change event
        $('input[type="checkbox"]#monogram').attr('checked', true).prop('checked', true).change();

        // Hide Estimated Delivery
        $('.js-estimatedDelivery').addClass('d-none');
        $('.js-estimatedDelivery-monogramming').removeClass('d-none');

        // close the modal
        hideMonogramModal();
    });
}

/**
 * Remove click on the monogram item card
 */
function removeMonogramItem() {
    $('body').on('click', '.monogram-remove', function(event) {
        event.preventDefault();

        // monogram item to be removed
        var monogramItem = event.target.closest('.monogram-confirmation');

        // get product quantity
        var $productContainer = productBase.methods.getProductContainer($(this));
        var $quantitySelect = $productContainer.find('.quantity-select');
        var qtyValue = $quantitySelect.val();

        // Only one product item
        if (parseInt(qtyValue) === 1) {
            // hide the monogram-applied-items area
            $('.monogram-applied-items').addClass('d-none');
            // update the monogram-qty to 0
            $('.monogram-applied-items').attr('data-monogram-qty', 0).data('monogram-qty', 0);

            // remove the check mark to personalize
            $('input[type="checkbox"]#monogram').attr('checked', false).prop('checked', false).change();
        } else {
            // remove the monogram section in the modal that matches
            var monogramSections = $('.monogram-experience').find('.monogram-section-single');
            var monogramItemIndex = $(monogramItem).index();
            $(monogramSections)[monogramItemIndex].remove();

            // remove the monogram item that was clicked for removal
            $(monogramItem).remove();

            var newQtyValue = parseInt(qtyValue) - 1;
            // update the monogram qty to new quantity so the qty update does not remove more
            $('.monogram-applied-items').data('monogram-qty', newQtyValue).attr('data-monogram-qty', newQtyValue);

            // update the quantity-select to a lower amount
            $quantitySelect.find('option:selected', 'select').removeAttr('selected').prev('option').attr('selected', 'selected').change();
        }

        // Show Estimated Delivery if all monogramming is removed
        if ($('.monogram-applied-items').hasClass('d-none')) {
            $('.js-estimatedDelivery').removeClass('d-none');
            $('.js-estimatedDelivery-monogramming').addClass('d-none');
        }
    });
}

/**
 * Edit a monogram item
 */
function editMonogramItem() {
    $('body').on('click', '.monogram-edit', function (event) {
        event.preventDefault();

        // get the matching monogram section
        var monogramItem = event.target.closest('.monogram-confirmation');
        var monogramSections = $('.monogram-experience').find('.monogram-section-single');
        var monogramItemIndex = $(monogramItem).index();
        var monogramSection = $(monogramSections)[monogramItemIndex];

        // check if the sections are supposed to be the same
        if ($('input[type="checkbox"]#monogram-same-as').is(':checked')) {
            // make sure the first monogram section is visible
            $('.monogram-section-single:first').children().removeClass('d-none');
            // hide the monogram sections except for first
            $('.monogram-section-single').slice(1).children().not(":first-child").not('.monogram-section-count').addClass('d-none');
            // unhide the same as messaging on the sections except for first
            $('.monogram-section-single .monogram-section-count .monogram-same-as').slice(1).removeClass('d-none');
        } else {
            // set this section as being edited
            $(monogramSection).addClass('monogram-editing');
        }

        // open the modal
        $('#monogramModal').modal('show');
    });
}

/**
 * Clone the monogram sections in the modal
 */
function cloneMonogramSection(qtyDif) {
    // copy the first monogram section qty dif amount of times and add to bottom
    var monogramFirstSection = $('.monogram-experience').find('.monogram-section-single')[0];
    var monogramLastSection = $('.monogram-experience').find('.monogram-section-single:last');

    for (var i = 0; i < qtyDif; i++) {
        var copy = $(monogramFirstSection).clone(true);
        var idNumber = Date.now();
        $(copy).find('input[id^=monogram-text-abc]').parent().find('label').attr('for', 'monogram-text-abc-' + idNumber);
        $(copy).find('input[id^=monogram-text-abc]').attr('id', 'monogram-text-abc-' + idNumber);
        copy.insertAfter($(monogramLastSection));
    }

    // update the sections with the appropriate counts
    updatePersonalizationCounts();
}

/**
 * Updates to monogramming based on product quantity increasing
 */
function productQtyUpdate($productContainer) {
    // check if monogramming is applied
    if ($('input[type="checkbox"]#monogram').attr('checked')) {
        // compare new qty vs monogram quantity
        var qtyValue = $productContainer.find('.quantity-select').val();
        var monoQty = $('.monogram-applied-items').data('monogram-qty');

        // update the personalization counts
        updatePersonalizationCounts();

        if (parseInt(qtyValue) > parseInt(monoQty)) {
            // quantity increased, add new monogramming section and show modal

            // find qty dif
            var qtyDif = qtyValue - monoQty;

            // add the additional monogram sections on qty change
            cloneMonogramSection(qtyDif);

            /**
             * add the additional monogram confirmed items now
             * to prevent user clicking off of the modal and not having
             * all of the new monogramming items added to the list
             */
            updateMonogramItemDisplay($('.monogram-experience'));

            // check if the sections are supposed to be the same
            if ($('input[type="checkbox"]#monogram-same-as').is(':checked')) {
                // make sure the first monogram section is visible
                $('.monogram-section-single:first').children().removeClass('d-none');
                // hide the monogram sections except for first
                $('.monogram-section-single').slice(1).children().not(":first-child").not('.monogram-section-count').addClass('d-none');
                // unhide the same as messaging on the sections except for first
                $('.monogram-section-single .monogram-section-count .monogram-same-as').slice(1).removeClass('d-none');
            }

            // show modal
            $('#monogramModal').modal('show');

        } else if (parseInt(monoQty) > parseInt(qtyValue)) {
            // quantity decreased, remove monogramming section starting at the bottom

            // update monogram qty to the new product qty value
            $('.monogram-applied-items').data('monogram-qty', qtyValue).attr('data-monogram-qty', qtyValue);

            // remove any past the qtyvalue amount of monogram sections
            $('.monogram-section-single:gt(' + (parseInt(qtyValue) - 1) + ')').remove();

            // remove any past the qtyvalue amount of monogram sections
            $('.monogram-confirmation:gt(' + (parseInt(qtyValue) - 1) + ')').remove();

            // hide the same as checkbox and personalization counts if only one left
            if (!(qtyValue > 1)) {
                $('.monogram-same-checkbox-wrapper').addClass('d-none');
                $('.monogram-section-count').addClass('d-none');
            }
        }
    }
}

/**
 * updates the personalization counts on the monogram modal
 * shows/hides the same as checkbox
 */
function updatePersonalizationCounts() {
    // get the product qty
    var $productContainer = productBase.methods.getProductContainer($('.monogram-applied-items'));
    var qtyValue = $productContainer.find('.quantity-select').val();

    // update the monogram item count shown in the labels
    $('.monogram-section-single .monogram-section-count .current-section-length').text(qtyValue);

    // update the monogram item index in the labels
    var monogramSections = $('.monogram-experience').find('.monogram-section-single');
    $(monogramSections).each(function (i) {
        var index = i + 1;
        $(this).find('.monogram-section-count .current-section-index').text(index);
    })

    // show personalization counts and checkbox if more than 1
    if (qtyValue > 1) {
        $('.monogram-same-checkbox-wrapper').removeClass('d-none');
        $('.monogram-section-count').removeClass('d-none');
    }
}

function updateMonogramItemDisplay(monogram) {
    if (!monogram) return;

    // update the monogram-applied-items area

    // get the product qty
    var $productContainer = productBase.methods.getProductContainer($('.monogram-applied-items'));
    var qtyValue = $productContainer.find('.quantity-select').val();
    // get the current monogram qty
    var monoQty = $('.monogram-applied-items').data('monogram-qty');
    // find the qty dif
    var qtyDif = 0;
    // only need to worry about adding sections here, the qty update removes them
    if (parseInt(qtyValue) > 1) {
        qtyDif = qtyValue - monoQty;
    }

    // need new monogram-confirmation sections
    if (qtyDif > 0) {
        // check how many monogram confirmation sections there are
        var confirmationCount = $('.monogram-applied-items').find('.monogram-confirmation').length;
        var newDif = qtyDif;
        if (parseInt(qtyValue) > confirmationCount) {
            newDif = qtyValue - confirmationCount;
        }

        // copy the first monogram section qty dif amount of times and add to bottom
        var monogramFirstConfirmation = $('.monogram-applied-items').find('.monogram-confirmation')[0];
        var monogramLastConfirmation = $('.monogram-applied-items').find('.monogram-confirmation:last');

        for (var i = 0; i < newDif; i++) {
            $(monogramFirstConfirmation).clone().insertAfter($(monogramLastConfirmation));
        }
    }

    // update the monogram qty to match
    $('.monogram-applied-items').attr('data-monogram-qty', qtyValue).data('monogram-qty', qtyValue);

    var monogramSections = $(monogram).find('.monogram-section-single');
    var monogramItems = $('.monogram-applied-items').find('.monogram-confirmation');

    // check if the sections are supposed to be the same
    if ($('input[type="checkbox"]#monogram-same-as').is(':checked')) {
        // update all sections to be identical to the first one
        var firstSection = $(monogramSections)[0];
        $(monogramItems).each(function (i) {
            var singleConfirmation = $(this);
            // price
            var price = $(firstSection).find('.monogram-dynamic-price');
            price = $(price).data('monoprice');
            var confirmationPrice = $(singleConfirmation).find('[data-monogram-cost]');
            $(confirmationPrice).text(price);

            // font
            var font = $(firstSection).find('[data-font-list] .monogram-option-selected');
            var selectedFont = font;
            $(singleConfirmation).find('[data-font-confirm]').text($(selectedFont).data('monogram-fontname')).attr('data-confirm-font', $(selectedFont).data('monogram-netsuiteid'));

            // style
            var style = $(firstSection).find('[data-mono-styles] .monogram-option-selected');
            $(singleConfirmation).find('[data-type-confirm]').text($(style).data('style'));

            // text
            var textInput = $(firstSection).find('[data-mono-inputarea');
            var text = $(textInput).val();
            var transform = $(textInput).attr('data-mon-font-case');
            if (transform === 'uppercase') {
                text = text.toUpperCase();
            }
            $(singleConfirmation).find('[data-input-confirm]').text(text);

            // color
            var color = $(firstSection).find('[data-mono-color-list] .monogram-option-selected [data-colorname]');
            $(singleConfirmation).find('[data-color-confirm]').text($(color).data('colorname')).attr('data-confirm-color', $(color).data('colornetid'));

            // images
            var confirmationImages = $(singleConfirmation).find('.monogram-img-selection.img-clone');
            // delete the previous images out
            $(confirmationImages).find('.monogram-swatch').remove();
            $(confirmationImages).find('.monogram-text-overlay').remove();
            // swatch
            var swatchImage = $(firstSection).parent().find('.monogram-images .monogram-swatch').not('.monogram-preview')[0];
            $(swatchImage).clone().prependTo($(confirmationImages));
            // pulse api image
            var pulseImageWrapper = $(firstSection).parent().find('.monogram-images .monogram-text-overlay')[0];
            $(pulseImageWrapper).clone().prependTo($(confirmationImages));
            var updatedPulseUrl = $(firstSection).find('[data-monogram-pulse-image]').attr('data-monogram-pulse-image');
            $(confirmationImages).find('.monogram-text-overlay .monogram-image').attr('src', updatedPulseUrl);
        });
    } else {
        $(monogramSections).each(function (i) {
            var singleSection = $(this);
            var singleConfirmation = $(monogramItems)[i];
            /**
             * update the individual confirmation items based on their monogram section
             * since there should always be an equal amount of them at this point
             */

            // price
            var price = $(singleSection).parent().find('.monogram-dynamic-price');
            price = $(price).data('monoprice');
            var confirmationPrice = $(singleConfirmation).find('[data-monogram-cost]');
            $(confirmationPrice).text(price);

            // font
            var font = $(singleSection).find('[data-font-list] .monogram-option-selected');
            var selectedFont = font;
            $(singleConfirmation).find('[data-font-confirm]').text($(selectedFont).data('monogram-fontname')).attr('data-confirm-font', $(selectedFont).data('monogram-netsuiteid'));

            // style
            var style = $(singleSection).find('[data-mono-styles] .monogram-option-selected');
            $(singleConfirmation).find('[data-type-confirm]').text($(style).data('style'));

            // text
            var textInput = $(singleSection).find('[data-mono-inputarea');
            var text = $(textInput).val();
            var transform = $(textInput).attr('data-mon-font-case');
            if (transform === 'uppercase') {
                text = text.toUpperCase();
            }
            $(singleConfirmation).find('[data-input-confirm]').text(text);

            // color
            var color = $(singleSection).find('[data-mono-color-list] .monogram-option-selected [data-colorname]');
            $(singleConfirmation).find('[data-color-confirm]').text($(color).data('colorname')).attr('data-confirm-color', $(color).data('colornetid'));

            // images
            var confirmationImages = $(singleConfirmation).find('.monogram-img-selection.img-clone');
            // delete the previous images out
            $(confirmationImages).find('.monogram-swatch').remove();
            $(confirmationImages).find('.monogram-text-overlay').remove();
            // swatch
            var swatchImage = $(singleSection).parent().find('.monogram-images .monogram-swatch').not('.monogram-preview')[0];
            $(swatchImage).clone().prependTo($(confirmationImages));
            // pulse api image
            var pulseImageWrapper = $(singleSection).parent().find('.monogram-images .monogram-text-overlay')[0];
            $(pulseImageWrapper).clone().prependTo($(confirmationImages));
            var updatedPulseUrl = $(singleSection).find('[data-monogram-pulse-image]').attr('data-monogram-pulse-image');
            if (!(updatedPulseUrl.length > 0)) {
                // no image was generated for this section, set to match the default image
                updatedPulseUrl = $('.monogram-experience .monogram-images [data-monogram-default-image]').attr('data-monogram-default-image');
            }
            $(confirmationImages).find('.monogram-text-overlay .monogram-image').attr('src', updatedPulseUrl);
        });
    }

    // show the monogram-applied-items area
    $('.monogram-applied-items').removeClass('d-none');
}

function hideMonogramModal() {
    $('#monogramModal').removeClass('show');
    setTimeout(() => {
        $('#monogramModal').modal('hide');
    }, SiteConstants.TransitionSpeed / 2);
}

function monogramModalCloseEvent() {
    $('#monogramModal').click(function (event) {
        var $target = $(event.target);
        // custom behavior for hiding mobile on background or close button click
        if (($target.closest('.modal-dialog').length === 0 || $target.closest('.modal-header .close').length > 0)
            && !$('input[id^=monogram-text-abc]').is(':focus')) {
            event.preventDefault();
            hideMonogramModal();
        }
    });
}
/**
 * Fires when user changes a product attribute is selected or
 * quantity is changed, after the Product-Variation call is made
 */
function variationAttributeUpdates() {
    $('body').on('product:afterAttributeSelect', function (e, response) {
        var $productContainer = response.container;
        // only update when the main product is updated
        if ($productContainer.hasClass('js-main-product-detail') || $productContainer.hasClass('product-detail')) {
            // check if monogram modal exists
            if ($('.monogram-experience').length > 0) {
                var monogramImages = response.data.product.monogramImages;
                // update the monogram images (not Pulse API)
                updateMonogramImages(monogramImages);

                // update the quantity based stuff
                productQtyUpdate($productContainer);

                // update monogram price in cta
                var updatedMonogramPrice = response.data.product.monogramUpdatedProductPrice;
                $('.monogram-experience .monogram-dynamic-price').text(updatedMonogramPrice);
            }
        }
    });
}

/**
 * Fires when user changes a product attribute is selected or
 * quantity is changed, before the Product-Variation call is made
 */
function beforeVariationUpdates() {
    $('body').on('product:beforeAttributeSelect', function (e, response) {
        // check if monogram modal exists
        if ($('.monogram-experience').length > 0) {
            // make sure not MITC
            if (!(response.container.hasClass('shop-collection-tile'))) {
                // check if the trigger is something other than quantity or the monogram checkbox
                var eventTrigger = response.container.prevObject;
                if (!(eventTrigger.hasClass('quantity-select')) && !(eventTrigger.is('#monogram'))) {
                    // uncheck the monogram modal (do not trigger change to prevent loop)
                    $('input[type="checkbox"]#monogram').attr('checked', false).prop('checked', false);

                    // hide the monogram applied items area
                    $('.monogram-applied-items').addClass('d-none');
                }
            }
        }
    });
}

/**
 * Calls the Monogram end point to get the Pulse API images
 */
function getMonogramPulseImages(monogramSection) {
    var url = $('.monogram-experience .monogram-images').data('monoimages-url');
    var defaultImage = $('.monogram-experience .monogram-images [data-monogram-default-image]');

    // show the placeholder messaging
    $('.monogram-swatch-text').removeClass('d-none');

    // hide the other bits
    $('.monogram-experience .monogram-image').addClass('d-none');
    $('.monogram-swatch-text-error').addClass('d-none');

    var selectedSection;
    if (monogramSection) {
        // specific section was passed in
        selectedSection = monogramSection;
    } else {
        // check if editing a section
        var editSection = $('.monogram-experience .monogram-section-single.monogram-editing');
        if (editSection.length > 0) {
            selectedSection = editSection;
            editSection.removeClass('monogram-editing');
        } else {
            // section was not passed in, grab first section
            selectedSection = $('.monogram-experience .monogram-section-single')[0];
        }
    }

    // text values
    var textInput = $(selectedSection).find('.monogram-text input');
    var text = $(textInput).val();
    var transform = $(textInput).attr('data-mon-font-case');
    var sequence = $(textInput).attr('data-mon-seq');

    // color value
    var color = $(selectedSection).find('.monogram-color-item.monogram-option-selected span').attr('data-colorid');

    // font value
    var font = $(selectedSection).find('.monogram-font-item.monogram-option-selected').attr('data-mono-fontid');

    // gets the monogram options needed to pass in
    var options = {
        text: text,
        colorID: encodeURIComponent(color),
        fontID: encodeURIComponent(font),
        transform: transform,
        sequence: sequence
    };

    // do not call if there is an error on the input
    if (!($(textInput).hasClass('is-invalid'))) {
        $('.monogram-experience .monogram-swatch').spinner().start();
        // disable the CTA while images are updating
        $('.monogram-experience').find('.monogram-cart-add').addClass('disabled');
        $.ajax({
            url: url,
            method: 'GET',
            data: options,
            success: function (data) {
                // check if in error state
                if (data.error) {
                    // show error messaging
                    $('.monogram-experience .monogram-image').addClass('d-none');
                    $('.monogram-swatch-text').addClass('d-none');
                    $('.monogram-swatch-text-error').removeClass('d-none');
                } else {
                    // set up the image
                    var monogramImage = 'data:image/png;base64,' + data.monogramImage;
                    // add the image to the slider
                    $('.monogram-experience .monogram-image').attr('src', monogramImage).removeClass('d-none');

                    // add the image to the hidden section for use later
                    $(selectedSection).find('[data-monogram-pulse-image]').attr('data-monogram-pulse-image', monogramImage).data('monogram-pulse-image', monogramImage);

                    // set the default image, only happens once
                    if (defaultImage.attr('data-monogram-default-image').length === 0) {
                        defaultImage.attr('data-monogram-default-image', monogramImage).data('monogram-default-image', monogramImage);
                    }

                    // hide the messaging
                    $('.monogram-swatch-text').addClass('d-none');
                    $('.monogram-swatch-text-error').addClass('d-none');
                }
            },
            error: function (err) {
                // show error messaging
                $('.monogram-experience .monogram-image').addClass('d-none');
                $('.monogram-swatch-text').addClass('d-none');
                $('.monogram-swatch-text-error').removeClass('d-none');
            }
        }).always(function() {
            $('.monogram-experience .monogram-swatch').spinner().stop();
            if ($('input[id^=monogram-text-abc]').val()) {
                $('.monogram-experience').find('.monogram-cart-add').removeClass('disabled');
            }
        });
    } else {
        // show error messaging
        $('.monogram-experience .monogram-image').addClass('d-none');
        $('.monogram-swatch-text').addClass('d-none');
        $('.monogram-swatch-text-error').removeClass('d-none');
    }
}

/**
 * Updates the monogram images in the slider in the monogram modal
 * on attribute change
 * @param {object} monogramImages
 */
function updateMonogramImages(monogramImages) {
    var monoPreview;
    var monoSwatch;
    var swatchHidden = $('.monogram-experience input[data-monogram-swatch-image]');
    var previewHidden = $('.monogram-experience input[data-monogram-preview-image]');
    if (monogramImages) {
        monoPreview = monogramImages.monogramPreviewImages;
        monoSwatch = monogramImages.monogramSwatch;
        // set hidden input for use later
        swatchHidden.attr('data-monogram-swatch-image', monoSwatch[0].url).data('monogram-swatch-image', monoSwatch[0].url);
        swatchHidden.attr('data-monogram-swatch-alt', monoSwatch[0].alt).data('monogram-swatch-alt', monoSwatch[0].alt);
        previewHidden.attr('data-monogram-preview-image', monoPreview[0].url).data('monogram-preview-image', monoPreview[0].url);
        previewHidden.attr('data-monogram-preview-alt', monoPreview[0].alt).data('monogram-preview-alt', monoPreview[0].alt);
    } else {
        monoSwatch = [{
            'url': swatchHidden.attr('data-monogram-swatch-image'),
            'alt': swatchHidden.attr('data-monogram-swatch-alt')
        }];
        monoPreview = [{
            'url': previewHidden.attr('data-monogram-preview-image'),
            'alt': previewHidden.attr('data-monogram-preview-alt')
        }];
    }
    var monoSlideSwatch = $('.monogram-experience .monogram-swatch').not('.monogram-preview');
    var monoSlidePreview = $('.monogram-experience .monogram-swatch.monogram-preview');

    // confirm there are images
    if (monoSwatch[0].url.length > 0) {
        // loop over the monogram-preview images
        for (var i = 0; i < monoSlidePreview.length; i++) {
            var slide = monoSlidePreview[i];
            $(slide).attr('src', monoPreview[0].url).attr('alt', monoPreview[0].alt);
        }

        // loop over the monogram-swatch images
        for (var j = 0; j < monoSlideSwatch.length; j++) {
            var slide = monoSlideSwatch[j];
            $(slide).attr('src', monoSwatch[0].url).attr('alt', monoSwatch[0].alt);
        }
    }
}

/**
 * Updates to the add to cart on click event if monogramming is applied
 * Should only fire for the main product add to cart and not MITC
 */
function addMonogrammingCart() {
    $('body').on('updateAddToCartFormData', function (e, form) {
        // only fire on main product add to cart
        if ($(e.target).hasClass('add-to-cart')) {
            // check if the personalize checkbox is checked
            if ($('input[type="checkbox"]#monogram').is(':checked')) {
                // monogram item number
                var monogramProductID = $('.monogram-applied-items').attr('data-monogram-item-number');

                // pull the monogramming info from the confirmed items area into a json
                var monogramData = [];
                // loop over each monogram applied items list to get the info
                var monogramItems = $('.monogram-applied-items').find('.monogram-confirmation');
                $(monogramItems).each(function (i) {
                    var singleConfirmation = $(this);
                    var itemData = {
                        'fontID': $(singleConfirmation).find('[data-confirm-font]').attr('data-confirm-font'),
                        'colorID': $(singleConfirmation).find('[data-confirm-color]').attr('data-confirm-color'),
                        'type': $(singleConfirmation).find('[data-type-confirm]').text(),
                        'text': $(singleConfirmation).find('[data-input-confirm]').text()
                    };
                    monogramData.push(itemData);
                });

                // pass the json to the form to be sent to the backend
                form.monogramProductID = monogramProductID;
                form.monogramData = JSON.stringify(monogramData);
            }
        }
    });
}

/**
 * Functionality after monogram checkbox change
 */
function monogramCheckUpdate() {
    $('body').on('change', '#monogram', function (e) {
        var url = $('.monogram-applied-items').data('monogram-url');
        var $productContainer = productBase.methods.getProductContainer($(this));

        productBase.methods.attributeSelect(url, $productContainer);
    });
}

module.exports = {
    showMonogramModal: showMonogramModal,
    monogramModalLoaded: monogramModalLoaded,
    hideMonogramModal: hideMonogramModal,
    monogramModalCloseEvent: monogramModalCloseEvent,
    monogramFontUpdate: monogramFontUpdate,
    monogramStyleUpdate: monogramStyleUpdate,
    monogramColorUpdate: monogramColorUpdate,
    monogramTextInput: monogramTextInput,
    monogramApply: monogramApply,
    removeMonogramItem: removeMonogramItem,
    editMonogramItem: editMonogramItem,
    monogramSameAs: monogramSameAs,
    variationAttributeUpdates: variationAttributeUpdates,
    beforeVariationUpdates: beforeVariationUpdates,
    addMonogrammingCart: addMonogrammingCart,
    monogramCheckUpdate: monogramCheckUpdate
};
