'use strict';

var abSlider = require('core/components/slider');

var tinySlider = require('tiny-slider/src/tiny-slider');
var wishlistHelpers = require('client_core/wishlist/components/helpers');

/*
* Check if there are enough items to trigger slider indicators and add class to adjust space if not
*/
abSlider.handleInactiveSliders = function (params, $sliderContainer, tslider) {
    var info = tslider.getInfo();
    var mobileItems = params.items;
    var tabletItems = params.responsive[769].items;
    var desktopItems = params.responsive[1024].items;
    var actualItems = info.slideCount;

    if (actualItems <= mobileItems) {
        $sliderContainer.addClass('slider-bullets-hidden slider-buttons-hidden');
    }

    if (actualItems <= tabletItems) {
        $sliderContainer.addClass('slider-bullets-hidden-md slider-buttons-hidden-md');
    }

    if (actualItems <= desktopItems) {
        $sliderContainer.addClass('slider-bullets-hidden-lg slider-buttons-hidden-lg');
    }
}

abSlider.applyTinySlider = function ($container, params) {
    if (!$container) return;

    var tslider = tinySlider.tns(params);

    if (!!tslider) {
        tslider.events.on('transitionStart', abSlider.slideIntoView);

        abSlider.bindPauseButton($container, tslider);
        abSlider.bindSliderUpdateEvent($container, tslider);
        abSlider.bindSliderDestroyEvent($container, tslider);
        abSlider.enableImageZoom($container);
        abSlider.handleThumbnailArrows($container, tslider);
        abSlider.handleSliderVideos($container, tslider);
        abSlider.handleInactiveSliders(params, $container, tslider);

        $('body').trigger('slider:init.sliderEvents', [$container]);
        wishlistHelpers.updateLinkData();
    }

    $container.data('tns', tslider);
    $container.trigger('tooltip:init');
    $container.find('[data-controls="next"], [data-controls="prev"]').attr('tabindex', 0);
}

module.exports = abSlider;
