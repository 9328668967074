'use strict';

var scrollAnimate = require('core/components/scrollAnimate');

/**
 * Updates landing page reviews content for Reviews-Landing endpoint
*/
function updateReviewsContent() {
    $('body').on('click', '.js-reviews-pagelink', function(e) {
        e.preventDefault();

        $.spinner().start();

        $.ajax({
            url: $(this).attr('href'),
            type: 'get',
            dataType: 'json',
            success: function (data) {
                if (data && data.renderedHTML) {
                    $('.js-reviews-content').html(data.renderedHTML);
                    scrollAnimate($('.js-reviews-content'));
                }
                $.spinner().stop();
            },
            error: function (err) {
                console.log(err);
                $.spinner().stop();
            }
        });
    });
}

module.exports = {
    updateReviewsContent: updateReviewsContent
};
