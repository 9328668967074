'use strict'

var toastHelpers = require('core/components/toast');
var cart = require('./../cart/cart');

/**
 * Updates product estimated delivery date
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} data - estimated delivery call response data
 * @param {Boolean} isCart - Trigger is cart context
*/
function updateEstimatedDeliveryDate(container, data, isCart) {
    if (!data) return;

    if (container) {
        var estimatedDelivery = data;
        var cartModel = 'cart' in data ? data.cart : null;
        var estimatedDeliveryContainer = container.find('.js-estimatedDelivery-container');

        // Update Wishlist cards on Cart page
        if ($('.wishlist-slider').length && estimatedDelivery && estimatedDelivery.formattedArrivalDate) {
            $('body .js-estimatedDelivery-arrivaldate').text(estimatedDelivery.formattedArrivalDate);
        }

        if (estimatedDeliveryContainer.length) {
            // Clear current data
            estimatedDeliveryContainer.find('.js-estimatedDelivery-collapsiblebody').addClass('d-none');
            estimatedDeliveryContainer.find('.js-estimatedDelivery-label, .js-estimatedDelivery-shipdate, .js-estimatedDelivery-zipcode, .js-estimatedDelivery-arrivaldate, .js-estimatedDelivery-countdown').empty();

            // Add new data
            if (estimatedDelivery) {
                if (estimatedDelivery.postalCode) {
                    // update both cart page and quickview edit on cart
                    $('body .js-estimatedDelivery-zipcode').text(estimatedDelivery.postalCode);
                    $('body #zipCodeInput').val(estimatedDelivery.postalCode);
                }

                if (estimatedDelivery.formattedArrivalDate) {
                    $('body .js-estimatedDelivery-arrivaldate').text(estimatedDelivery.formattedArrivalDate); // update both cart page and quickview edit on cart
                }

                // Ensure cart page data updates even if quickview pip gets updated on cart
                if ($('.cart-page').length > 0 && cartModel) {
                    cart.updateFreeShippingMeter(cartModel);
                    cart.updateCartTotals(cartModel);
                    cart.updateLTLCartAjax(cartModel);
                    if (cartModel.hasShippingStateExclusion) {
                        $('.js-state-exclusion-messaging').show();
                    } else {
                        $('.js-state-exclusion-messaging').hide();
                    }
                }
                if (!isCart) {
                    if (estimatedDelivery.label) {
                        estimatedDeliveryContainer.find('.js-estimatedDelivery-label').text(estimatedDelivery.label);
                    }
                    if (estimatedDelivery.formattedShipDate) {
                        estimatedDeliveryContainer.find('.js-estimatedDelivery-shipdate').text(estimatedDelivery.formattedShipDate);
                    }
                    if (estimatedDelivery.countdownText) {
                        estimatedDeliveryContainer.find('.js-estimatedDelivery-countdown-label').text(estimatedDelivery.countdownText);
                    }

                    // Re-init countdown timer
                    estimatedDeliveryContainer.find('.js-estimatedDelivery-countdown').remove();
                    if (estimatedDelivery.cutOffDate) {
                        estimatedDeliveryContainer.find('.js-estimatedDelivery-countdown-container').append('<span class="js-estimatedDelivery-countdown" data-cutoffdate="" data-timeoffset=""></span>');
                        $('body').find('.js-estimatedDelivery-countdown').attr('data-cutoffdate', estimatedDelivery.cutOffDate).attr('data-timeoffset', estimatedDelivery.timezoneOffset || '');
                        resetCountdownTimer($('body').find('.js-estimatedDelivery-countdown'));
                    }
                }
            }

            // Show/hide sections
            var availabilityStatus = container.find('.js-availability-container').attr('data-status');
            var showHideSections = container.find('.js-estimatedDelivery-countdown-container, .js-estimatedDelivery-collapsible');
            if (!availabilityStatus || availabilityStatus === 'backorder' || availabilityStatus === 'preorder' || availabilityStatus === 'dropship' || availabilityStatus === 'madetoorder') {
                showHideSections.addClass('d-none');
            } else {
                showHideSections.removeClass('d-none');
            }
        }
    }
}

/**
 * Calls UPS-TimeInTransitAjax to retrieve the estimated delivery based on the submitted zip code
 */
function estimatedDeliveryAjax() {
    function invalidEstDelivery(form, errorMessage) {
        if (errorMessage) {
            toastHelpers.methods.show('danger', errorMessage, false);
        }
        form.find('.input-group').addClass('is-invalid');
        form.find('input[name="postalCode"]').focus();
    }

    $('body').on('submit', '.js-estimated-delivery-form', function (e) {
        e.preventDefault();

        var $form = $(this);
        $form.spinner().start();
        $form.find('.input-group').removeClass('is-invalid');

        // Validate zip code field
        var isValidZipcode = true;
        var POSTALCODE_PATTERN = new RegExp(/^\d+$/);
        var enterZipValue = $form.find('#zipCodeInput').val();
        if (!enterZipValue || enterZipValue && !POSTALCODE_PATTERN.test(enterZipValue) || enterZipValue && enterZipValue.length !== 5) {
            invalidEstDelivery($form, $form.find('#zipCodeInput').data('invalidmsg'));
            $form.spinner().stop();
            isValidZipcode = false;
            return;
        }

        // Container
        var isCart = $form.closest('.cart-page').length > 0 ? true : false;
        var $productContainer = isCart ? $form.closest('.cart-page') : $form.closest('.product-detail');
        if (!$productContainer.length) {
            $productContainer = $form.closest('.quick-view-dialog').find('.product-detail');
        }

        // Get updated UPS delivery info
        $.ajax({
            url: $form.attr('action'),
            method: 'GET',
            data: $form.serialize(),
            success: function (data) {
                if (data && data.success) {
                    updateEstimatedDeliveryDate($productContainer, data, isCart);

                    $productContainer.find('.js-estimatedDelivery-collapsible').removeClass('d-none');
                    $productContainer.find('.js-estimatedDelivery-collapsiblebody').addClass('d-none');
                } else {
                    invalidEstDelivery($form, data.errorMessage);
                }

                $form.spinner().stop();
            },
            error: function (e) {
                $form.spinner().stop();
            }
        });
    });
}

/**
 * Inits the countdown timer on pageload
*/
function initCountdownTimer() {
    var countdownContainers = $('.js-estimatedDelivery-countdown');

    if (countdownContainers && countdownContainers.length > 0) {
        for (var i=0; i < countdownContainers.length; i++) {
            var countdownTimer = $(countdownContainers[i]);
            if (countdownTimer.attr('data-cutoffdate') != '') {
                resetCountdownTimer(countdownTimer);
            }
        }
    }
}

/**
 * Resets the countdown timer
*/
function resetCountdownTimer(countdownContainer) {
    if (!countdownContainer) return;

    var deadline = countdownContainer && countdownContainer.attr('data-cutoffdate') ? countdownContainer.attr('data-cutoffdate') : null;

    if (deadline) {
        var deadlineDate = new Date(deadline);
        var offset = countdownContainer.attr('data-timeoffset');
        var x = setInterval(function() {
            var tmp = new Date();
            var now = new Date(tmp.getTime() + (offset * 3600 * 1000));
            var t = deadlineDate.getTime() - now.getTime();
            var days = Math.floor((t /(60 * 60 * 24) / 1000));
            var hours = Math.floor((t%(1000 * 60 * 60 * 24))/(1000 * 60 * 60));
            var minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
            var hoursString = hours.toString().padStart(2,'0');
            var minutesString = minutes.toString().padStart(2,'0');

            if (t < 0) {
                clearInterval(x);
                setTimeout(function(){
                    window.location.reload();
                }, 2 * 60 * 1000);
            } else if (days >= 0 && hours <= 23) {
                var countdownValue = hoursString + "h " + minutesString + "m ";

                countdownContainer.text(countdownValue);
            }
        }, 1000);
    }
}

/**
 * Shows the editable state of the zip code estimator
*/
function initEditZipCodeEstimator() {
    $('body').on('click', '.estimated-delivery-collapsible-btn', function () {
        var $container = $(this).closest('.js-estimatedDelivery-container');
        $container.find('.js-estimatedDelivery-collapsible').addClass('d-none');
        $container.find('.js-estimatedDelivery-collapsiblebody').removeClass('d-none');
        $container.find('#zipCodeInput').focus();

        // set value of field to zip showing in button
        var currentZip = $container.find('.js-estimatedDelivery-zipcode').text();
        if (currentZip !== '') {
            $('body #zipCodeInput').val(currentZip); // update both quickview edit and cart estimated delivery zip code input
        }
    });
}

module.exports = {
    updateEstimatedDeliveryDate: updateEstimatedDeliveryDate,
    estimatedDeliveryAjax: estimatedDeliveryAjax,
    initCountdownTimer: initCountdownTimer,
    resetCountdownTimer: resetCountdownTimer,
    initEditZipCodeEstimator: initEditZipCodeEstimator
};
