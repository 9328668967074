'use strict';

var core = require('core/wishlist/components/toggleProduct');
var toastHelpers = require('core/components/toast');
var modalShowLists = require('client_core/wishlist/components/modalShowLists');
var wishlistHelpers = require('client_core/wishlist/components/helpers');

// This function handles when a user clicks the favorite heart icon for their list
// For a logged in user it adds functionality to change the title of the modal
// If the logged in user has already added the given product to a list the title shows as "Remove from list"
// If the logged in user has not added the given product to a list the title shows as "Add to list"
// For a guest user it automatically adds or removes the given product from the list and displays toast msg
var eventClickHeart = function(event) {
    event.preventDefault();
    var $this = $(event.currentTarget);
    if (window.UncachedData.customer.isAuthenticated) {
        modalShowLists.methods.eventToggleProductOnWishlist($this);
    } else {
        module.exports.methods.toggleProductGuest($this);
    }
};

var toggleProductGuest = function($button) {
    var data = $button.data();
    var isCart = $('.cart-page').length ? true : false;
    $button.addClass('loading'); // TCS customization to enable animations on heart
    var inWishlist = $button.attr('data-inwishlist') && $button.attr('data-inwishlist') === 'true' ? true : false;

    $.ajax({
        url: data.url,
        method: 'POST',
        dataType: 'json',
        data: {
            pid: data.wishlistpid,
            quantity: data.wishlistquantity
        },
        success: function(data) {
            if (!data.error) {
                wishlistHelpers.updateUncachedData({
                    actionType: data.wishlistActionType,
                    pid: data.pid
                });
                wishlistHelpers.updateLinkData(false, data);
                data.guest = true;
                wishlistHelpers.openToast(data);
                $('body').trigger('wishlist:update', { wishlistUpdateUrl: data.wishlistUpdateUrl });

                // Add product tile to wishlist section
                if (isCart && 'productTileHTML' in data && !inWishlist) {
                    $('body').trigger('wishlist:addProductTileSlider', {
                        productTileHTML: data.productTileHTML,
                        container: $('.wishlist-slider')
                    });
                }

                // Remove from wishlist section
                if (isCart && inWishlist) {
                    wishlistHelpers.removeWishlistTileFromSlider(data.pid);
                }
            } else {
                toastHelpers.methods.show('danger', data.message, true);
            }
            $button.removeClass('loading');
        }
    }).then(function (data) {
        // Cart wishlist updates
        if (isCart) {
            // Move product from Cart to Wishlist
            wishlistHelpers.saveForLater(data, $button);
        }
    });
};

core.methods.eventClickHeart = eventClickHeart;
core.methods.toggleProductGuest = toggleProductGuest;

module.exports = core;
