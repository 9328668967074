'use strict';
if (window && !window.$) { // this check is for SG, in SG window.$ is already exist and throw error on reinitialize
    window.jQuery = window.$ = require('jquery');
}

(function () {
    if ($('.page[data-action="Order-Confirm"').length > 0) {
        try {
            /*
             Timeout is required for SG, since inside SG power review main JS file
             is included in footer and this template is included in order confirmation page
             so, this loads bit earlier than footer therefore needs to defer this event to wait
             to load footer script.
            */
            setTimeout(function () {
                var $analytics = $('.power-reviews-analytics');
                var config = window.POWERREVIEW;
                var tracker = POWERREVIEWS.tracker.createTracker({ merchantGroupId: config.merchant_group_id }); // eslint-disable-line
                tracker.trackCheckout($analytics.data('power-reviews-analytics'));
            }, 1000);
        } catch (e) { window.console.log(e); }
    }
}());
