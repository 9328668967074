'use strict';

var core = require('integrations/product/base');
var siteIntegrations = require('core/integrations/siteIntegrationsUtils');
var toggleObject = siteIntegrations.getIntegrationSettings();
var cloudinary = require('../thirdParty/cloudinary');
var estimatedDelivery = require('../components/estimatedDelivery');
var SiteConstants = require('constants/SiteConstants');
var scrollAnimate = require('core/components/scrollAnimate');
var shopTheCollection = require('../components/shopTheCollection');
var abSlider = require('client_core/components/slider');
var debounce = require('lodash/debounce');
var toastHelpers = require('core/components/toast');

function updateAttribute() {
    $('body').on('product:afterAttributeSelect', function (e, response) {
        var isQuickview = response.container.hasClass('product-quickview') || false;
        var isShopTheCollection = response.container.hasClass('shop-collection-tile') || false; // If the triggered attr is a Shop the Collection product
        var updateShopTheCollectionItems = !isShopTheCollection && 'collectionProducts' in response.data.product && response.data.product.collectionProducts && response.data.product.collectionProducts.length > 0 ? true : false; // Used for the main product to update Shop the Collection products
        var productName = isShopTheCollection ? response.data.product.productCollectionName : response.data.product.productName;

        // START: TCS Custom Updates
        $('body').trigger('tooltip:init');

        // Product Name
        response.container.find('.product-name').html(productName);

        // Update mobile section of product name
        if (response.container.hasClass('js-main-product-detail')) {
            response.container.closest('.product-detail').find('.js-mobile-product-details-top').find('.product-name').html(productName);
        }

        // Total Price
        updateTotalItemPrice(response.container, response.data.product);

        // Main Product trigger: Update Shop the Collection tile products for unselected products
        if (updateShopTheCollectionItems) {
            updateShopTheCollectionProducts(response);
        }

        shopTheCollection.methods.updateAddToBagFooter();

        if (isShopTheCollection) {
            // Shop the Collection tile updates
            updateShopTheCollectionProductTile(response.container, response.data.product);
        } else {
            // Color attribute name
            var colorAttributeContainer = response.container.find('.attributes').find('[data-attr="color"]');
            var selectedColor = colorAttributeContainer.length ? colorAttributeContainer.find('.swatch.selected').attr('data-displayvalue') : null;
            if (selectedColor) {
                colorAttributeContainer.find('.color-display-value').text(selectedColor);
            }

            // TCS Updates
            // Monogram action url
            response.container.find('.monogram-applied-items').data('monogram-url', response.data.product.monogramUrl).attr('data-monogram-url', response.data.product.monogramUrl);

            // add variation guide modal
            response.container.find('[id^="variationGuideModal"]').attr('data-product', response.data.product.id);

            // Fix for AB issue of not updating sizechart on variant change
            response.container.find('#sizeChartModal').attr('data-product', response.data.product.id);
            var sizeChartHref = response.container.find('.size-chart-launcher').attr('href');
            if (sizeChartHref) {
                var oldAssetId = sizeChartHref.split('cid=')[1];
                var newAssetId = response.data.product.sizeChartId;
                if (newAssetId && oldAssetId && newAssetId !== oldAssetId) {
                    sizeChartHref = sizeChartHref.replace(oldAssetId, newAssetId);
                    response.container.find('.size-chart-launcher').attr('href', sizeChartHref);
                }
            }
            updateBadges(response.container.closest('.product-detail'), response.data.product.badgesHTML);
            updateProductSpecs(response.container.closest('.product-detail'), response.data.product.productSpecsHTML);

            // Cloudinary custom start: update cloudinary PGW
            var cloudinaryData = response.data.cloudinary;
            if (cloudinaryData) {
                if (isQuickview) {
                    cloudinaryData.galleryWidget.options.container = cloudinaryData.galleryWidget.options.container.replace('#', '#qv-');
                    window.qvGallery.update(cloudinaryData.galleryWidget.options); // eslint-disable-line no-undef
                } else {
                    cloudinary.updateCloudinaryGalleryWidget(cloudinaryData);
                }
            }
            // Cloudinary custom end: update cloudinary PGW
        }
        // END: TCS Custom Updates

        //Quickview
        if ($('.modal.show .product-quickview>.bundle-items').length) {
            $('.modal.show').find(response.container).attr('data-pid', response.data.product.id);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);
        } else if ($('.set-items').length) {
            response.container.find('.product-id').text(response.data.product.id);
        } else if ($('.modal.show .product-quickview').length) {
            $('.modal.show .product-quickview, .modal.show .qv-product-edit-add').attr('data-pid', response.data.product.id);
            $('.modal.show .full-pdp-link').attr('href', response.data.product.selectedProductUrl);
            $('.modal.show').find(response.container).find('.product-id').text(response.data.product.id);

            // TCS Custom: reinit custom promo popovers to place above quickview modal
            var $modalPopovers = $(response.container).find('[data-toggle="popover"]');
            if ($modalPopovers.length) {
                $modalPopovers
                    .popover('dispose')
                    .popover({container: 'body'});
            }
        //Main PDP
        } else if ($('.product-detail>.bundle-items').length) {
            response.container.attr('data-pid', response.data.product.id);
            response.container.find('.product-id').text(response.data.product.id);
        } else if ($('.product-set-detail').eq(0)) {
            if (response.container.hasClass('js-main-product-detail')) {
                response.container.closest('.product-detail').attr('data-pid', response.data.product.id);
            } else {
                response.container.attr('data-pid', response.data.product.id);
            }
            response.container.find('.product-id').text(response.data.product.id);
            response.container.find('.add-to-cart').attr('data-pid', response.data.product.id);
            response.container.closest('.product-detail').nextAll('.product-overview-container').find('.product-id').text(response.data.product.id);
        } else {
            response.container.find('.add-to-cart').attr('data-pid', response.data.product.id);
            response.container.find('.product-id').text(response.data.product.id);
            $('.product-detail:not(".bundle-item")').attr('data-pid', response.data.product.id);
        }
    });
}

function sizeChart() {
    $('body').on('click', '.size-chart .size-chart-launcher', event => {
        event.preventDefault();
        var $element = $(event.target).hasClass('icon-size') ? $(event.target).closest('.size-chart-launcher') : $(event.target);
        var url = $element.attr('href');
        var productId = $element.closest('.product-quickview').length > 0
            ? $element.closest('.product-quickview').find('.product-id').html()
            : $element.closest('.main-product-detail').find('.product-id').html();
        var $sizeChartModal = $('#sizeChartModal.modal[data-product="' + productId + '"]'); // TCS update: scope to sizeChartModal
        var $modalBody = $sizeChartModal.find('.modal-body');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'html',
            success: function (data) {
                $modalBody.html(data);
            }
        });

        // if the sizechart is from a quickview append after all the modal-backdrops
        if ($element.parents('.product-quickview').length) {
            var $sizeChartContainer = $element.closest('.size-chart');

            $sizeChartModal.appendTo('body');
            $sizeChartModal.on('hide.bs.modal', event => {
                $sizeChartModal.appendTo($sizeChartContainer);
            });
        }
        $sizeChartModal.modal('show');
    });

    $('body').on('click', '#sizeChartModal .close', event =>  {
        $(event.target).closest('#sizeChartModal').modal('hide');
    });
}

// TCS Update: new function to handle show/hide of variation guide modals
function variationGuide() {
    $('body').on('click', '.size-chart .variation-guide-launcher', event => {
        event.preventDefault();
        var $element = $(event.target).hasClass('guide-icon') ? $(event.target).closest('.variation-guide-launcher') : $(event.target);
        var url = $element.attr('href');
        var attributeId = $element.data('attribute-id');
        var modalSelector = '#variationGuideModal' + attributeId;
        var productId = $element.closest('.main-product-detail').find('.product-id').html();
        var $variationGuideModal = $(modalSelector + '.modal[data-product=' + productId + ']').first();
        var $modalBody = $variationGuideModal.find('.modal-body');

        $.ajax({
            url: url,
            type: 'get',
            dataType: 'html',
            success: function (data) {
                $modalBody.html(data);
            }
        });

        // if the sizechart is from a quickview append after all the modal-backdrops
        if ($element.parents('.product-quickview').length) {
            var $variationGuideContainer = $element.closest('.size-chart');

            $variationGuideModal.appendTo('body');
            $variationGuideModal.on('hide.bs.modal', event => {
                $variationGuideModal.appendTo($variationGuideContainer);
            });
        }
        $variationGuideModal.modal('show');
    });

    $('body').on('click', '[id^="variationGuideModal"] .close', event =>  {
        $(event.target).closest('[id^="variationGuideModal"]').modal('hide');
    });
}

/**
 * Updates all badge displayed
*/
function updateBadges(container, badgesHTML) {
    if (badgesHTML) {
        $.each(badgesHTML, function(key, badgeHTML){
            var $badgeContainer = container.find($('.js-product-' + key));

            if ($badgeContainer && $badgeContainer.length) {
                $badgeContainer.html(badgeHTML);
            }
        });
    }
}

/**
 * Updates product specs section
*/
function updateProductSpecs(container, productSpecsHTML) {
    if (productSpecsHTML) {
        var $overviewContainer = container.siblings('.product-overview-container');
        var $productSpecsContainer = $overviewContainer && $overviewContainer.length > 0 ? $overviewContainer.find('.js-description-and-detail') : null;

        if ($productSpecsContainer) {
            $productSpecsContainer.html(productSpecsHTML);
        }
    }
}

/**
 * Updates the Shop the Collection product tile based on the ajax response from the main product
*/
function updateShopTheCollectionProducts(response) {
    var collectionProducts = response.data.product.collectionProducts;
    var existingSetTiles = $('.shop-collection-tile');

    if (existingSetTiles.length) {
        for (var i = 0; i < collectionProducts.length; i++) {
            var product = collectionProducts[i];
            var $productContainer = $(existingSetTiles[i]);
            var isProductSelected = $productContainer.find('.js-shop-collection-checkbox').is(':checked'); // Do not update selected product tiles

            if (!isProductSelected) {
                var variantResponseContext = {
                    product: product
                }
                core.methods.handleVariantResponse(variantResponseContext, $productContainer);
                core.methods.updateOptions(product.optionsHtml, $productContainer);
                core.methods.updateQuantities(product.quantities, $productContainer);
                $productContainer.find('.product-name').html(product.productCollectionName);
                updateTotalItemPrice($productContainer, product);
                updateShopTheCollectionProductTile($productContainer, product);
            }

            if ($productContainer.find('[data-toggle="popover"]').length) {
                $productContainer.find('[data-toggle="popover"]').popover();
            }
        }
    }
}

/**
 * Updates the Shop the Collection product tile based on the ajax response on the tile selection (not main PDP)
*/
function updateShopTheCollectionProductTile($productContainer, product) {
    $productContainer.attr('data-gtmdata', JSON.stringify(product.gtmData));
    $productContainer.find('.product-tile-image-link, .product-tile-name-link').attr('href', product.selectedProductUrl);
    $productContainer.attr('data-pid', product.id);

    if (product.images && product.images.cloudinaryProductImage && product.images.cloudinaryProductImage.url) {
        $productContainer.find('.shop-collection-tile-image').attr('src', product.images.cloudinaryProductImage.url)
    }

    $productContainer.find('.shop-collection-tile-image')
        .attr('alt', product.productCollectionName)
        .attr('title', product.productCollectionName);

    if ($productContainer.find('[data-toggle="popover"]').length) {
        $productContainer.find('[data-toggle="popover"]').popover();
    }
}

/**
 * Updates the availability status in the Product Detail Page
 *
 * @param {Object} response - Ajax response object after an
 *                            attribute value has been [de]selected
 * @param {jQuery} $productContainer - DOM element for a given product
 */
function updateAvailabilityProcess(response, $productContainer) {
    var isShopTheCollection = $productContainer.hasClass('shop-collection-tile') || false;
    var availabilityValue = '';
    var availabilityMessages = response.product.availability.messages;
    if (!response.product.readyToOrder) {
        availabilityValue = '<li><div>' + response.resources.info_selectforstock + '</div></li>';
    } else {
        availabilityMessages.forEach(function (message) {
            // TCS CUSTOM: Add in the Dropship or Made to Order Timing message, span should match what is in availability.isml
            var availabilityTimingMessage = response.product.availability && 'dropShipMadetoOrderTiming' in response.product.availability ? response.product.availability.dropShipMadetoOrderTiming : '';
            if (availabilityTimingMessage) {
                if (isShopTheCollection) {
                    availabilityValue += '<li><div>' + message + '<span class="d-block availability-timing">' + availabilityTimingMessage + '</span></div></li>';
                } else {
                    availabilityValue += '<li><div>' + message + ': <span class="availability-timing">' + availabilityTimingMessage + '</span></div></li>';
                }
            } else {
                availabilityValue += '<li><div>' + message + '</div></li>';
            }
        });
    }

    var additionalMessaging = response.product.availability && 'additionalMessaging' in response.product.availability ? response.product.availability.additionalMessaging : '';
    if (isShopTheCollection) {
        $productContainer.find('.js-availability-container').attr('data-status', response.product.availability.status);

        // Tooltip
        var shopCollectionTooltip = $productContainer.find('.js-shop-collection-availability-tooltip');
        if (additionalMessaging && additionalMessaging.length) {
            shopCollectionTooltip.attr('data-original-title', additionalMessaging).show();
        } else {
            shopCollectionTooltip.attr('data-original-title', additionalMessaging).hide();
        }

        // Ship Date (preorder/backorder only)
        var formattedShipDate = response.product.estimatedDelivery && 'formattedShipDate' in response.product.estimatedDelivery ? response.product.estimatedDelivery.formattedShipDate : '';
        $productContainer.find('.js-shop-collection-shipdate-value').text(formattedShipDate);
        if (formattedShipDate) {
            $productContainer.find('.js-shop-collection-shipdate').removeClass('d-none');
        } else {
            $productContainer.find('.js-shop-collection-shipdate').addClass('d-none');
        }
    } else {
        // TCS CUSTOM: Add additional availability messaging
        var $additionalMessaging = $productContainer.find('.js-availability-additional-msg');
        if ($additionalMessaging.length > 0) {
            $additionalMessaging.html(additionalMessaging);
        }

        // TCS CUSTOM: Update estimated delivery date
        $productContainer.find('.js-availability-container').attr('data-status', response.product.availability.status);
        estimatedDelivery.updateEstimatedDeliveryDate($productContainer, response.product.estimatedDelivery);

        // TCS CUSTOM: State Exclusions
        var stateExclusionText = 'stateExclusion' in response.product && response.product.stateExclusion && 'resources' in response.product.stateExclusion && 'pip' in response.product.stateExclusion.resources && response.product.stateExclusion.resources.pip ? response.product.stateExclusion.resources.pip : '';
        $productContainer.find('.js-state-exclusion-messaging').text(stateExclusionText);

        // TCS CUSTOM: Heavyweight Surcharge Messaging
        $productContainer.find('.shipping-surcharge-msg').text(response.product.heavyweightSurchargeText);

        // TCS CUSTOM: LTL Shipping Messaging
        $productContainer.find('.js-ltl-messaging').text(response.product.LTLitem);
    }

    $($productContainer).trigger('product:updateAvailability', {
        product: response.product,
        $productContainer: $productContainer,
        message: availabilityValue,
        resources: response.resources
    });
}

/**
 * Takes user to Product Overview on PIP and expands dropdown on mobile
*/
function pipSeeDetails() {
    $(".see-product-details").click(function() {
        if (window.isMobile()) {
            var $cardTitle = $(".js-description-and-detail").find('.js-product-description').find('.card-title');
            var accordionExpanded = $cardTitle.attr('aria-expanded');
            if (accordionExpanded === "false") {
                $cardTitle.trigger('click');
            }
        }

        setTimeout(() => {
            scrollAnimate($(".js-description-and-detail"));
        }, SiteConstants.TransitionSpeed);
    });
};

/**
 * Retrieve the event's product container
 */
function getProductContainer($currentElement) {
    var $productContainer;

    if ($currentElement) {
        $productContainer = $currentElement.closest('.set-item');
        var $productTileShopCollection = $currentElement.closest('.shop-collection-tile');
        var $productDetail = $currentElement.closest('.js-main-product-detail');
        if ($productDetail.length === 0) {
            $productDetail = $currentElement.closest('.product-detail');
        }

        if ($('.product-bundle').length) {
            $productContainer = $('.bundle-main-product').length ? $currentElement.closest('.bundle-main-product') : $currentElement.closest('.product-bundle');
        }

        if ($productTileShopCollection.length) {
            $productContainer = $productTileShopCollection;
        } else if ($productDetail.length) {
            $productContainer = $productDetail;
        }
    }

    return $productContainer;
}

/**
 * Attributes that display as color swatches
 * Replaces rvw_autobahn_core
 */
function colorAttribute() {
    var scope = this;

    $(document).on('click', '[data-attr="color"] button', function (e) {
        e.preventDefault();

        var $productContainer = getProductContainer($(this));

        $('body').trigger('product:swatchClicked', [$(this), toggleObject]); // add trigger for any attribute click use (incl. nonClickable Attrs)
        if (scope.methods.checkForClickableAttribute($(this)) && !toggleObject.viewOutOfStockItems) {
            return;
        }

        scope.methods.attributeSelect($(this).attr('data-url'), $productContainer);
    });
}

/**
 * Attributes that display in a select dropdown (default)
 * Replaces rvw_autobahn_core
 */
function selectAttribute() {
    var scope = this;

    $(document).on('change', 'select[class*="select-"], .options-select', function (e) {
        e.preventDefault();

        var $productContainer = getProductContainer($(this));

        scope.methods.attributeSelect(e.currentTarget.value, $productContainer);
    });
}

/**
 * Attributes that display as non-color swatches
 * Replaces rvw_autobahn_core
 */
 function nonColorAttribute() {
    var scope = this;

    $(document).on('click', 'button.swatch:not(.color-attribute)', function (e) {
        e.preventDefault();

        var $productContainer = getProductContainer($(this));

        $('body').trigger('product:swatchClicked', [$(this), toggleObject]); // add trigger for any attribute click use (incl. nonClickable Attrs)
        if (scope.methods.checkForClickableAttribute($(this)) && !toggleObject.viewOutOfStockItems) {
            return;
        }

        scope.methods.attributeSelect($(this).attr('data-url'), $productContainer);

        $(this).closest('.non-color-attribute-swatches').find('.non-color-display-value').text($(this).find('.swatch-value').data('display-value'));
    });
}

/**
 * Process the attribute values for an attribute that has image swatches
 *
 * @param {Object} attr - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {Object[]} attr.values - Array of attribute value objects
 * @param {string} attr.values.value - Attribute coded value
 * @param {string} attr.values.url - URL to de/select an attribute value of the product
 * @param {boolean} attr.values.isSelectable - Flag as to whether an attribute value can be
 *     selected.  If there is no variant that corresponds to a specific combination of attribute
 *     values, an attribute may be disabled in the Product Detail Page
 * @param {jQuery} $productContainer - DOM container for a given product
 * @param {Object} msgs - object containing resource messages
 * @param {Object} product - Response product data
 */
 function processSwatchValues(attr, $productContainer, msgs, product) {
    var isShopTheCollection = $productContainer.hasClass('shop-collection-tile') || false;
    if (attr.attributeId == 'color') {
        if ($productContainer.find('.color-attributes-groups').length > 0) {
            $('.color-attribute').tooltip('hide');
            $productContainer.find('.color-attributes-groups').html(product.swatchByPriceGroupHTML);
            launchSwatchPreview();
            return;
        }
        $productContainer.find('.color-display-value').text(attr.displayValue || '');
    };

    if (attr.attributeId == 'size') {
        $productContainer.find('[data-attr="size"]').find('.non-color-display-value').text(attr.displayValue || '');
    };

    attr.values.forEach(function (attrValue) {
        var $attrValue = $productContainer.find('[data-attr="' + attr.id + '"] [data-attr-value="' + attrValue.value + '"]');

        var $swatchButton = $attrValue.parent('button');

        if (attrValue.selected) {
            $attrValue.addClass('selected');
            if (msgs && 'assistiveSelectedText' in msgs) {
                $attrValue.siblings('.selected-assistive-text').text(msgs.assistiveSelectedText);
            }
            $attrValue.attr('selected', 'selected');
        } else {
            $attrValue.removeClass('selected');
            $attrValue.siblings('.selected-assistive-text').empty();
            $attrValue.removeAttr('selected');
        }

        if (attrValue.url) {
            $swatchButton.attr('data-url', attrValue.url);
        } else {
            $swatchButton.removeAttr('data-url');
        }

        // Disable if not selectable
        $attrValue.removeClass('selectable unselectable available unavailable out-of-stock');
        $attrValue.addClass(attrValue.available ? 'available' :  toggleObject.viewOutOfStockItems ? 'out-of-stock' : 'unavailable');

        if (isShopTheCollection) {
            $attrValue.addClass(attrValue.selectable && attrValue.available ? 'selectable' : 'unselectable');
        } else {
            $attrValue.addClass(attrValue.selectable ? 'selectable' : 'unselectable');
        }

        $attrValue.attr('value', attrValue.url).removeAttr('disabled');
        if ((!isShopTheCollection && !attrValue.selectable) || (isShopTheCollection && (!attrValue.selectable || !attrValue.available))) {
            $attrValue.attr('disabled', true);
        }
    });
}

/**
 * Routes the handling of attribute processing depending on whether the attribute has image
 *     swatches or not
 * @param {Object} attrs - Attribute
 * @param {string} attr.id - Attribute ID
 * @param {jQuery} $productContainer - DOM element for a given product
 * @param {Object} msgs - object containing resource messages
 * @param {Object} product - Response product data
 */
function updateAttrs(attrs, $productContainer, msgs, currentTarget, product) {
    var methods = this;

    //Get the available swatchable attribute array ['color','size'] (currently defined as a site pref, and added as a data attribute to the product container element
    var attrsWithSwatches = $productContainer.hasClass('.product-detail') ? $productContainer.data('swatchable-attributes') : $productContainer.closest('.product-detail').data('swatchable-attributes');

    attrs.forEach(function (attr) {
        if (attrsWithSwatches && attrsWithSwatches.indexOf(attr.attributeId) > -1) {
            processSwatchValues(attr, $productContainer, msgs, product);
        } else {
            methods.processNonSwatchValues(attr, $productContainer, msgs, currentTarget);
        }
    });
}

/**
 * Quantity select trigger
 * Replaces rvw_autobahn_core
 */
function availability() {
    var scope = this;

    $(document).on('change', '.quantity-select', function (e) {
        e.preventDefault();

        var $productContainer = getProductContainer($(this));

        if ($('.bundle-items', $productContainer).length === 0) {
            scope.methods.attributeSelect($(e.currentTarget).find('option:selected').data('url'),
                $productContainer);
        }
    });
}

/**
 * updates the product view when a product attribute is selected or deselected or when
 *         changing quantity
 * @param {string} selectedValueUrl - the Url for the selected variation value
 * @param {jQuery} $productContainer - DOM element for current product
 */
function attributeSelect(selectedValueUrl, $productContainer, currentTarget) {
    var methods = this;

    if (selectedValueUrl) {
        var isShopTheCollection = $productContainer.hasClass('shop-collection-tile');
        $('body').trigger('product:beforeAttributeSelect',
            { url: selectedValueUrl, container: $productContainer });


        if (isShopTheCollection) {
            selectedValueUrl = selectedValueUrl + '&isShopCollection=true';
        } else {
            /**
             * TCS Custom: Adds in the hasMonogram parameter to pass to the variation updates
             * when on the main product. Does not fire for MITC
             */
            if ($('input[type="checkbox"]#monogram').is(':checked')) {
                selectedValueUrl = selectedValueUrl + '&hasMonogram=true';
            }
        }

        $.ajax({
            url: selectedValueUrl,
            method: 'GET',
        })
            .then((data) => {
                methods.handleVariantResponse(data, $productContainer, currentTarget);
                methods.updateOptions(data.product.optionsHtml, $productContainer);
                methods.updateQuantities(data.product.quantities, $productContainer);
                methods.updateAttributeContent(data, $productContainer);

                $('body').trigger('product:afterAttributeSelect',
                    { data: data, container: $productContainer });
            })
            .always(() => $.spinner().stop());
    }
}

/**
 * Updates the Mini-Cart quantity value after the customer has pressed the "Add to Cart" button
 * @param {string} response - ajax response from clicking the add to cart button
 */
function handlePostCartAdd(response) {
    // conditional added for response, sometimes it was failing when called on page load
    if (response) {
        $('.minicart').trigger('count:update', response);
        if (response.newBonusDiscountLineItem
            && Object.keys(response.newBonusDiscountLineItem).length !== 0) {
            this.chooseBonusProducts(response.newBonusDiscountLineItem);
        } else {
            // TCS update - replace toast message with add to bag modal
            var $addToBagModal = $('#addToBagModal');

            if (response.error) {
                var errorMessage = `<div class="alert alert-danger m-3" role="alert">${response.message}</div>`;
                $addToBagModal.find('.modal-body').append(errorMessage);
                $addToBagModal.find('.modal-title').append('0 ' + $addToBagModal.data('plural-title'));
                $.spinner().stop();
            } else {
                var url = $addToBagModal.attr('data-action-url');
                var form = JSON.parse($addToBagModal.attr('data-form'));
                var qty = form.quantity ? parseInt(form.quantity, 10) : 0;

                // Get quantity for product set/multiple products added at once
                var productSet = 'pidsObj' in form ? JSON.parse(form.pidsObj) : null;
                if (productSet && productSet.length > 0) {
                    for (var i = 0; i < productSet.length; i++) {
                        qty += parseInt(productSet[i].qty, 10);
                    }
                }

                var modalTitle = qty + ' ' + (qty > 1 ? $addToBagModal.data('plural-title') : $addToBagModal.data('singular-title'));

                // populate modal title
                $addToBagModal.find('.modal-title').append(modalTitle);

                // populate modal body
                $addToBagModal.find('.modal-body').spinner().start();
                $.get(url, data => {

                    var $html = $('<div>').append($.parseHTML(data));
                    var $cartItems = $html.find('.minicart-item');
                    var newItemUUIDs = response.pliUUID;

                    // remove non-new cart items
                    $cartItems.each((_i, item) => {
                        var $cartItem = $(item);
                        var isNew = false;
                        newItemUUIDs.forEach(uuid => {
                            if ($cartItem.hasClass('uuid-' + uuid)) {
                                isNew = true;
                            }
                        });
                        if (!isNew) {
                            $cartItem.remove();
                        }
                    });

                    $addToBagModal.find('.modal-body').append($html[0]);
                    $.spinner().stop();
                });
            }
        }
    }
}

function launchAddToBagModal(form) {
    var $addToBagModal = $('#addToBagModal');
    if ($addToBagModal.length && !$addToBagModal.hasClass('show')) {
        $addToBagModal.attr('data-form', JSON.stringify(form));
        $addToBagModal.find('.modal-title').empty();
        $addToBagModal.find('.modal-body').empty();
        $addToBagModal.modal('show');
        $addToBagModal.find('.modal-body').spinner().start();
    }
}

/**
 * Retrieve contextual quantity selector
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {jquery} - quantity selector DOM container
 */
function getQuantitySelector($el) {
    var quantitySelected;
    if ($el && $('.set-items').length) {
        quantitySelected = $($el).closest('.product-detail').find('.quantity-select');
    } else if ($el && $('.product-bundle').length) {
        var quantitySelectedModal = $($el).closest('.modal-footer').find('.quantity-select');
        var quantitySelectedPDP = $($el).closest('.bundle-footer').find('.quantity-select');
        if (quantitySelectedModal.val() === undefined) {
            quantitySelected = quantitySelectedPDP;
        } else {
            quantitySelected = quantitySelectedModal;
        }
    } else {
        if ($el.closest('.shop-collection-tile').length) {
            quantitySelected = $($el).closest('.shop-collection-tile').find('.quantity-select');
        } else if ($el.closest('.quick-view-dialog').length) {
            quantitySelected = $($el).closest('.modal-content').find('.quantity-select');
        } else {
            quantitySelected = $($el).closest('.js-main-product-detail').find('.quantity-select');
        }
    }
    return quantitySelected;
}

/**
 * Parses JSON from Ajax call made whenever an attribute value is [de]selected
 * TCS Customizations: Added check to prevent disabling ATB button for Shop the Collection products
 * @param {Object} response - response from Ajax call
 * @param {Object} response.product - Product object
 * @param {string} response.product.id - Product ID
 * @param {Object[]} response.product.variationAttributes - Product attributes
 * @param {Object[]} response.product.images - Product images
 * @param {boolean} response.product.hasRequiredAttrsSelected - Flag as to whether all required
 *     attributes have been selected.  Used partially to
 *     determine whether the Add to Cart button can be enabled
 * @param {jQuery} $productContainer - DOM element for a given product.
 */
function handleVariantResponse(response, $productContainer, currentTarget) {
    var isShopTheCollection = $productContainer.hasClass('shop-collection-tile') || false;
    var isChoiceOfBonusProducts = $productContainer.parents('.choose-bonus-product-dialog').length > 0;
    var isVariant;
    var isSetItem = $productContainer.hasClass('product-set-item-detail') ? true : false;

    if (response.product.variationAttributes) {
        this.updateAttrs(response.product.variationAttributes, $productContainer, response.resources, currentTarget, response.product);

        isVariant = response.product.productType === 'variant';
        if (isChoiceOfBonusProducts && isVariant) {
            $productContainer.parent('.bonus-product-item').data('pid', response.product.id);
            $productContainer.parent('.bonus-product-item').data('ready-to-order', response.product.readyToOrder);
        }
    }

    // Update primary images
    var primaryImages = response.product.images;
    var pdpGalleryAssets = response.product.pdpGalleryAssets;

    this.createSlider(primaryImages, pdpGalleryAssets, $productContainer);
    // if variant is a product set item, update the sample image
    if (isSetItem) {
        $productContainer
            .find('.product-set-item-main-image')
            .attr('src', primaryImages.large[0].url)
            .attr('alt', primaryImages.large[0].alt);
    }

    // Update pricing
    if (!isChoiceOfBonusProducts) {
        if ($productContainer.is('.js-main-product-detail')) {
            $productContainer.closest('.product-detail').find('.js-main-product-detail .prices').html(response.product.renderedPrice);

            // Update ATC button price
            var pipATCButtonPrice = $productContainer.find('.js-atc-button-price');
            if (pipATCButtonPrice.length) {
                if (response.product.updatedTotalPrice && response.product.updatedTotalPrice.formatted) {
                    pipATCButtonPrice.text('- ' + response.product.updatedTotalPrice.formatted);
                } else {
                    pipATCButtonPrice.text('');
                }
            }
        } else {
            $productContainer.find('.prices').html(response.product.renderedPrice);
        }
    }

    // Update promotions
    var promotionsHTML = isShopTheCollection ? response.product.promotionsShortHtml : response.product.promotionsHtml;
    if ($productContainer.is('.js-main-product-detail')) {
        $productContainer.closest('.product-detail').find('.js-main-product-detail .promotions').empty().html(promotionsHTML);
    } else {
        $productContainer.find('.promotions').empty().html(promotionsHTML);
    }

    this.updateAvailabilityProcess(response, $productContainer);

    // Clear Shop the Collection error messages
    if (isShopTheCollection) {
        $('.js-shop-collection-attrs-errormsg').hide();
    }

    if (!isShopTheCollection) {
        if (isChoiceOfBonusProducts) {
            var $selectButton = $productContainer.find('.select-bonus-product');
            $selectButton.trigger('bonusproduct:updateSelectButton', {
                product: response.product, $productContainer: $productContainer
            });
        } else {
            // Enable "Add to Cart" button if all required attributes have been selected
            $('button.add-to-cart, button.add-to-cart-global, button.update-cart-product-global').trigger('product:updateAddToCart', {
                product: response.product, $productContainer: $productContainer
            }).trigger('product:statusUpdate', response.product);
        }
    }

    // Update attributes
    $productContainer.find('.main-attributes').empty().html(this.getAttributesHtml(response.product.attributes));

    // Update wishlist
    var $wishlistIcon = $productContainer.closest('.product-detail').find('.cloudinary-gallery-container .wishlist-toggle-product');
    if ($wishlistIcon.length && !isShopTheCollection) {
        $wishlistIcon
            .data('wishlistpid', response.product.wishlistpid)
            .attr('data-wishlistpid', response.product.wishlistpid);
        //Make heart icon accurate
        var wishlist = require('client_core/wishlist/components/helpers.js');
        wishlist.updateLinkData($wishlistIcon);
    }
}

/**
 * Retrieves the value associated with the Quantity pull-down menu
 * @param {jquery} $el - DOM container for the relevant quantity
 * @return {string} - value found in the quantity input
 */
function getQuantitySelected($el) {
    return getQuantitySelector($el).val();
}

/**
 * Updates the total item price
 */
function updateTotalItemPrice($productContainer, product) {
    var totalPrice = product.updatedTotalPrice.formatted;
    var totalPriceContainer = $productContainer.find('.js-product-total-price-container');
    var isShopTheCollection = $productContainer.hasClass('shop-collection-tile');
    var displayPrice = true;
    totalPriceContainer.find('.js-product-total-price').html(totalPrice);
    totalPriceContainer.find('.js-product-total-price').attr('data-pricevalue', product.updatedTotalPrice.value);

    // Shop the Collection - only display price if it's currently checked
    if (isShopTheCollection) {
        displayPrice = $productContainer.find('.js-shop-collection-checkbox').is(':checked');
    }

    // Show or hide price
    if (totalPrice && displayPrice) {
        totalPriceContainer.removeClass('d-none');
    } else {
        totalPriceContainer.addClass('d-none');
    }
}

/**
 * Add to Cart
 */
function addToCart() {
    $(document).on('click', 'button.add-to-cart, button.add-to-cart-global, .js-shop-collection-add-to-cart', function () {
        var isShopTheCollection = $(this).hasClass('js-shop-collection-add-to-cart');
        var addToCartUrl;
        var pid;
        var pidsObj;
        var setPids;
        var quantity;

        // START TCS Custom - Shop the Collection
        if (isShopTheCollection) {
            $('.js-shop-collection-no-items-errormsg').hide();
            var shopTheCollectionProducts = $(this).closest('.shop-collection').find('.shop-collection-tile .js-shop-collection-checkbox:checked');

            if (shopTheCollectionProducts.length === 0) {
                $('.js-shop-collection-no-items-errormsg').show();
                return;
            }

            // Get all products to add
            setPids = [];
            shopTheCollectionProducts.each(function () {
                var collectionProductTile = $(this).closest('.shop-collection-tile');

                setPids.push({
                    pid: collectionProductTile.attr('data-pid'),
                    qty: collectionProductTile.find('.quantity-select').val(),
                    options: core.methods.getOptions($(this))
                });
            });
            pidsObj = JSON.stringify(setPids);

            addToCartUrl = $(this).attr('data-url');
        }
        // END TCS Custom - Shop the Collection

        $('body').trigger('product:beforeAddToCart', this);

        if ($('.set-items').length && $(this).hasClass('add-to-cart-global')) {
            setPids = [];

            $('.product-detail').each(function () {
                if (!$(this).hasClass('product-set-detail')) {
                    setPids.push({
                        pid: $(this).find('.product-id').text(),
                        qty: $(this).find('.quantity-select').val(),
                        options: core.methods.getOptions($(this))
                    });
                }
            });
            pidsObj = JSON.stringify(setPids);
        }

        pid = isShopTheCollection ? null : getPidValue($(this));
        quantity = $(this).hasClass('single-variant-quick-add-to-cart') ? 1 : getQuantitySelected($(this));

        var form = {
            pid: pid,
            pidsObj: pidsObj,
            childProducts: core.methods.getChildProducts(),
            quantity: quantity
        };

        if (!isShopTheCollection) {
            var $productContainer = $(this).closest('.product-detail');
            if (!$productContainer.length) {
                if ($(this).hasClass('single-variant-quick-add-to-cart')) {
                    addToCartUrl = $(this).data('url');
                } else {
                    $productContainer = $(this).closest('.quick-view-dialog').find('.product-detail');
                    var $productModalbody = $(this).closest('.modal-content');
                    addToCartUrl = core.methods.getAddToCartUrl($productModalbody);
                }
            } else {
                addToCartUrl = core.methods.getAddToCartUrl($productContainer);
            }

            if (!$('.bundle-item').length) {
                form.options = core.methods.getOptions($productContainer);
            }
        }

        $(this).trigger('updateAddToCartFormData', form);

        launchAddToBagModal(form); // TCS custom

        if (addToCartUrl) {
            $.ajax({
                url: addToCartUrl,
                method: 'POST',
                data: form,
                success: function (data) {
                    if (!data.error) {
                        core.methods.handlePostCartAdd(data);
                        $('body').trigger('product:afterAddToCart', data);
                        $('body').trigger('product:afterAddToCartQuickview', data); //cart page quickview only
                        $.spinner().stop();
                        core.methods.miniCartReportingUrl(data.reportingURL);

                        // Shop the Collection: Remove checked checkboxes
                        if (isShopTheCollection) {
                            shopTheCollection.methods.resetShopTheCollection();
                            $('#addToBagModal').addClass('js-atb-shopcollection');
                        }

                        // GTM add to cart events
                        var gtmAddedProducts =  getAddToCartGTMObj(data);
                        $('body').trigger('product:GTMaddToCart', {
                            multipleProducts: gtmAddedProducts.length > 0 ? true : false,
                            gtmData: gtmAddedProducts,
                            data: data
                        });

                        // Klaviyo Added to Cart events for multiple products added at once
                        if ('KLMultiPids' in data) {
                            $('body').trigger('product:KLaddedToCart', {
                                data: data.KLMultiPids
                            });
                        }
                    } else {
                        toastHelpers.methods.show('warning', data.message, false);
                    }
                },
                error: function () {
                    $.spinner().stop();
                }
            });
        }
    });
}

function getAddToCartGTMObj(data) {
    var result = [];

    try {
        var addedPLIUUIDs = data.pliUUID;

        for (var i = 0; i < addedPLIUUIDs.length; i++) {
            var pliUUID = addedPLIUUIDs[i];
            var addedPLI = data.cart.items.filter(function (item) {
                return item.UUID === pliUUID;
            });

            if (addedPLI && addedPLI.length > 0) {
                result.push({
                    'gtmData': addedPLI[0].gtmData,
                    'qty': addedPLI[0].quantity
                });
            }

        }
    } catch (e) {
        console.log(e);
    }

    return result;
}

/**
 * Retrieves the relevant pid value (copied from base so we dont load the entire file 2x for this)
 * @param {jquery} $el - DOM container for a given add to cart button
 * @return {string} - value to be used when adding product to cart
 */
function getPidValue($el) {
    var pid;

    if ($('#quickViewModal').hasClass('show') && !$('.product-set').length) {
        pid = $($el).closest('.modal-content').find('.product-quickview').attr('data-pid');
    } else if ($('.product-set-detail').length || $('.product-set').length) {
        pid = $($el).closest('.product-detail').find('.product-id').text();
    } else {
        pid = $($el).closest('.product-detail:not(".bundle-item")').attr('data-pid');
    }

    return pid;
}

/**
 * Fetch the template to use for swatch previews
 */
function getSwatchPreviewContent() {
    return $(this).closest('.color-attribute').prev('.swatch-preview-template').html();
}

/**
 * Initialize swatch preview tooltips for both desktop and mobile
 */
function initSwatchTooltip(container) {
    $(container).find('.attributes .color-attribute').tooltip('dispose').tooltip({
        "html": true,
        "placement": "top",
        "trigger": window.isMobile() ? 'manual' : 'hover focus',
        "title": getSwatchPreviewContent,
        "container": window.isMobile() ? '.swatch-preview-container' : false
    });
}

/**
 * Manually trigger swatch preview tooltip for mobile
 */
function triggerMobileSwatchPreview(container) {
    var $selectedSwatch = $(container).find('.attributes .swatch.selected');
    if ($selectedSwatch.length) {
        $selectedSwatch.closest('.color-attribute').tooltip('show');
    }
}

/**
 * Initialize tooltip previews for desktop, trigger launch for selected variant on mobile
 */
function launchSwatchPreview() {
    initSwatchTooltip('body');

    // show preview for selected swatch on page load
    if (window.isMobile() && !$('.page-designer-edit-mode').length) {
        $('.product-detail').each((_index, element) => {
            triggerMobileSwatchPreview(element);
        });
    }

    // update tooltip states after variant change
    $('body').on('product:afterAttributeSelect', (_event, response) => {
        if (window.isMobile() && $(response.container).is('.js-main-product-detail') && !$('.page-designer-edit-mode').length) {
            $('.swatch-preview-container').empty();
            triggerMobileSwatchPreview(response.container);
        }
    });

    // init for quickview modal
    $(document).on('quickview:ready', (_event, modal) => {
        initSwatchTooltip(modal);

        if (window.isMobile() && !$('.page-designer-edit-mode').length) {
            triggerMobileSwatchPreview(modal);
        }
    });
}

function handleSwatchPreviewOnResize() {
    if ($('.js-main-product-detail .attributes .color-attribute').length > 0) {
        var isMobile = window.isMobile();
        $(window).on('resize', debounce(() => {
            // check for switch between mobile and desktop
            if (isMobile !== window.isMobile()) {
                launchSwatchPreview();
                // clear out static preview when switching from mobile to desktop
                if (!window.isMobile()) {
                    $('.swatch-preview-container').empty();
                }
            }
            isMobile = window.isMobile();
        }, 300));
    }
}

/*
* On variant change, update the text of the swatch dropdown to match the select dropdown
*/
function updateSwatchDropdown() {
    $('body').on('product:afterAttributeSelect', () => {
        var $swatchDropdownButtons = $('.js-swatch-dropdown-button');
        if ($swatchDropdownButtons.length > 0) {
            $swatchDropdownButtons.each((_index, element) => {
                var $button = $(element);
                var $productContainer = $button.closest('[data-pid]');
                var attributeId = $button.data('attr');
                var $selectPicker = $productContainer.find('[data-attr="' + attributeId + '"].selectpicker');
                var buttonText = $button.text();
                var selectedOptionText = $selectPicker.find('option:selected').text();

                // fix for chrome/firefox bug where dropdown's selected option is not updated properly
                for (var i = 0; i < $selectPicker.find('option').length; i++) {
                    var option = $selectPicker.find('option')[i];
                    if ($(option).attr('selected') === 'selected') {
                        selectedOptionText = $(option).text();
                        break;
                    }
                }

                if (buttonText !== selectedOptionText) {
                    $button.text(selectedOptionText);
                }
            });
        }
    });
}

/*
* Update the corresponding select dropdown when an attribute from the swatch dropdown is clicked
*/
function handleSwatchDropdownClick() {
    $('body').on('click', '.js-swatch-dropdown-attribute', event => {
        var $attribute = $(event.target).closest('.js-swatch-dropdown-attribute');
        var $attributeContainer = $attribute.closest('.attribute');
        var attributeId = $attributeContainer.find('.js-swatch-dropdown-button').data('attr');
        var attributeValue = $attribute.data('attr-value');
        var attributeName = $attribute.data('attr-name');
        var $selectPicker = $attributeContainer.find('[data-attr="' + attributeId + '"].selectpicker');
        var attributeUrl = $selectPicker.find('[data-attr-value="' + attributeValue + '"]').val();

        $selectPicker.val(attributeUrl).trigger('change');
        $attributeContainer.find('.swatch-dropdown').html(attributeName);
        setSwatchDropdownContent($attributeContainer.find('.js-swatch-dropdown-button'));
    });
}

function getSwatchDropdownContent() {
    return $(this).next('.swatch-dropdown-content-container').html();
}

function setSwatchDropdownContent($dropdownButton) {
    $dropdownButton.popover('dispose');
    $dropdownButton.popover({
        content: getSwatchDropdownContent,
        sanitize: false,
        trigger: 'focus'
    });
}

/*
* Custom Quantity Dropdown selector (to match the other Shop the Collection dropdowns)
*/
function updateQuantityDropdown() {
    $('body').on('product:afterAttributeSelect', () => {
        var $quantityDropdownButtons = $('.js-quantity-dropdown-button');
        if ($quantityDropdownButtons.length > 0) {
            $quantityDropdownButtons.each((_index, element) => {
                var $button = $(element);
                var $qtyContainer = $button.closest('.quantity');
                var $select = $qtyContainer.find('.quantity-select');
                var selectedQty = $select.val();
                var currentlySelected = $qtyContainer.find('.js-quantity-dropdown-option.selected').data('value');

                if (selectedQty !== currentlySelected) {
                    $button.text(selectedQty);
                    $qtyContainer.find('.js-quantity-dropdown-option.selected').removeClass('selected');
                    $qtyContainer.find('.js-quantity-dropdown-option[data-value="' + selectedQty + '"]').addClass('selected');
                }
            });
        }
    });
}

function handleQuantityDropdownClick() {
    $('body').on('click', '.js-quantity-dropdown-option', event => {
        var $option = $(event.target).closest('.js-quantity-dropdown-option');
        var $qtyContainer = $option.closest('.quantity');
        var optionValue = $option.data('value');
        var $select = $qtyContainer.find('.quantity-select');

        $select.find('option[value="' + optionValue + '"]').prop('selected', true).change();
        $qtyContainer.find('.quantity-dropdown').html(optionValue);
        setQuantityDropdownContent($qtyContainer.find('.js-quantity-dropdown-button'));
    });
}

function getQuantityDropdownContent() {
    return $(this).next('.quantity-dropdown-content-container').html();
}

function setQuantityDropdownContent($dropdownButton) {
    $dropdownButton.popover('dispose');
    $dropdownButton.popover({
        content: getQuantityDropdownContent,
        sanitize: false,
        trigger: 'focus'
    });
}

function adjustStickyATBClass($button, $buttonContainer) {
    var buttonTopOffset = $buttonContainer.offset().top;
    var buttonHeight = $button.outerHeight();
    var screenHeight = $(window).innerHeight();
    var scrollTop = $(window).scrollTop();

    if (((buttonTopOffset - screenHeight) + buttonHeight) > scrollTop) {
        $button.addClass('sticky');
        $button.find('.add-to-cart').addClass('gtm-atb-mobile-btn');
    } else {
        $button.removeClass('sticky');
        $button.find('.add-to-cart').removeClass('gtm-atb-mobile-btn');
    }
}

/*
* Set initial state and listener for mobile sticky add to bag
*/
function initStickyATB() {
    if (window.isMobile()) {
        var $button = $('.product-detail .addtocartbutton');
        var $buttonContainer = $button.closest('.cart-and-ipay');

        if ($button.length) {
            adjustStickyATBClass($button, $buttonContainer);

            $(window).scroll(() => {
                adjustStickyATBClass($button, $buttonContainer);
            });
        }
    }
}

/*
* Move promo modals to bottom of body element on launch to prevent z-index issues
*/
function placePromotionModal() {
    $('body').on('show.bs.modal', '.promo-banner-modal', event => {
        var $modal = $(event.target);
        $modal.appendTo('body');

        // fix for AB bug that appends quickview title to promotional modal header
        if ($modal.find('.modal-title').length > 1) {
            $modal.find('div.modal-title').remove();
        }
    });
}

/*
* Trigger launch of promo modal on click of promo item with enableGlobalPromoModal enabled
*/
function launchPromoModal() {
    $('body').on('click', '.js-launch-promo-modal', event => {
        var $promoBannerButton = $('#site-banner-text [data-toggle="modal"]');
        if ($promoBannerButton.length > 0) {
            $promoBannerButton.first().trigger('click');
        }
    });
}

/*
* Set the top position for the sticky PDP gallery
*/
function initStickyGallery() {
    var headerNavHeight = $('#top-header').outerHeight();
    $('.js-sticky-gallery').css('top', headerNavHeight + SiteConstants.Spacer + 'px');
}

/*
* Add to bag - close modal events
*/
function addBagCloseModal() {
    // On PDP, if main product is added to cart - scroll the user to the Shop the Collection section after the modal closes
    $('#addToBagModal').on('hide.bs.modal', function (e) {
        if ($('.shop-collection').length && $(this).hasClass('js-atb-shopcollection') === false) {
            scrollAnimate($(".shop-collection"));
        }
        $(this).removeClass('js-atb-shopcollection');
    });
}

/**
 * Select Swatches takes an array of swatch dom elements that need to be preselected
   *
   * @param {array} The swatch elements that need to be selected
*/
function selectSwatch(swatches) {
    var methods = this;

    if (swatches.length > 0) {
        // This splice removes the first swatch element from the array, and stores it in the "swatch" variable
        var swatch = swatches.splice(0,1)[0];
        var $productContainer = getProductContainer($(this));

        $productContainer.find('.color-display-value').text($(swatch).data('displayvalue'));

        // This call triggers the selection of the current swatch element, and sends the new array to be passed back to this function afterward.
        methods.attributeSelect($(swatch).attr('data-url'), $productContainer, function() {
            methods.selectSwatch(swatches);
        });
    }
}

/**
 * Update a wishlist slider by adding a product tile
 */
function addProductTileWishlistSlider() {
    $('body').on('wishlist:addProductTileSlider', function (e, data) {
        if (!data) return;

        var container = data.container;
        var productTileHTML = data.productTileHTML;

        if (container && productTileHTML) {
            if (container.find('.slider-container .slider').length > 0) {
                if ($('.wishlist-slider .slider-container .slider .slide').length > 0) {
                    container.find('.slider-container .slider').trigger('slider:destroy');
                }

                // Create slider container
                productTileHTML = '<div class="slide">' + productTileHTML + '</div>';

                // Add tile to slider
                var slider = container.find('.slider-container .slider'); // Deconstructed slider
                if (slider) {
                    $(productTileHTML).prependTo($(slider));
                    abSlider.initializeSliders(container);
                    container.removeClass('d-none');
                }
            }
        }
    });
}

core.methods.updateAttrs = updateAttrs;
core.methods.attributeSelect = attributeSelect;
core.methods.getQuantitySelector = getQuantitySelector;
core.methods.getQuantitySelected = getQuantitySelected;
core.methods.handleVariantResponse = handleVariantResponse;
core.methods.getPidValue = getPidValue;
core.methods.updateAvailabilityProcess = updateAvailabilityProcess;
core.methods.getProductContainer = getProductContainer;
core.methods.handlePostCartAdd = handlePostCartAdd;
core.methods.launchAddToBagModal = launchAddToBagModal;
core.methods.selectSwatch = selectSwatch;
core.updateAttribute = updateAttribute;
core.sizeChart = sizeChart;
core.variationGuide = variationGuide;
core.pipSeeDetails = pipSeeDetails;
core.colorAttribute = colorAttribute;
core.selectAttribute = selectAttribute;
core.nonColorAttribute = nonColorAttribute;
core.availability = availability;
core.launchSwatchPreview = launchSwatchPreview;
core.handleSwatchPreviewOnResize = handleSwatchPreviewOnResize;
core.addToCart = addToCart;
core.methods.getProductContainer = getProductContainer;
core.handleSwatchDropdownClick = handleSwatchDropdownClick;
core.updateSwatchDropdown = updateSwatchDropdown;
core.updateQuantityDropdown = updateQuantityDropdown;
core.handleQuantityDropdownClick = handleQuantityDropdownClick;
core.methods.setQuantityDropdownContent = setQuantityDropdownContent;
core.methods.setSwatchDropdownContent = setSwatchDropdownContent;
core.initStickyATB = initStickyATB;
core.placePromotionModal = placePromotionModal;
core.initStickyGallery = initStickyGallery;
core.addBagCloseModal = addBagCloseModal;
core.addProductTileWishlistSlider = addProductTileWishlistSlider;
core.launchPromoModal = launchPromoModal;

module.exports = core;
