'use strict';

// TCS file override for Consent Tracking that's specific to a Page Designer page

module.exports = function () {
    $('.js-ccpa-modal-close').on('click', function () {
        $(this).closest('.modal').modal('hide');
    });
    $('.js-ccpa-modal-confirm').on('click', function () {
        var cookieOptOut = document.cookie.indexOf('cookieOptOut=1') > -1;
        var $optOutPageButton = $('.js-cdg-request-optout');
        var $modal = $(this).closest('.modal');

        // Already opted out
        if (cookieOptOut) {
            $optOutPageButton.text('Opted Out Already');
            $modal.modal('hide');
        } else {
            // Opt out
            $.ajax({
                url: $('.js-ccpa-optout-link').attr('href'),
                type: 'GET',
                dataType: 'json',
                success: function () {
                    document.cookie = 'cookieOptOut=1; path=/; max-age=' + 2147483647;
                    $optOutPageButton.text('Opted Out Successfully');
                    $modal.modal('hide');
                }
            });
        }
    });
};
