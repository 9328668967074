'use strict';

/*
* Initiate post pageload events
*/
function initPostPageloadEvents() {
    if ($('.js-post-pageload-events').length) {
        var url = $('.js-post-pageload-events').attr('data-url');

        if (url) {
            $.ajax({
                url: url,
                method: 'POST',
                success: response => {},
                error: err => {}
            });
        }
    }
}

$(function() {
    if ($('.js-klaviyo-event').length) {
        try {
            $.ajax({
                url: $('.js-klaviyo-event').data('url'),
                method: 'GET',
                dataType: 'json',
                success: response => {},
                error: err => {}
            });
        } catch (e) {}
    }
});

module.exports = {
    initPostPageloadEvents: initPostPageloadEvents
};
